import get from 'lodash/get';
import { h, Component } from 'preact';
import { IntlProvider } from 'preact-i18n';

import BrandSpecificText from '~ui/components/brandSpecificText';
import ExplorePaymentsButtonText from '~ui/components/explorePaymentsButtonText';
import OverrideText from '~ui/components/overrideText';
import * as utils from '~ui/utils';

// This component renders buttons inserted using the location functions
// (insert) in DDC's API. It does not render buttons using DDC's intent
// functions (insertCallToAction). Those are rendered by the scraper.

export default ({
	borderRadius,
	brand,
	buttonTextOverride,
	colors,
	ctaType,
	dictionary,
	estimate,
	isLockedPricing,
	onClick,
	paymentInterval,
	showPaymentDetailsOnButton,
	waitingForVehicleData,
}) => {
	const primaryCtaTypes = ['creditApp', 'deposit', 'standAloneCreditApp', 'startApplication'];
	const buttonTypeClass = primaryCtaTypes.includes(ctaType) ? 'btn-primary' : 'btn-default';
	const defaultText = utils.ctaTypeDefaultTextIdMap[ctaType];
	const buttonAttributes = {
		class: `autofi-cta btn btn-block btn-sm text-center ${buttonTypeClass}`,
		onClick,
		style: {
			background: get(colors, 'background', '#3fb94d'),
			'border-radius': borderRadius,
			color: get(colors, 'text', '#ffffff'),
			'margin-top': '8px',
		},
		'data-autofi-cta-type': ctaType,
	};

	return (
		<IntlProvider definition={dictionary}>
			{ctaType === 'startApplication' ? (
				<a {...buttonAttributes}>
					<ExplorePaymentsButtonText
						buttonTextOverride={buttonTextOverride}
						estimate={estimate}
						isLockedPricing={isLockedPricing}
						paymentInterval={paymentInterval}
						showPaymentDetailsOnButton={showPaymentDetailsOnButton}
						waitingForVehicleData={waitingForVehicleData}
					/>
				</a>
			) : buttonTextOverride ? (
				// eslint-disable-next-line react/no-danger
				<a {...buttonAttributes} dangerouslySetInnerHTML={{ __html: buttonTextOverride }}></a>
			) : ctaType === 'privateOffers' ? (
				<a {...buttonAttributes}>
					<BrandSpecificText id={defaultText} brand={brand} />
				</a>
			) : (
				<a {...buttonAttributes}>
					<OverrideText content={defaultText} />
				</a>
			)}
		</IntlProvider>
	);
};
