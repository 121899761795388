import { h, Component } from 'preact';
import get from 'lodash/get';
import range from 'lodash/range';
import flatMap from 'lodash/flatMap';
import isNil from 'lodash/isNil';
import { IntlProvider, Localizer, Text, MarkupText } from 'preact-i18n';

import isFuture from 'date-fns/is_future';
import dateFormat from 'date-fns/format';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import isEqual from 'date-fns/is_equal';
import dateParse from 'date-fns/parse';
import startOfMinute from 'date-fns/start_of_minute';
import subMinutes from 'date-fns/sub_minutes';

import styles from './form/form.styl';
import { AmplitudeLabelComponents, Pathways, VehicleStatus } from '~lib/enum';
import * as utils from '~ui/utils';
import { trackGoogleUniversalAnalyticsEvent, trackJdPowerEvent, trackShiftDigitalEvent } from '~ui/utils/analytics';
import { trackGoogleAnalytics4Event } from '~ui/utils/googleAnalytics';
import AutoFiLogo from '~ui/assets/autofi-logo.svg';
import FormHeader from '~ui/components/formHeader';
import LoadingSpinner from '~ui/components/loadingSpinner';
import PrettyDatePicker from '~ui/components/prettyDatePicker';
import PrettyDropdown from '~ui/components/prettyDropdown';
import PrettyInput from '~ui/components/prettyInput';
import SubmitButton from '~ui/components/submitButton';
import isolate from '~ui/components/isolate';
import makePhoneNumberPlaceholder from '~ui/components/makePhoneNumberPlaceholder';
import modal from '~ui/components/modal';
import withStyles from '~ui/components/withStyles';

class ScheduleTestDriveForm extends Component {
	state = {
		formData: { contactMethod: 'TEXT' },
		inputValidity: {},
		submitEvent: null,
		touched: false,
	};

	inputIsValid = (inputName) => {
		return Boolean(get(this.state.inputValidity, [inputName, 'valid'], true));
	};

	formIsValid = () => {
		const { formData } = this.state;
		const phoneNumberIsValid = (formData.phone && this.inputIsValid('phone')) || !this.phoneNumberIsRequired();

		return (
			phoneNumberIsValid &&
			['name', 'email', 'testDriveDate', 'testDriveTime', 'contactMethod'].every(
				(inputName) => formData[inputName] && this.inputIsValid(inputName)
			)
		);
	};

	handleInputChange = (event) => {
		const { target } = event;
		this.setState({ touched: true });
		this.updateInputState(target);
	};

	phoneNumberIsRequired = () => {
		const { requirePhone } = this.props.autofiData.dealer.websiteSettings.ui.features.getYourPrice;
		return requirePhone || ['PHONE', 'TEXT'].includes(this.state.formData.contactMethod);
	};

	getTimeChoices = (contactHours, testDriveDate) => {
		return [
			'',
			...flatMap(range(0, 24), (hour) =>
				[0, 30]
					.filter((minute) => {
						const dateTime = this.combineDateAndTime(testDriveDate, `${hour}:${minute}`);
						return this.testDriveIsAllowedAtTime(contactHours, dateTime);
					})
					.map((minute) => utils.prettyTimeString(hour, minute))
			),
		];
	};

	getOpenHours = (contactHours, date) => {
		const dayOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][date.getDay()];
		const openString = get(contactHours, [dayOfWeek, 'open']);
		const closeString = get(contactHours, [dayOfWeek, 'closed']);
		return [openString, closeString];
	};

	getContactHours = () => {
		return this.props.vehicle?.dealer?.contactHours || {};
	};

	testDriveIsAllowedAtTime = (contactHours, dateTime) => {
		if (!this.isOpenOnDate(contactHours, dateTime)) {
			return false;
		}

		const [openString, closeString] = this.getOpenHours(contactHours, dateTime);
		const openDateTime = this.combineDateAndTime(dateTime, openString);
		const closeDateTime = this.combineDateAndTime(dateTime, closeString);
		const lastTestDriveTime = subMinutes(closeDateTime, 30);
		const isLateEnough = isFuture(dateTime) && (isAfter(dateTime, openDateTime) || isEqual(dateTime, openDateTime));
		const isEarlyEnough = isBefore(dateTime, lastTestDriveTime) || isEqual(dateTime, lastTestDriveTime);
		return isLateEnough && isEarlyEnough;
	};

	shouldDisableDate = (date) => {
		const contactHours = this.getContactHours();
		const isOpenOnDate = this.isOpenOnDate(contactHours, date);
		return !isOpenOnDate;
	};

	isOpenOnDate = (contactHours, date) => {
		const [openString, closeString] = this.getOpenHours(contactHours, date);
		return !isNil(openString) && !isNil(closeString);
	};

	updateInputState = ($input) => {
		let value = $input.type === 'checkbox' ? $input.checked : $input.value;
		const { name, validity } = $input;

		/**
		 * @todo
		 * Discover why tests fail without this if statement
		 */
		if (name === 'testDriveDate' && !value) {
			value = dateFormat(new Date(), 'YYYY-MM-DD');
		}

		this.setState((prevState) => ({
			formData: { ...prevState.formData, [name]: value },
			inputValidity: { ...prevState.inputValidity, [name]: validity },
		}));
	};

	combineDateAndTime = (date, timeString) => {
		const result = dateParse(date);
		const [hour, minute] = utils.parseTimeString(timeString);
		result.setHours(hour);
		result.setMinutes(minute);
		return startOfMinute(result);
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ submitEvent: event });
	};

	submit = () => {
		const { autofiData, pageType, submit, trackAmplitudeEvent, vehicle } = this.props;
		const { dealer: currentDealer, sessionData } = autofiData;
		const { formData, submitEvent } = this.state;
		const { name, email, phone, testDriveDate, testDriveTime, contactMethod, rememberMe } = formData;
		const contactData = { name, email, phone, contactMethod, rememberMe };
		const scheduledTime = this.combineDateAndTime(testDriveDate, testDriveTime);
		const vehicleDealer = vehicle.dealer || currentDealer;
		const applicant = utils.makeApplicantWithTestDriveAppointment({
			contactData,
			dealer: vehicleDealer,
			scheduledTime,
		});
		const googleEventMetaData = { dealer: currentDealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_submission',
			{ form_name: 'schedule_test_drive', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);

		trackGoogleAnalytics4Event(
			'asc_form_submission_sales_appt',
			{ form_name: 'schedule_test_drive', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_submission_sales',
			{ form_name: 'schedule_test_drive', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'schedule_test_drive', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);

		trackGoogleUniversalAnalyticsEvent('Scheduled Test Drive Form Submitted', googleEventMetaData);

		trackShiftDigitalEvent('drLeadFormFinish', vehicle, currentDealer, sessionData, applicant, pageType);
		trackShiftDigitalEvent('drApptSchedFinish', vehicle, currentDealer, sessionData, applicant, pageType);

		trackJdPowerEvent('DRS-Test-Drive-End', vehicle, currentDealer);

		const pathway = Pathways.TestDrive;
		const { PathwaySubmit } = AmplitudeLabelComponents.Label;
		trackAmplitudeEvent({ event: submitEvent, vehicle, label: PathwaySubmit, pathway });

		submit(applicant, () => this.setState({ submitEvent: null }));
	};

	initializeInputState = () => {
		// Don't run updateInputState on radio buttons because that will click all
		// of them, causing the last to be selected
		const $inputs = [
			...this.$form.querySelectorAll('input:not([type=radio]), textarea, select'),
			this.testDriveDropdown,
		];
		$inputs.forEach(this.updateInputState);
	};

	timeDropdownRef = (dropdown) => (this.testDriveDropdown = dropdown);

	componentDidMount = () => {
		this.initializeInputState();

		if (!this.props.hidden) {
			this.trackGa4LoadEvents();
		}

		this.setState((prevState) => ({
			formData: { ...prevState.formData, testDriveDate: dateFormat(new Date(), 'YYYY-MM-DD') },
			touched: false,
		}));
	};

	trackGa4LoadEvents = () => {
		const { vehicle, autofiData } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event('asc_page_view', { page_type: 'sales', vehicle }, googleEventMetaData);
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { applicant, appointment, autofiData, hidden, pageType, vehicle } = this.props;
		const { dealer: currentDealer, sessionData } = autofiData;
		if (!hidden) {
			if (prevProps.hidden) {
				this.trackGa4LoadEvents();
			}
			if (prevProps.hidden || appointment !== prevProps.appointment) {
				this.initializeInputState();
			}
		}

		const { submitEvent, touched } = this.state;
		if (!prevState.touched && touched) {
			trackShiftDigitalEvent('drLeadFormStart', vehicle, currentDealer, sessionData, applicant, pageType);
			trackShiftDigitalEvent('drApptSchedStart', vehicle, currentDealer, sessionData, applicant, pageType);
		}

		const { Pending } = VehicleStatus;
		const prevVehicleStatus = get(prevProps.vehicle, 'status', Pending);
		const vehicleStatus = get(vehicle, 'status', Pending);
		const formWasReadyToSubmit = Boolean(prevVehicleStatus !== Pending && prevState.submitEvent);
		const formIsReadyToSubmit = Boolean(vehicleStatus !== Pending && submitEvent);
		if (!formWasReadyToSubmit && formIsReadyToSubmit) {
			this.submit();
		}
	};

	getDealerPhone = () => {
		const {
			autofiData: { dealer },
		} = this.props;
		const phone = dealer?.phones?.find((phone) => phone.type === 'GENERAL') || dealer?.phones[0];
		return phone?.number || '';
	};

	hasContactHours = () => {
		const contactHours = this.getContactHours();
		return Object.keys(contactHours).length > 0;
	};

	handleClose = () => {
		const { autofiData, close, vehicle } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'schedule_test_drive', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);

		close();
	};

	render = (props, state) => {
		const { applicant, autofiData, subTitle, title, dictionary, vehicle } = props;
		const { formData, submitEvent } = state;

		const testDriveDate = dateParse(formData.testDriveDate);
		const contactHours = this.getContactHours();
		const timeChoices = this.getTimeChoices(contactHours, testDriveDate);
		const requirePhone = this.phoneNumberIsRequired();
		const isValidVehicle = vehicle?.status === VehicleStatus.OK;
		const isPendingVehicle = vehicle?.status === VehicleStatus.Pending;
		const { launchDarklyFeatureFlags } = autofiData;
		const showTCPADisclosure = launchDarklyFeatureFlags.enableTCPAConsent;

		return (
			<IntlProvider definition={dictionary}>
				{isPendingVehicle && <LoadingSpinner />}
				<div class="autofi-modal scheduleTestDriveForm">
					<FormHeader close={this.handleClose} subTitle={subTitle} title={title} />
					<div class="modalContent">
						{!isValidVehicle && (
							<div class="modalErrorMessage">
								<Localizer>
									<p class="title">
										<Text id="no-available-date-for-test-drive" />
									</p>
								</Localizer>
								<Localizer>
									<small>
										<Text
											id="try-again-later-or-contact-us"
											fields={{
												phone: this.getDealerPhone(),
											}}
										/>
									</small>
								</Localizer>
							</div>
						)}

						<form class="autofi-form" ref={($el) => (this.$form = $el)}>
							<h3>
								<Text id="when-do-you-want-to-come-in" />
							</h3>
							<div class="row">
								{isValidVehicle ? (
									<PrettyDatePicker
										name="testDriveDate"
										onInput={this.handleInputChange}
										required
										defaultDate={formData.testDriveDate || new Date()}
										disable={[this.shouldDisableDate]}
										minDate={new Date()}
									/>
								) : (
									<div class="prettyDatePicker empty">
										<input type="text" disabled />
									</div>
								)}
								<Localizer>
									<PrettyDropdown
										name="testDriveTime"
										onChange={this.handleInputChange}
										options={timeChoices.map((item) => ({ value: item, text: item }))}
										required
										value={formData.testDriveTime}
										hint={this.inputIsValid('testDriveTime') ? '' : <Text id="pick-a-time" />}
										ref={this.timeDropdownRef}
									/>
								</Localizer>
							</div>
							<h3>
								<Text id="who-is-test-driving" />
							</h3>
							<div class="row">
								<Localizer>
									<PrettyInput
										name="name"
										defaultValue={applicant.name.full}
										placeholder={<Text id="full-name">Full Name</Text>}
										required
										pattern={utils.validationPatterns.fullname}
										hint={utils.hasNoNumbers(formData.name) ? '' : <Text id="invalid-name-numbers-are-not-allowed" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
							</div>
							<div class="row">
								<Localizer>
									<PrettyInput
										name="email"
										defaultValue={applicant.email}
										type="email"
										placeholder={<Text id="email">Email</Text>}
										required
										hint={this.inputIsValid('email') || !formData.email ? '' : <Text id="invalid-email-address" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										name="phone"
										defaultValue={applicant.phone}
										type="tel"
										placeholder={makePhoneNumberPlaceholder({ requirePhone })}
										required={requirePhone}
										hint={this.inputIsValid('phone') || !formData.phone ? '' : <Text id="invalid-phone-number" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
							</div>
							<div class="row radioGroup">
								<div class="radioGroupLabel">
									<Text id="preferred-contact-method" />
								</div>
								<label>
									<input
										type="radio"
										name="contactMethod"
										value="TEXT"
										checked={formData.contactMethod === 'TEXT'}
										onClick={this.handleInputChange}
									/>
									<span>
										<Text id="text" />
									</span>
								</label>
								<label>
									<input
										type="radio"
										name="contactMethod"
										value="EMAIL"
										checked={formData.contactMethod === 'EMAIL'}
										onClick={this.handleInputChange}
									/>
									<span>
										<Text id="email" />
									</span>
								</label>
								<label>
									<input
										type="radio"
										name="contactMethod"
										value="PHONE"
										checked={formData.contactMethod === 'PHONE'}
										onClick={this.handleInputChange}
									/>
									<span>
										<Text id="phone" />
									</span>
								</label>
							</div>
							<div class="row">
								<SubmitButton
									onClick={this.handleSubmit}
									disabled={!this.formIsValid()}
									submitting={Boolean(submitEvent)}
								>
									<Text id="schedule" />
								</SubmitButton>
							</div>
							<div>
								<label class="checkboxContainer">
									<input type="checkbox" name="rememberMe" onChange={this.handleInputChange} />
									<div>
										<Text id="remember-me" />
										<div class="explanation">
											<MarkupText id="you-wont-have-to-fill-out" />
										</div>
									</div>
								</label>
							</div>
							{showTCPADisclosure && (
								<div class="row">
									<div className="consent">
										<MarkupText id="tcpa-consent" />
									</div>
								</div>
							)}
							<div class="poweredBy">
								<Text id="powered-by" /> <AutoFiLogo class="autoFiLogo" />
							</div>
						</form>
					</div>
				</div>
			</IntlProvider>
		);
	};
}
const WrappedScheduleTestDriveForm = modal(isolate(withStyles(ScheduleTestDriveForm, styles)));

WrappedScheduleTestDriveForm.allRequiredFieldsFilled = () => {
	return false;
};

export default WrappedScheduleTestDriveForm;
