import flatpickr from 'flatpickr';
import { h, Component } from 'preact';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

import styles from './prettyDatePicker.styl';
import * as utils from '~ui/utils';
import withStyles from '~ui/components/withStyles';

const zeroWidthSpace = '\u200b';

class PrettyDatePicker extends Component {
	static defaultProps = { hint: undefined };

	state = { value: undefined, touched: false };

	defaults = {
		altFormat: 'l n/j/Y',
		altInput: true,
		clickOpens: false,
		dateFormat: 'Y-m-d',
		defaultDate: this.props.defaultDate,
		disable: this.props.disable,
		minDate: this.props.minDate,
		onChange: () => {
			if (utils.isIos()) {
				this.flatpickr.altInput.blur();
			}
		},
		static: true,
	};

	getRoot = () => {
		return this.base.getRootNode();
	};

	onInput = (event) => {
		this.props.onInput(event);
		this.setState({ value: event.target.value, touched: true });
	};

	componentDidMount = () => {
		document.addEventListener('click', this.handleBodyClick);
		const $root = this.getRoot();
		const options = {
			...this.defaults,
			appendTo: $root === document ? undefined : $root.children[0],
			...omit(this.props, ['name', 'onInput', 'required']),
		};
		if ($root !== document) {
			$root.addEventListener('click', this.stopClickEvent);
		}

		this.setState({ value: this.input.value }, () => {
			this.flatpickr = flatpickr(this.input, options);
			// TODO: find out if it is OK to use altInput. This property is not
			// documented. Try to find another way to make flatpickr close if the input
			// is clicked when it is already open, to match behavior of date picker in
			// loanapp. Or find out if it is OK to simplify things by removing
			// clickOpens override and going with the default behavior (nothing happens
			// if input is clicked when flatpickr is open).
			this.flatpickr.altInput.onclick = this.flatpickr.toggle;
		});
	};

	// Prevent event listeners outside of shadow DOM from responding to click
	// event, which can prevent flatpickr from working properly on DDC sites.
	stopClickEvent = (event) => event.stopPropagation();

	shouldComponentUpdate = () => {
		// If this method is not defined, the hidden input containing the selected
		// date will mysteriously disappear at certain times. This can be avoided
		// by not using the altInput feature, but that limits our flexibility, and
		// may still not completely prevent Preact from inappropriately messing
		// with the date picker.
		// TODO: find out if this is OK, or if it breaks some things (like
		// preventing "touched" and the hint from updating).
		return false;
	};

	componentWillUnmount = () => {
		document.removeEventListener('click', this.handleBodyClick);
		this.getRoot().removeEventListener('click', this.stopClickEvent);
	};

	handleBodyClick = (event) => {
		const clickIsInComponent = this.base.contains(event.target);
		const clickIsInCalendar = this.flatpickr.calendarContainer.contains(event.target);
		if (!clickIsInComponent && !clickIsInCalendar) {
			this.flatpickr.close();
		}
	};

	render = (props, state) => {
		return (
			<div class={`prettyDatePicker ${state.value ? '' : 'empty'} ${state.touched ? 'touched' : ''}`}>
				<input {...omit(props, ['onInput'])} type="date" onInput={this.onInput} ref={(input) => (this.input = input)} />
				<label>{props.placeholder}</label>
				{isNil(props.hint) ? null : <div class="hint">{props.hint || zeroWidthSpace}</div>}
			</div>
		);
	};
}

export default withStyles(PrettyDatePicker, styles);
