import isNil from 'lodash/isNil';
import { h, Component } from 'preact';
import { Text } from 'preact-i18n';

import * as utils from '~ui/utils';
import OverrideText from '~ui/components/overrideText';
import LockIcon from '~ui/assets/lock-icon.svg';

export default ({
	buttonTextOverride,
	estimate,
	isLockedPricing,
	paymentInterval,
	showPaymentDetailsOnButton,
	waitingForVehicleData,
}) => {
	const buttonText = buttonTextOverride || (isLockedPricing ? 'unlock-all-discounts' : 'explore-payments');

	const { biweeklyPayment, monthlyPayment } = estimate || {};
	const paymentAmount = paymentInterval === 'BIWEEKLY' ? biweeklyPayment : monthlyPayment;

	// TODO: remove !waitingForVehicleData check below and
	// waitingForVehicleData prop passed to this component. It is unlikely that
	// we will have an estimator response but no vehicle data.
	const shouldShowPayment =
		showPaymentDetailsOnButton && !waitingForVehicleData && Boolean(estimate) && !isNil(paymentAmount);

	return (
		<span>
			{/* Apply margin-right to LockIcon inline, so it will have this style,
			even if the styles in mainButton.styl are not applied, which happens
			when showing buttons in the dealer's style on DDC sites. */}
			{/* eslint-disable-next-line react/style-prop-object */}
			{isLockedPricing && <LockIcon style="margin-right: 8px" />}
			<OverrideText content={buttonText} />
			{shouldShowPayment && [
				': ',
				<span class="monthlyPayment">
					{utils.formatMoney(paymentAmount)}
					<Text id={`per-${paymentInterval.toLowerCase()}`} />
				</span>,
			]}
		</span>
	);
};
