import compact from 'lodash/compact';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import { h, Component } from 'preact';
import { createPortal } from 'preact/compat';

import styles from './prettyInput.styl';
import { isAddressWithNumber } from '~ui/utils';
import withStyles from '~ui/components/withStyles';

const zeroWidthSpace = '\u200b';

class PrettyAddressInput extends Component {
	static defaultProps = {
		type: 'text',
		hint: undefined,
		country: 'US',
	};

	state = {
		value: undefined,
		touched: false,
		googleApiAlreadyLoaded: !!window.google?.maps?.places,
	};

	onInput = (event) => {
		const { onInput } = this.props;
		const value = get(event, 'target.value', event.value);
		const target = event.target;
		if (target) {
			onInput(event);
		}
		this.setState({ value: value, touched: true });
		this.setInputValidity(value);
	};

	onFocus = () => {
		// Prevent Chrome address autofill from covering Google address
		// autocomplete suggestions. See https://stackoverflow.com/a/49161445/28324
		this.input.autocomplete = 'new-password';
	};

	setInputValidity = (street) => {
		const isValid = isAddressWithNumber(street);
		this.input.setCustomValidity(isValid ? '' : 'invalid');
	};

	componentDidMount = () => {
		this.setState({ value: this.input.value });

		const whenGoogleMapsApiLoads = () => {
			const autocomplete = new window.google.maps.places.Autocomplete(this.input);
			autocomplete.addListener('place_changed', () => {
				const { onPlaceChange } = this.props;
				if (onPlaceChange) {
					const place = autocomplete.getPlace();
					const addressComponent = this.extractGoogleAddressComponents(place);
					const street_number = get(addressComponent, 'street_number', '');
					const route = get(addressComponent, 'route', '');
					addressComponent.street_number = `${street_number} ${route}`.trim();
					onPlaceChange(addressComponent);
					this.onInput({
						value: addressComponent.street_number,
					});
				}
			});
		};

		if (this.state.googleApiAlreadyLoaded) {
			whenGoogleMapsApiLoads();
		} else {
			this.googleApiScript.onload = whenGoogleMapsApiLoads;
		}
	};

	extractGoogleAddressComponents = (placeObject) => {
		const addressPropMap = {
			street_number: 'short_name',
			route: 'long_name',
			locality: 'long_name',
			administrative_area_level_1: 'short_name',
			country: 'long_name',
			postal_code: 'short_name',
		};

		const addressComponents = get(placeObject, 'address_components', []);

		const addressPairs = fromPairs(
			addressComponents
				.filter((component) => addressPropMap[component.types[0]])
				.map((component) => [component.types[0], component[addressPropMap[component.types[0]]]])
		);

		return mapValues(addressPairs, (component) => component || '');
	};

	googleApiScriptRef = (script) => {
		this.googleApiScript = script;
	};

	inputRef = (input) => {
		this.input = input;
	};

	render = (props, state) => {
		const { googleApiKey } = props;

		const containerClasses = compact([
			'prettyInput',
			'prettyAddressInput',
			state.value ? null : 'empty',
			state.touched ? 'touched' : null,
			props.containerClass,
		]).join(' ');

		return (
			<div class={containerClasses}>
				{/*
					// Override z-index of container used for Google Places Autocomplete.
					// Google only gives it a z-index of 1000, which is not enough to show on
					// top of our modal forms. Trying to fix this by decreasing the z-index of
					// our forms causes dealer overlays to cover our forms. */}
				{createPortal(
					<style class="autofi-fix-address-autocomplete">{`
						.pac-container {
							z-index: 1220003 !important
						}
					`}</style>,
					document.head
				)}
				{!this.state.googleApiAlreadyLoaded && (
					<script
						src={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`}
						ref={this.googleApiScriptRef}
						async
					/>
				)}
				<input
					{...omit(props, ['containerClass', 'onInput', 'pattern'])}
					onFocus={this.onFocus}
					onInput={this.onInput}
					ref={this.inputRef}
				/>
				<label>{props.placeholder}</label>
				<div class="hint">{props.hint || zeroWidthSpace}</div>
			</div>
		);
	};
}

export default withStyles(PrettyAddressInput, styles);
