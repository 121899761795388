import get from 'lodash/get';
import { h, Component } from 'preact';
import { IntlProvider } from 'preact-i18n';

import isolate from '~ui/components/isolate';
import OverrideText from '~ui/components/overrideText';
import withStyles from '~ui/components/withStyles';

import styles from './mainButton.styl';

const DepositButton = (props) => {
	const { buttonColors, buttonTextOverride, compact, dictionary, onClick } = props;
	const buttonClasses = ['autofi-cta', 'autofi-main-button'];
	if (compact) {
		buttonClasses.push('compact');
	}

	const buttonText = buttonTextOverride || 'hold-this-vehicle';

	return (
		<IntlProvider definition={dictionary}>
			<button
				class={buttonClasses.join(' ')}
				data-autofi-cta-type="deposit"
				onClick={onClick}
				type="button"
				style={{
					backgroundColor: get(buttonColors, 'background'),
					color: get(buttonColors, 'text'),
				}}
			>
				<OverrideText content={buttonText} />
			</button>
		</IntlProvider>
	);
};

export default isolate(withStyles(DepositButton, styles));
