import { h, Component } from 'preact';

import * as utils from '~ui/utils';

export default class DiscountTable extends Component {
	render = (props) => {
		const { dealerRetailPrice, discountAndRebatesAmount } = props;

		return (
			<div>
				<table class="discountTable">
					<tbody>
						<tr>
							<th>MSRP</th>
							<td>{utils.formatMoney(dealerRetailPrice)}</td>
						</tr>
						<tr>
							<th>Discounts</th>
							<td>{utils.formatMoney(discountAndRebatesAmount)}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<th>Online Price</th>
							<td>{utils.formatMoney(dealerRetailPrice - discountAndRebatesAmount)}</td>
						</tr>
					</tfoot>
				</table>
				<h2>See monthly payments for your deal…</h2>
			</div>
		);
	};
}
