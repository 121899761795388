import { h, Component } from 'preact';
import { IntlProvider, Text } from 'preact-i18n';

import SearchIcon from '~ui/assets/search.svg';
import CloseIcon from '~ui/assets/close-icon.svg';
import styles from '../creditApp/sequence.styl';
import BrandSpecificText from '~ui/components/brandSpecificText';
import withStyles from '~ui/components/withStyles';

const Loading = (props) => {
	const { autofiData, close, dictionary, vehicle } = props;
	const { brand } = vehicle.dealer || autofiData.dealer;

	return (
		<IntlProvider definition={dictionary}>
			<form class="autofi-modal privateOffersPage loading">
				<header class="modalHeader">
					<button type="button" class="closeButton" onClick={close}>
						<Text id="close" /> <CloseIcon />
					</button>
					<h2>
						<BrandSpecificText id="searching-private-offer" brand={brand} />
					</h2>
				</header>
				<div class="modalContent">
					<div class="spinnerContainer">
						<SearchIcon class="search-icon" />
						<div class="spinner" />
					</div>
				</div>
			</form>
		</IntlProvider>
	);
};

export default withStyles(Loading, styles);
