/** converts a url's query string to an object
 * @param {string} url
 * @returns {Object.<string, string>}
 */
function parseQuery(url) {
	const result = {};

	[...new URL(url).searchParams]
		.map(([key, value]) => [key.toLowerCase(), value.toLowerCase()])
		.forEach(([key, value]) => {
			if (!result[key]) {
				result[key] = [];
			}
			result[key].push(value);
		});

	Object.entries(result)
		.filter(([_key, values]) => values.length === 1)
		.forEach(([key, values]) => (result[key] = values[0]));

	return result;
}

module.exports = parseQuery;
