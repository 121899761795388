import { h, Component } from 'preact';
import { IntlProvider } from 'preact-i18n';

import { AmplitudeLabelComponents } from '~lib/enum';
import FormHeader from '~ui/components/formHeader';
import isolate from '~ui/components/isolate';
import modal from '~ui/components/modal';
import styles from './form/form.styl';
import withStyles from '~ui/components/withStyles';

class ThankYouModal extends Component {
	trackAmplitudeLoad = () => {
		const { pathway, trackAmplitudeEvent, vehicle } = this.props;
		const action = AmplitudeLabelComponents.Action.Load;
		const label = AmplitudeLabelComponents.Label.CustomerMessageDisplayed;
		const messageCode = 'Dealmaker Cannot be Displayed - No Vehicle Data';
		const messageText = [this.getTitle(), ...this.getMainText()].join('\n');
		const step = AmplitudeLabelComponents.Steps.ThankYou;

		trackAmplitudeEvent({ action, label, opts: { messageCode, messageText, step }, pathway, vehicle });
	};

	getTitle = () => this.props.dictionary['thank-you-for-your-interest'];

	getMainText = () => {
		const { dictionary } = this.props;

		return [dictionary['we-are-unable-to-calculate-a-payment'], dictionary['a-dealer-representative-will-contact-you']];
	};

	componentDidMount = () => {
		if (!this.props.hidden) {
			this.trackAmplitudeLoad();
		}
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.hidden && !this.props.hidden) {
			this.trackAmplitudeLoad();
		}
	};

	render = (props) => {
		const { close, dictionary } = props;

		return (
			<IntlProvider definition={dictionary}>
				<div class="autofi-modal thankYouModal">
					<FormHeader close={close} title={this.getTitle()} />
					<div class="modalContent">
						{this.getMainText().map((sentence) => (
							<p>{sentence}</p>
						))}
					</div>
				</div>
			</IntlProvider>
		);
	};
}

const WrappedThankYouModal = modal(isolate(withStyles(ThankYouModal, styles)));

export default WrappedThankYouModal;
