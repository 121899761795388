import { h, Component } from 'preact';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import ModalSequence from '~ui/components/modalSequence';
import Intro from './intro';
import Form from './form';
import Loading from './loading';
import Result from './result';
import { AmplitudeLabelComponents, Pathways } from '~lib/enum';
import ConsumerMFAClient from './ConsumerMFAClient';
import * as utils from '~ui/utils';
import HTTPClient from '~ui/utils/HTTPClient';

class CreditAppModalSequence extends Component {
	state = {
		softPullResponse: null,
		isSubmitting: false,
	};

	onSoftPullResponse = (response) =>
		new Promise((resolve) => {
			const { ficoRange } = response || {};
			const rangeDisplayed = !isEmpty(ficoRange);
			const { applicant, trackAmplitudeEvent } = this.props;

			trackAmplitudeEvent({
				action: AmplitudeLabelComponents.Action.Load,
				label: AmplitudeLabelComponents.Label.PathwayCreditEstimator,
				opts: {
					creditRangeDisplayed: rangeDisplayed,
					isTestUser: applicant.isTestUser,
					step: AmplitudeLabelComponents.Steps.PersonalEstimate,
				},
				pathway: Pathways.CreditApp,
			});
			this.setState({ softPullResponse: response }, () => resolve());
		});

	performSoftPull = async () => {
		const { applicant, autofiData, loanAppId } = this.props;
		const { apiProxyUrl, dealer } = autofiData;
		const payload = utils.makeSoftPullPayload(applicant, autofiData, loanAppId);
		const url = utils.getSoftPullUrl(apiProxyUrl, window.location.href);
		const { secret } = dealer.settings.buyNow;

		return new Promise((resolve) => {
			HTTPClient.post(
				{
					payload,
					endpoint: { url, secret },
				},
				async (err, response) => {
					this.setState({ isSubmitting: false });
					let responseData = null;
					try {
						responseData = JSON.parse(response);
					} catch (_err) {
						// do nothing
						return resolve({ error: _err, data: null });
					}
					if (responseData && !err) {
						await this.onSoftPullResponse(responseData);
					} else {
						// eslint-disable-next-line no-console
						console.error('Sorry! Something went wrong on our end.');
						await this.onSoftPullResponse(null);
						return resolve({ error: err, data: null });
					}
					return resolve({ error: null, data: responseData });
				}
			);
		});
	};

	render = (props, state) => {
		return (
			<ModalSequence
				{...props}
				isSubmitting={state.isSubmitting}
				pages={[Intro, Form, Loading, Result, ConsumerMFAClient]}
				performSoftPull={this.performSoftPull}
				softPullResponse={state.softPullResponse}
			/>
		);
	};
}

CreditAppModalSequence.allRequiredFieldsFilled = (dealer, applicant) => {
	const { email, name, phone, rememberMe } = applicant;

	if (!rememberMe) {
		// always make them resubmit the form if they didnt want to be remembered
		return false;
	} else {
		const { features } = dealer.websiteSettings.ui;
		const requirePhone = get(features, 'getYourPrice.requirePhone', false);
		return Boolean(name.full && email && (requirePhone ? phone : true));
	}
};

export default CreditAppModalSequence;
