import { h, Component } from 'preact';

import LoadingSpinner from '~ui/components/loadingSpinner';
import withStyles from '~ui/components/withStyles';
import styles from './submitButton.styl';

const SubmitButton = (props) => {
	const { children, disabled, onClick, submitting } = props;

	return (
		<div class="submitButtonContainer">
			<button type="submit" onClick={onClick} disabled={disabled || submitting}>
				{children}
			</button>
			{submitting && <LoadingSpinner />}
		</div>
	);
};

export default withStyles(SubmitButton, styles);
