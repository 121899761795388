import { h, Component } from 'preact';
import { IntlProvider, MarkupText, Text } from 'preact-i18n';

import CalculatorIcon from '~ui/assets/calculator-icon.svg';
import CloseIcon from '~ui/assets/close-icon.svg';
import LoadingSpinner from '~ui/components/loadingSpinner';
import styles from './sequence.styl';
import withStyles from '~ui/components/withStyles';

const Loading = (props) => {
	const { close, dictionary } = props;

	return (
		<IntlProvider definition={dictionary}>
			<form class="autofi-modal creditAppPage loading">
				<header class="modalHeader">
					<button type="button" class="closeButton" onClick={close}>
						<Text id="close" /> <CloseIcon />
					</button>
					<h2>
						<MarkupText id={'calculating'} />
					</h2>
				</header>
				<div class="modalContent">
					<div class="spinnerContainer">
						<CalculatorIcon class="calculator-icon" />
						<LoadingSpinner />
					</div>
				</div>
			</form>
		</IntlProvider>
	);
};

export default withStyles(Loading, styles);
