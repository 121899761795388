import { h, Component } from 'preact';
import { Localizer } from 'preact-i18n';

import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

import styles from './prettyInput.styl';
import withStyles from '~ui/components/withStyles';

const zeroWidthSpace = '\u200b';

class PrettyTextArea extends Component {
	static defaultProps = { hint: undefined };

	state = { value: undefined, touched: false };

	onInput = (event) => {
		this.props.onInput(event);
		this.setState({ value: event.target.value, touched: true });
	};

	componentDidMount = () => {
		this.setState({ value: this.input.value });
	};

	render = (props, state) => {
		return (
			<div class={`prettyInput ${state.value ? '' : 'empty'} ${state.touched ? 'touched' : ''}`}>
				{/* value attribute is required for textarea to work in Edge */}
				<Localizer>
					<textarea
						{...omit(props, ['onInput'])}
						onInput={this.onInput}
						ref={(input) => (this.input = input)}
						value={state.value}
					/>
				</Localizer>
				{isNil(props.hint) ? null : <div class="hint">{props.hint || zeroWidthSpace}</div>}
			</div>
		);
	};
}

export default withStyles(PrettyTextArea, styles);
