import difference from 'lodash/difference';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import getI18nDiscount from '~lib/dictionaries/getI18nDiscount';
import { VehicleStatus } from '~lib/enum';
import parseQuery from '~lib/parse-query';
import HTTPClient from '~ui/utils/HTTPClient';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import groupBy from 'lodash/groupBy';

/**
 * Make a call to the vehicle service for full details on multiple vehicles
 * @param {array} vehicles
 * @param {object} autofiData
 * @param {string} dealerCodeOverride
 */
export const fetchVehicles = async (vehicles, autofiData, dealerCodeOverride) => {
	const allVins = extractVins(vehicles);
	const vinsToFetch = difference(allVins, Object.keys(vehicleCache));

	const { dealer: currentDealer, dealersMap } = autofiData;

	const vehicleDetails = await getVehicleData(vinsToFetch, autofiData, dealerCodeOverride);

	const { NoData, OK } = VehicleStatus;
	const vehicleDetailsMap = vinMap(
		vehicleDetails.map((vehicle) => {
			const dealerCode = get(vehicle, 'dealer.code');
			return {
				...vehicle,
				dealer: get(dealersMap, dealerCode, currentDealer),
				status: OK,
			};
		})
	);
	const missingVehicleVins = difference(vinsToFetch, Object.keys(vehicleDetailsMap));
	const missingVehicleMap = Object.fromEntries(missingVehicleVins.map((vin) => [vin, { status: NoData }]));
	// TODO: use Error status for vehicles with no data
	merge(vehicleCache, missingVehicleMap, vehicleDetailsMap);
	return combineVehicleData(vehicles, vehicleCache);
};

/**
 * Because the scraper may poll multiple times (e.g. before finding pricing),
 * it's helpful to cache responses. If the scraper keeps polling over and over,
 * it could cause lots of unnecessary requests. This is a map of vin => vehicle
 * details from the vehicle service.
 */
const vehicleCache = {};

/**
 * clearVehicleCache is being exported only for testing. There is no other need for anything to use it.
 */
export const clearVehicleCache = () => {
	for (let vin in vehicleCache) {
		delete vehicleCache[vin];
	}
};

/**
 * formats discount object
 *
 * @param {number} amount
 * @param {string} dealerName
 * @returns {object}
 */
const formatDiscount = (amount, dealerName) => {
	const i18nDiscountName = getI18nDiscount(dealerName);

	return {
		code: 'DLR',
		title: i18nDiscountName.en,
		titleI18n: i18nDiscountName,
		taxable: 'PRETAX',
		amount,
		description: i18nDiscountName.en,
		descriptionI18n: i18nDiscountName,
	};
};

/**
 * Get data from the vehicle service endpoint, making as many requests as necessary.
 * @param {string[]} vins array with vins
 * @param {object} autofiData
 * @param {string} dealerCodeOverride
 */
const getVehicleData = async (vins, autofiData, dealerCodeOverride) => {
	const allVehicleObjects = [];

	let response;
	do {
		// eslint-disable-next-line no-await-in-loop
		response = await makeRequest(vins, autofiData, dealerCodeOverride, allVehicleObjects.length);
		if (Array.isArray(response?.data?.vehicles)) {
			allVehicleObjects.push(...response.data.vehicles);
		}
	} while (allVehicleObjects.length < response.pagination?.total);

	const vehiclesGroupedByVin = groupBy(allVehicleObjects, 'vin');

	const bestVehicleForEachVin = Object.values(vehiclesGroupedByVin).map((vehiclesForVin) => {
		const bestPriority = minBy(vehiclesForVin, 'dealerRoutingPriority')?.dealerRoutingPriority;
		const vehiclesWithBestPriority = vehiclesForVin.filter((v) => v.dealerRoutingPriority === bestPriority);
		return maxBy(vehiclesWithBestPriority, 'updatedAt') ?? vehiclesWithBestPriority[0];
	});

	const vehicles = bestVehicleForEachVin.map(
		({
			dealerCode: code,
			dealerName,
			photoUrls,
			sellingPrice: salePrice,
			updatedAt: lastUpdated,
			year,
			...vehicle
		}) => ({
			...vehicle,
			dealer: { code },
			discounts: vehicle.discount ? [formatDiscount(vehicle.discount, dealerName)] : [],
			lastUpdated,
			photoUrl: photoUrls?.[0] || '',
			salePrice,
			year: year?.toString(),
		})
	);

	return vehicles;
};

/**
 * Make the actual HTTP request to the vehicle service endpoint
 * @param {string[]} vins array with vins
 * @param {object} autofiData
 * @param {string} dealerCodeOverride
 * @param {number} offset
 */
const makeRequest = async (vins, autofiData, dealerCodeOverride, offset) =>
	new Promise((resolve) => {
		const { appName, dealersMap, vehicleServiceUrl } = autofiData;
		const normalizedQuery = parseQuery(window.location.href);
		const afEnable = normalizedQuery['af-enable'] === 'true';
		const dealerCodes = dealerCodeOverride
			? [dealerCodeOverride]
			: Object.keys(pickBy(dealersMap, (dealer) => afEnable || dealer.isLive));

		if (!vehicleServiceUrl || !vins.length || !dealerCodes.length) {
			resolve([]);
			return;
		}

		HTTPClient.post(
			{
				endpoint: { url: `${vehicleServiceUrl}/vehicles` },
				hippoHeaders: { autofiservice: `${appName}:vehiclesV2`, dealers: dealerCodes.join(',') },
				payload: {
					filters: {
						dealer: dealerCodes,
						vin: vins,
					},
					format: 'json',
					fields: [
						'age',
						'baseMsrp',
						'bodyType',
						'bookValue',
						'color',
						'dealerCode',
						'dealerName',
						'dealerRetailPrice',
						'dealerRoutingPriority',
						'discount',
						'fuelType',
						'invoice',
						'make',
						'maxDealerCash',
						'mileage',
						'model',
						'modelCode',
						'msrp',
						'packageCode',
						'photoUrls',
						'preInstalledAccessories',
						'rawDiscount',
						'sellingPrice',
						'stockNumber',
						'trim',
						'updatedAt',
						'vin',
						'year',
					],
					limit: 1000,
					offset,
				},
			},
			// eslint-disable-next-line handle-callback-err
			(err, response) => {
				try {
					const parsedResponse = JSON.parse(response);
					resolve(parsedResponse);
					return;
				} catch (_err) {
					// do nothing
				}
				resolve(null);
			}
		);
	});

/**
 * merge into a single array of complete vehicle data
 * @param {array} scrapedVehicles vehicle data scraped from the page
 * @param {object} vehicleDetailsMap map of vin => vehicle service details
 */
const combineVehicleData = (scrapedVehicles, vehicleDetailsMap) => {
	return scrapedVehicles.map((scrapedVehicle) => ({
		...scrapedVehicle,
		...get(vehicleDetailsMap, scrapedVehicle.vin, {}),
	}));
};

/**
 * Turn an array of vehicles into a vin-keyed object for easy lookup
 * @param {array} vehicles
 * @return {object} mapping of vin keys to vehicle objects
 */
const vinMap = (vehicles) =>
	keyBy(
		vehicles.filter((v) => v.vin),
		'vin'
	);

/**
 * @param {array} vehicles
 * @return {array} vins
 */
const extractVins = (vehicles) => vehicles.map((v) => v.vin).filter(Boolean);
