import clamp from 'lodash/clamp';
import { h, Component } from 'preact';
import { IntlProvider } from 'preact-i18n';

import DisclosurePopup from '~ui/components/disclosurePopup';
import InfoIcon from '~ui/components/infoIcon';
import * as utils from '~ui/utils';

export default class Estimate extends Component {
	state = {
		disclosureIsOpen: false,
	};

	handleBodyClick = () => {
		this.setState({ disclosureIsOpen: false });
	};

	handleDisclosureClick = (event) => {
		event.stopPropagation(); // don't bubble up to handleBodyClick
		this.setState((state) => ({ disclosureIsOpen: !state.disclosureIsOpen }));
		this.updatePopupPositionStyles();
	};

	updatePopupPositionStyles = () => {
		const bodyRect = document.body.getBoundingClientRect();
		// TODO: use this.getDOMNode() instead of this.base if it becomes supported
		const disclaimerRect = this.base.getBoundingClientRect();

		const popupPositionStyles = {
			top: disclaimerRect.bottom - bodyRect.top,
			right: bodyRect.width - disclaimerRect.right + bodyRect.left,
			width: clamp(window.innerWidth, 150, Math.min(bodyRect.right, 250)),
		};

		this.setState({ popupPositionStyles });
	};

	componentDidMount = () => {
		document.addEventListener('click', this.handleBodyClick);
		window.addEventListener('resize', this.updatePopupPositionStyles);
	};

	componentWillUnmount = () => {
		document.addEventListener('click', this.handleBodyClick);
		window.addEventListener('resize', this.updatePopupPositionStyles);
	};

	render = (props, state) => {
		const { amount, disclosure, text, dictionary } = props;
		const { disclosureIsOpen, popupPositionStyles } = state;

		return (
			<div class="estimateContainer">
				<span>
					{text}
					<InfoIcon active={disclosureIsOpen} onClick={this.handleDisclosureClick} />
				</span>
				<span class="estimate">{utils.formatMoney(amount)}</span>
				<DisclosurePopup class="disclosure estimateDisclosure" hidden={!disclosureIsOpen} style={popupPositionStyles}>
					<IntlProvider definition={dictionary}>{disclosure}</IntlProvider>
				</DisclosurePopup>
			</div>
		);
	};
}
