const get = require('lodash/get');
const loadDictionary = require('./loadDictionary');

/**
 * A helper to get localized text for a given key, options, locale
 * that can be used serverside or without relying on preact-i18n
 *
 * @param {String} key corresponding to a dictionary entry
 * @param {Object} [options={}] used to interpolate dynamic data into localized text
 * @param {String} [locale="en"] what locale we are getting localized text for
 *
 * @return {String} the localized text
 */
function localize(key, options = {}, locale = 'en') {
	const dictionary = loadDictionary(locale);
	let i18nValue = get(dictionary, key);

	for (const option in options) {
		i18nValue = i18nValue.replace(`{{${option}}}`, options[option]);
	}

	return i18nValue;
}

module.exports = localize;
