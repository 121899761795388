import { h, Component } from 'preact';
import styles from './formIcons.styl';
import withStyles from '~ui/components/withStyles';

const FormIcons = ({ icons, title, className, baseUrl }) => {
	return (
		<div className={`form_icons ${className}`}>
			{title ? <h3 className="form_icons-title">{title}</h3> : null}
			<div className="form_icons-items">
				{icons.map((icon) => (
					<div className="form_icons-item" key={icon.key}>
						{icon.Svg && <icon.Svg className="form_icons-item-img" alt={icon.label} />}
						{icon.Png && <img className="form_icons-item-img" src={`${baseUrl}/${icon.Png}`} alt={icon.label} />}
						{icon.label && <p className="form_icons-item-label">{icon.label}</p>}
					</div>
				))}
			</div>
		</div>
	);
};

export default withStyles(FormIcons, styles);
