import { h, Component, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import { iFrameMessages } from '~lib/enum';
import { getDriveTogetherOrigin } from '../utils';
import isolate from '~ui/components/isolate';
import withStyles from '~ui/components/withStyles';
import styles from './driveTogetherIframeButton.styl';

function DriveTogetherIframeButton({ dealerCode, vin, onClick }) {
	const origin = getDriveTogetherOrigin();

	useEffect(() => {
		function handleMessage(event) {
			const { type, vin: eventVin } = event.data;
			if (event.origin === origin && type === iFrameMessages.DriveTogetherButtonClicked && eventVin === vin) {
				onClick();
			}
		}
		window.addEventListener('message', handleMessage);
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [origin, vin, onClick]);

	return (
		origin &&
		dealerCode &&
		vin && (
			<iframe
				class="autofi-cta drive-together-button"
				data-autofi-cta-type="driveTogether"
				title={`drive-together-button-${vin}`}
				src={`${origin}/drive-together/${dealerCode}/${vin}/button`}
			/>
		)
	);
}

export default isolate(withStyles(DriveTogetherIframeButton, styles));
