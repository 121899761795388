import { h, Component } from 'preact';
import { Text } from 'preact-i18n';

import OverrideText from '~ui/components/overrideText';
import CloseIcon from '~ui/assets/close-icon.svg';

const FormHeader = ({ close, subTitle, title }) => {
	return (
		<header class="modalHeader">
			<button type="button" class="closeButton" onClick={close}>
				<Text id="close" />
				<CloseIcon />
			</button>
			<h2>
				<OverrideText content={title} />
			</h2>
			<h3>
				<OverrideText content={subTitle} />
			</h3>
		</header>
	);
};

export default FormHeader;
