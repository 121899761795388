import { h, Component, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import { iFrameMessages } from '~lib/enum';
import { getPrequalifyOrigin } from '../utils';
import isolate from '~ui/components/isolate';
import withStyles from '~ui/components/withStyles';
import styles from './prequalifyIframeButton.styl';

function PrequalifyIframeButton({ dealerCode, vin, onClick }) {
	const origin = getPrequalifyOrigin();
	const postMessageTarget = window.location.href;
	const queryParams = new URLSearchParams({ dealerCode, vin, postMessageTarget });

	useEffect(() => {
		function handleMessage(event) {
			if (event.origin === origin && event.data.type == iFrameMessages.AutoFiPrequalifyButtonClicked) {
				onClick();
			}
		}
		window.addEventListener('message', handleMessage);
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [origin, onClick]);

	return (
		origin && (
			<iframe
				class="autofi-cta autofi-prequalify-button"
				data-autofi-cta-type="prequalify"
				title={`prequalify-button-${vin}`}
				src={`${origin}/button?${queryParams}`}
			/>
		)
	);
}

export default isolate(withStyles(PrequalifyIframeButton, styles));
