const METHODS = {
	Get: 'GET',
	Post: 'POST',
	Put: 'PUT',
	Delete: 'DELETE',
};

const handleResponse = (res, cb) => {
	if (res.readyState !== 4 || !cb) {
		return;
	}

	if (res.status >= 300) {
		cb(res.response || res.responseText, null);
	} else if (res.readyState === 4) {
		cb(null, res.response || res.responseText);
	}
};

const request = (method, options, cb) => {
	const req = new XMLHttpRequest();
	req.open(method, options.endpoint.url, true);
	if (options.endpoint.secret) {
		req.setRequestHeader('authorization', options.endpoint.secret);
	}
	if (options.hippoHeaders) {
		req.setRequestHeader('autofiservice', options.hippoHeaders.autofiservice);
		req.setRequestHeader('dealers', options.hippoHeaders.dealers);
	}
	req.setRequestHeader('accept', 'application/json');
	req.setRequestHeader('content-type', 'application/json');
	if (options.withCredentials) {
		req.withCredentials = true;
	}

	const parseResponse = () => {
		handleResponse(req, cb);
	};

	if (typeof req.onload === 'function') {
		req.onload = parseResponse;
	} else {
		req.onreadystatechange = parseResponse;
	}

	if (options.payload) {
		req.send(JSON.stringify(options.payload));
	} else {
		req.send();
	}
	return req;
};

const get = (options, cb) => request(METHODS.Get, options, cb);
const post = (options, cb) => request(METHODS.Post, options, cb);
const put = (options, cb) => request(METHODS.Put, options, cb);

export default {
	handleResponse,
	METHODS,
	request,
	get,
	post,
	put,
};
