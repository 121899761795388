import { h } from 'preact';
import { createPortal } from 'preact/compat';

import isolate from '~ui/components/isolate';
import withStyles from '~ui/components/withStyles';
import styles from './disclosurePopup.styl';

const InnerDisclosurePopup = (props) => {
	const { children, ...componentProps } = props;
	return <div {...componentProps}>{children}</div>;
};

const StyledInnerDisclosurePopup = isolate(withStyles(InnerDisclosurePopup, styles));

const DisclosurePopup = (props) => {
	const { style, hidden, innerPopUpStyle, ...componentProps } = props;

	return createPortal(
		<div class="autofi-disclosure-popup" hidden={hidden} style={style}>
			<StyledInnerDisclosurePopup hidden={hidden} style={innerPopUpStyle} {...componentProps} />
		</div>,
		document.body
	);
};

export default DisclosurePopup;
