import { h, Component } from 'preact';
import ActiveIcon from '~ui/assets/info-icon-active.svg';
import InactiveIcon from '~ui/assets/info-icon-inactive.svg';
import withStyles from '~ui/components/withStyles';
import styles from './infoIcon.styl';

const InfoIcon = (props) => {
	return props.active ? (
		<ActiveIcon class="infoIcon active" onClick={props.onClick} />
	) : (
		<InactiveIcon class="infoIcon inactive" onClick={props.onClick} />
	);
};

export default withStyles(InfoIcon, styles);
