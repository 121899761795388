import get from 'lodash/get';

import HTTPClient from './HTTPClient';
import parseQuery from '~lib/parse-query';

const getConsumerSessionUrl = (baseUrl, launchDarklyFeatureFlags, path = '') => {
	const normalizedQuery = parseQuery(window.location.href);
	const penguinPR = normalizedQuery['af-penguin-pr'];

	const url = new URL(
		`${baseUrl}${launchDarklyFeatureFlags?.enableMfaAuthCheck ? '/v1/consumerSessions' : '/v1/sessions'}${path}`
	);

	if (penguinPR && launchDarklyFeatureFlags?.enableMfaAuthCheck) {
		url.searchParams.set('af-penguin-pr', penguinPR);
	}

	return url.toString();
};

const getSessionName = (sessionData) => {
	return get(sessionData, 'name', 'consumerSession');
};

/**
 * Get session ID from local Storage
 *
 * @param {object} sessionData
 * @param {boolean} sessionData.isInStore
 * @param {string} sessionData.name
 * @returns {string}
 */
const getSessionId = (sessionData) => {
	if (!get(sessionData, 'isInStore')) {
		const sessionName = getSessionName(sessionData);
		return window.localStorage.getItem(sessionName);
	}
	return null;
};

/**
 * Set session ID in local Storage
 * TODO: change that when cookies are reintegrated
 *
 * @param {object} sessionData
 * @param {boolean} sessionData.isInStore
 * @param {string} sessionData.name
 * @param {string} id
 * @returns {boolean}
 */
const set = (sessionData, id) => {
	if (!id || id === '') {
		return false;
	}

	const sessionName = getSessionName(sessionData);

	try {
		// fail-safe for incognito mode on some browsers
		window.localStorage.setItem(sessionName, id);
		return true;
	} catch (error) {
		return false;
	}
};

/**
 * Get consumerSession from the database
 *
 * @param {object} autofiData
 * @param {string} autofiData.apiProxyUrl
 * @param {string} autofiData.loanAppBaseUrl
 * @param {object} autofiData.launchDarklyFeatureFlags
 * @param {object} autofiData.sessionData
 * @param {boolean} autofiData.sessionData.isInStore
 * @param {string} autofiData.sessionData.name
 * @param {function} cb callback
 * @returns void
 */
const getFromDB = ({ apiProxyUrl, loanAppBaseUrl, launchDarklyFeatureFlags, sessionData }, cb) => {
	if (sessionData.isInStore) {
		return cb();
	}

	// get from local storage
	const consumerSession = getSessionId(sessionData);
	if (!consumerSession) {
		return cb();
	}

	const url = new URL(
		getConsumerSessionUrl(
			launchDarklyFeatureFlags.enableMfaAuthCheck ? apiProxyUrl : `${loanAppBaseUrl}/api`,
			launchDarklyFeatureFlags,
			'/customer'
		)
	);

	if (!launchDarklyFeatureFlags.enableMfaAuthCheck) {
		url.search = new URLSearchParams({ consumerSession });
	}

	const options = {
		endpoint: {
			url,
			...(launchDarklyFeatureFlags.enableMfaAuthCheck && { secret: `Bearer ${consumerSession}` }),
		},
	};

	HTTPClient.get(options, (error, response) => {
		let userInfo;
		try {
			if (response === '' || error) {
				// We return a empty object indicating that the session exists in db but is empty
				userInfo = {};
			} else {
				userInfo = JSON.parse(response);
			}
		} catch (_err) {
			// continue regardless of error
		}
		cb(userInfo);
	});
};

/**
 * @param {object} autofiData
 * @param {string} autofiData.apiProxyUrl
 * @param {object} autofiData.launchDarklyFeatureFlags
 * @param {object} autofiData.dealer
 * @param {string} autofiData.dealer.code
 * @param {string} consumerSession
 * @param {function} cb callback
 * @returns void
 */
const create = ({ apiProxyUrl, dealer, launchDarklyFeatureFlags }, consumerSession, cb) => {
	if (!apiProxyUrl) {
		if (cb) {
			cb();
		} else {
			return;
		}
	}

	const options = {
		endpoint: {
			url: getConsumerSessionUrl(apiProxyUrl, launchDarklyFeatureFlags),
			...(consumerSession && launchDarklyFeatureFlags.enableMfaAuthCheck && { secret: `Bearer ${consumerSession}` }),
		},
		...(launchDarklyFeatureFlags.enableMfaAuthCheck && { payload: { dealerCode: dealer.code } }),
	};

	HTTPClient.post(options, (error, response) => {
		let authToken;
		try {
			if (!error && response && response !== '') {
				const parsedResponse = JSON.parse(response);
				authToken = launchDarklyFeatureFlags.enableMfaAuthCheck ? parsedResponse.authToken : parsedResponse.id;
			}
		} catch (e) {
			// continue regardless of error
		}

		if (cb) {
			cb(authToken);
		}
	});
};

/**
 * Create a consumerSession if the one in localStorage is empty
 *
 * @param {object} autofiData
 * @param {string} autofiData.apiProxyUrl
 * @param {object} autofiData.launchDarklyFeatureFlags
 * @param {object} autofiData.dealer
 * @param {object} autofiData.dealer.settings
 * @param {object} autofiData.dealer.settings.mfa
 * @param {string[]} autofiData.dealer.settings.mfa.pathways
 * @param {object} autofiData.sessionData
 * @param {boolean} autofiData.sessionData.isInStore
 * @param {string} autofiData.sessionData.name
 * @param {function} [cb] callback
 */
const createSession = (autofiData, cb) => {
	const { sessionData, launchDarklyFeatureFlags } = autofiData;

	// Check in localStorage if we have a session ID
	const consumerSession = getSessionId(sessionData);

	if (consumerSession && !launchDarklyFeatureFlags?.enableMfaAuthCheck) {
		if (cb) {
			cb(consumerSession);
		}
		return;
	}

	create(autofiData, consumerSession, (consumerSession) => {
		// Set new session id in localStorage
		set(sessionData, consumerSession);

		if (cb) {
			cb(consumerSession);
		}
	});
};

const checkAuthStatus = async ({ apiProxyUrl, launchDarklyFeatureFlags, sessionData }) => {
	const token = getSessionId(sessionData);

	const options = {
		endpoint: {
			url: getConsumerSessionUrl(apiProxyUrl, launchDarklyFeatureFlags),
			...(token && { secret: `Bearer ${token}` }),
		},
	};

	return new Promise((resolve, reject) => {
		HTTPClient.get(options, (err, response) => {
			let responseData = null;
			try {
				responseData = JSON.parse(response);
			} catch (_err) {
				// do nothing
				return reject(_err);
			}
			if (responseData && !err) {
				return resolve({
					isAuthorized: Boolean(responseData?.isAuthorized),
					isLocked: Boolean(responseData?.isLocked),
				});
			} else {
				// eslint-disable-next-line no-console
				console.error('Sorry! Something went wrong on our end.');

				return reject(err);
			}
		});
	});
};

export default {
	checkAuthStatus,
	create,
	createSession,
	getConsumerSessionUrl,
	getFromDB,
	getSessionId,
	getSessionName,
	set,
};
