import { h, Component } from 'preact';

import styles from './fullPageLoadingAnimation.styl';
import isolate from '~ui/components/isolate';
import modal from '~ui/components/modal';
import withStyles from '~ui/components/withStyles';

const FullPageLoadingAnimation = () => (
	<div className="autofi-loading-animation">
		<div className="top-bar bar"></div>
		<div className="middle-bar bar"></div>
		<div className="bottom-bar bar"></div>
	</div>
);

export default modal(isolate(withStyles(FullPageLoadingAnimation, styles)), {
	containerClass: 'autofi-loading-animation-container',
});
