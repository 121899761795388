import defaultTo from 'lodash/defaultTo';
import includes from 'lodash/includes';
import { h, Component } from 'preact';

import isolate from '~ui/components/isolate';
import modal from '~ui/components/modal';

class ModalSequence extends Component {
	state = { currentPageIndex: 0 };

	showPreviousPage = () => {
		this.setState((prevState) => ({ currentPageIndex: prevState.currentPageIndex - 1 }), this.props.scrollToTop);
	};

	showNextPage = () => {
		this.setState((prevState) => ({ currentPageIndex: prevState.currentPageIndex + 1 }), this.props.scrollToTop);
	};

	showPage = (page) => {
		const { pages } = this.props;
		if (includes(pages, page)) {
			this.setState({ currentPageIndex: pages.indexOf(page) });
		} else {
			// eslint-disable-next-line no-console
			console.warn('Page not found', page);
		}
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.hidden && !this.props.hidden) {
			this.setState({ currentPageIndex: 0 });
		}
	};

	render = (props, state) => {
		const { pages, startPageIndex, ...pageProps } = props;
		const CurrentPage = pages[defaultTo(startPageIndex, state.currentPageIndex)];

		return (
			<CurrentPage
				{...pageProps}
				showNextPage={this.showNextPage}
				showPage={this.showPage}
				showPreviousPage={this.showPreviousPage}
			/>
		);
	};
}

export default modal(isolate(ModalSequence));
