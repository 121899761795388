import get from 'lodash/get';
import { h, Component } from 'preact';
import { IntlProvider, Text, MarkupText, Localizer } from 'preact-i18n';

import OverrideText from '~ui/components/overrideText';
import { AmplitudeLabelComponents, Pathways, VehicleStatus } from '~lib/enum';
import AutoFiLogo from '~ui/assets/autofi-logo.svg';
import CloseIcon from '~ui/assets/close-icon.svg';
import styles from '../creditApp/sequence.styl';
import BrandSpecificText from '~ui/components/brandSpecificText';
import makePhoneNumberPlaceholder from '~ui/components/makePhoneNumberPlaceholder';
import PrettyAddressInput from '~ui/components/prettyAddressInput';
import PrettyInput from '~ui/components/prettyInput';
import SubmitButton from '~ui/components/submitButton';
import withStyles from '~ui/components/withStyles';
import * as utils from '~ui/utils';
import { trackGoogleUniversalAnalyticsEvent, trackShiftDigitalEvent } from '~ui/utils/analytics';
import { trackGoogleAnalytics4Event } from '~ui/utils/googleAnalytics';

class Form extends Component {
	state = {
		formData: { rememberMe: true },
		inputValidity: {},
		submitEvent: null,
		touched: false,
	};

	inputIsValid = (inputName) => {
		// If valid property is undefined, input hasn't been touched, so don't
		// consider it invalid.
		return Boolean(get(this.state.inputValidity, [inputName, 'valid'], true));
	};

	formIsValid = () => {
		// Usually, this.base refers to the form. But when the component is
		// mounting, this.base is undefined, so in that case, avoid calling
		// checkValidity.
		return (
			this.base &&
			this.base.checkValidity() &&
			this.inputIsValid('name') &&
			this.inputIsValid('street') &&
			this.inputIsValid('city') &&
			this.inputIsValid('state') &&
			this.inputIsValid('zip') &&
			this.phoneAndEmailAreValid()
		);
	};

	phoneAndEmailAreValid = () => {
		const { requirePhone } = this.props.autofiData.dealer.websiteSettings.ui.features.getYourPrice;
		const { email, phone } = this.state.formData;
		const emailRegex = new RegExp(`^${utils.validationPatterns.email}$`, 'u');
		return Boolean(emailRegex.test(email) && (requirePhone ? phone : true));
	};

	handleInputChange = (event) => {
		const { target } = event;
		this.setState({ touched: true });
		this.updateInputState(target);
	};

	getInputState = ($input) => {
		const value = $input.type === 'checkbox' ? $input.checked : $input.value;
		const { name, validity } = $input;

		return { name, validity, value };
	};

	updateInputState = ($input) => {
		const { name, validity, value } = this.getInputState($input);

		this.setState((prevState) => ({
			formData: { ...prevState.formData, [name]: value },
			inputValidity: { ...prevState.inputValidity, [name]: validity },
		}));
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ submitEvent: event });
	};

	submit = () => {
		const { autofiData, pageType, propagateApplicant, showNextPage, trackAmplitudeEvent, vehicle } = this.props;
		const { formData, submitEvent } = this.state;
		const { dealer: currentDealer, sessionData } = autofiData;
		const dealer = vehicle.dealer || currentDealer;
		const applicant = utils.makeApplicant({ contactData: formData, dealer });
		applicant.isTestUser = utils.isTestUser(applicant);

		const googleEventMetaData = { dealer: currentDealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_submission',
			{ form_name: 'private_offer', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_submission_sales',
			{ form_name: 'private_offer', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'private_offer', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);

		trackGoogleUniversalAnalyticsEvent('Private Offer Lead', googleEventMetaData);

		trackShiftDigitalEvent('drLeadFormFinish', vehicle, currentDealer, sessionData, applicant, pageType);

		trackAmplitudeEvent({
			event: submitEvent,
			label: AmplitudeLabelComponents.Label.PathwaySubmit,
			opts: { isTestUser: applicant.isTestUser },
			pathway: Pathways.PrivateOffers,
			vehicle,
		});

		showNextPage();
		// @TODO: proxy applicant data to cobra so that cobra use it to call new endpoint for private offers

		propagateApplicant(applicant, vehicle, () => this.setState({ submitEvent: null }));
	};

	initializeInputState = () => {
		[...this.base.querySelectorAll('input, textarea, select')].forEach(this.updateInputState);
	};

	handlePlaceChange = (addressDetails) => {
		const { formData } = this.state;
		const address = {
			street: get(addressDetails, 'street_number', ''),
			city: get(addressDetails, 'locality', ''),
			state: get(addressDetails, 'administrative_area_level_1', ''),
			zip: get(addressDetails, 'postal_code', ''),
		};

		[...this.base.querySelectorAll('input')]
			.filter(($input) => $input.name in address)
			.forEach(($input) => {
				$input.value = address[$input.name];
				this.updateInputState($input);
			});

		// Update value in state of inputs to properly set 'empty' class
		[...this.base.querySelectorAll(':not(.prettyAddressInput) > input')].forEach(($input) =>
			$input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
		);

		this.base.querySelector('[name=street2]').focus();
		this.setState({
			formData: {
				...formData,
				...address,
			},
		});
	};

	trackGa4LoadEvents = () => {
		const { autofiData, vehicle } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event('asc_page_view', { page_type: 'sales', vehicle }, googleEventMetaData);
	};

	componentDidMount = () => {
		this.initializeInputState();

		if (!this.props.hidden) {
			this.trackGa4LoadEvents();
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { applicant, autofiData, hidden, pageType, vehicle } = this.props;
		const { dealer: currentDealer, sessionData } = autofiData;
		const { submitEvent, touched } = this.state;

		const formBecameVisible = prevProps.hidden && !hidden;
		if (formBecameVisible) {
			this.initializeInputState();
			this.trackGa4LoadEvents();
		}

		if (!prevState.touched && touched) {
			trackShiftDigitalEvent('drLeadFormStart', vehicle, currentDealer, sessionData, applicant, pageType);
		}

		const { Pending } = VehicleStatus;
		const prevVehicleStatus = get(prevProps.vehicle, 'status', Pending);
		const vehicleStatus = get(vehicle, 'status', Pending);
		const formWasReadyToSubmit = Boolean(prevVehicleStatus !== Pending && prevState.submitEvent);
		const formIsReadyToSubmit = Boolean(vehicleStatus !== Pending && submitEvent);
		if (!formWasReadyToSubmit && formIsReadyToSubmit) {
			this.submit();
		}
	};

	handleClose = () => {
		const { autofiData, close, vehicle } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'private_offer', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);

		close();
	};

	render = (props, state) => {
		const { applicant, autofiData, dictionary, subTitle, title, vehicle } = props;
		const { dealer, googleApiKey } = autofiData;
		const { requirePhone } = dealer.websiteSettings.ui.features.getYourPrice;
		const { formData, submitEvent } = state;
		const brand = get(vehicle, 'dealer.brand');
		const street = formData.street === '' ? applicant.address.street : formData.street;
		const hint = utils.makeAddressHintMessage(street);

		return (
			<IntlProvider definition={dictionary}>
				<form class="autofi-modal privateOffersPage privateOffersForm">
					<header class="modalHeader">
						<button type="button" class="closeButton" onClick={this.handleClose}>
							<Text id="close" /> <CloseIcon />
						</button>
						<h2>
							{title ? (
								<OverrideText content={title} />
							) : (
								<BrandSpecificText id="view-your-private-offer" brand={brand} />
							)}
						</h2>
						{subTitle && (
							<h3>
								<OverrideText content={subTitle} />
							</h3>
						)}
					</header>
					<div class="modalContent">
						<form class="autofi-form">
							<div class="row">
								<Localizer>
									<PrettyInput
										defaultValue={applicant.name.full}
										name="name"
										onInput={this.handleInputChange}
										pattern={utils.validationPatterns.fullname}
										placeholder={<Text id="full-name">Full Name</Text>}
										required
										hint={utils.hasNoNumbers(formData.name) ? '' : <Text id="invalid-name-numbers-are-not-allowed" />}
									/>
								</Localizer>
							</div>
							<div class="row">
								<Localizer>
									<PrettyAddressInput
										name="street"
										containerClass="streetContainer"
										defaultValue={applicant.address.street}
										placeholder={<Text id="address">Address</Text>}
										required
										onInput={this.handleInputChange}
										googleApiKey={googleApiKey}
										onPlaceChange={this.handlePlaceChange}
										hint={<Text id={hint} />}
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										name="street2"
										containerClass="street2Container"
										defaultValue={applicant.address.street2}
										placeholder={<Text id="apt">Apt</Text>}
										onInput={this.handleInputChange}
									/>
								</Localizer>
							</div>
							<div class="row">
								<Localizer>
									<PrettyInput
										name="city"
										containerClass="cityContainer"
										defaultValue={applicant.address.city}
										placeholder={<Text id="city">City</Text>}
										required
										onInput={this.handleInputChange}
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										name="state"
										containerClass="stateContainer"
										defaultValue={applicant.address.state}
										maxlength={2}
										pattern="[a-zA-Z]{2}"
										placeholder={<Text id="state">State</Text>}
										required
										hint={this.inputIsValid('state') || !formData.state ? '' : <Text id="invalid-state" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										name="zip"
										containerClass="zipContainer"
										defaultValue={applicant.address.zip}
										pattern="\d{5}|[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d"
										placeholder={<Text id="zip-code">Zip code</Text>}
										required
										hint={this.inputIsValid('zip') || !formData.zip ? '' : <Text id="invalid-zip-code" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
							</div>
							<div class="row">
								<Localizer>
									<PrettyInput
										defaultValue={applicant.email}
										hint={this.inputIsValid('email') || !formData.email ? '' : <Text id="invalid-email-address" />}
										name="email"
										onInput={this.handleInputChange}
										pattern={utils.validationPatterns.email}
										placeholder={<Text id="email">Email</Text>}
										required
										type="email"
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										defaultValue={applicant.phone}
										hint={this.inputIsValid('phone') || !formData.phone ? '' : <Text id="invalid-phone-number" />}
										name="phone"
										onInput={this.handleInputChange}
										pattern={utils.validationPatterns.phone}
										placeholder={makePhoneNumberPlaceholder({ requirePhone })}
										type="tel"
									/>
								</Localizer>
							</div>
							<div>
								<label class="checkboxContainer">
									<input
										type="checkbox"
										name="rememberMe"
										checked={formData.rememberMe}
										onChange={this.handleInputChange}
									/>
									<div>
										<Text id="remember-me" />
										<div class="explanation">
											<MarkupText id="you-wont-have-to-fill-out" />
										</div>
									</div>
								</label>
							</div>
							<div class="row">
								<SubmitButton
									onClick={this.handleSubmit}
									disabled={!this.formIsValid()}
									submitting={Boolean(submitEvent)}
								>
									<Text id="show-offer" />
								</SubmitButton>
							</div>
							<div class="poweredBy">
								<Text id="powered-by" /> <AutoFiLogo class="autoFiLogo" />
							</div>
						</form>
					</div>
				</form>
			</IntlProvider>
		);
	};
}

const WrappedPrivateOffersForm = withStyles(Form, styles);

WrappedPrivateOffersForm.allRequiredFieldsFilled = (dealer, applicant) => {
	const { address, email, name, phone, rememberMe } = applicant;

	if (!rememberMe) {
		// always make them resubmit the form if they didnt want to be remembered
		return false;
	}

	const { features } = dealer.websiteSettings.ui;
	const requirePhone = get(features, 'getYourPrice.requirePhone', false);
	return Boolean(
		address.city &&
			address.state &&
			address.street &&
			address.zip &&
			name.full &&
			email &&
			(requirePhone ? phone : true)
	);
};

export default WrappedPrivateOffersForm;
