import { h, Component } from 'preact';
import { Localizer } from 'preact-i18n';
import compact from 'lodash/compact';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

import styles from './prettyInput.styl';
import withStyles from '~ui/components/withStyles';
import * as utils from '~ui/utils';

const zeroWidthSpace = '\u200b';

class PrettyDropdown extends Component {
	static defaultProps = { hint: undefined };

	state = {
		open: false,
		prevIndex: undefined,
		selectedIndex: 0,
		touched: false,
	};

	onChange = (event) => {
		const { name, onChange } = this.props;
		const { index, value } = event.target.dataset;
		const { validity } = this.$input;
		onChange({
			target: { value, name, validity },
		});
		this.setState({ open: false, selectedIndex: index, touched: true });
	};

	toggle = () => {
		this.setState((prevState) => ({ open: !prevState.open, prevIndex: prevState.selectedIndex }), this.adjustScroll);
		this.$input.focus();
	};

	handleChange = () => {
		const { name, onChange, options, value } = this.props;
		const { selectedIndex } = this.state;
		const { validity } = this.$input;
		const prevValue = get(options[selectedIndex], 'value');
		if (value !== prevValue) {
			onChange({
				target: { value, name, validity },
			});
		}
		const index = findIndex(options, { value });
		this.setState({ selectedIndex: index === -1 ? 0 : index });
	};

	componentDidMount = () => {
		const { options, value } = this.props;

		document.addEventListener('click', this.handleBodyClick);

		const index = findIndex(options, { value });
		this.setState({ selectedIndex: index === -1 ? 0 : index });
	};

	componentDidUpdate = (prevProps) => {
		const { options, value } = this.props;
		if (prevProps.value !== value || !isEqual(prevProps.options, options)) {
			this.handleChange();
		}
	};

	componentWillUnmount = () => {
		document.removeEventListener('click', this.handleBodyClick);
	};

	handleBodyClick = (event) => {
		if (!this.base.contains(event.target)) {
			this.setState({ open: false });
		}
	};

	adjustScroll = () => {
		if (this.state.open) {
			const containerRect = this.$options.getBoundingClientRect();
			const $selectedOption = this.$options.children[this.state.selectedIndex];
			const optionRect = $selectedOption.getBoundingClientRect();
			if (optionRect.top <= containerRect.top || optionRect.bottom >= containerRect.bottom) {
				this.$options.scrollTop = $selectedOption.offsetTop - (containerRect.height - optionRect.height) / 2;
			}
		}
	};

	handleKeyDown = (event) => {
		const { open, prevIndex, selectedIndex } = this.state;
		// 'Down' and 'Up' are used by Edge. 'ArrowDown' and 'ArrowUp' are used by
		// other browsers.
		if (open) {
			const { options } = this.props;
			const isModified = event.altKey || event.ctrlKey || event.metaKey;
			const newIndex = {
				Enter: selectedIndex,
				ArrowUp: isModified ? 0 : Math.max(0, selectedIndex - 1),
				Up: isModified ? 0 : Math.max(0, selectedIndex - 1),
				ArrowDown: isModified ? options.length - 1 : Math.min(options.length - 1, selectedIndex + 1),
				Down: isModified ? options.length - 1 : Math.min(options.length - 1, selectedIndex + 1),
				Escape: prevIndex,
			}[event.key];
			if (newIndex !== undefined) {
				this.setState({ selectedIndex: newIndex }, this.adjustScroll);
			}
		}
		if (['ArrowDown', 'ArrowUp', 'Down', 'Up'].includes(event.key) && !open) {
			this.setState({ open: true }, this.adjustScroll);
		}
		if (['ArrowDown', 'ArrowUp', 'Down', 'Up', 'Enter', 'Escape'].includes(event.key)) {
			event.preventDefault();
		}
		if (['Enter', 'Escape', 'Tab'].includes(event.key)) {
			this.setState({ open: false });
		}
	};

	get validity() {
		return this.$input.validity;
	}

	render = (props, state) => {
		const value = get(props.options[state.selectedIndex], 'value');

		const containerClasses = compact([
			'prettyDropdown',
			value ? '' : 'empty',
			state.touched ? 'touched' : '',
			state.open ? 'open' : '',
		]).join(' ');

		return (
			<div class={containerClasses}>
				<Localizer>
					<input
						{...omit(props, ['onChange', 'options'])}
						autocomplete="off"
						onClick={this.toggle}
						onKeyDown={this.handleKeyDown}
						readonly={utils.isIos() ? state.open : true}
						ref={($input) => (this.$input = $input)}
						value={value}
					/>
				</Localizer>
				{state.open ? (
					<div class="options" ref={($options) => (this.$options = $options)}>
						{props.options.map((option, i) => (
							<div
								key={option.value}
								class={state.selectedIndex === i ? 'focus' : ''}
								data-index={i}
								data-value={option.value}
								onClick={this.onChange}
							>
								{option.text || zeroWidthSpace}
							</div>
						))}
					</div>
				) : null}
				<label>{props.placeholder}</label>
				{isNil(props.hint) ? null : <div class="hint">{props.hint || zeroWidthSpace}</div>}
			</div>
		);
	};
}

export default withStyles(PrettyDropdown, styles);
