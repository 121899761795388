/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
const DEFAULT_LOCALE = 'en';
const DEFAULT_FRENCH_LOCALE = 'fr-CA';
const LOCALES = [DEFAULT_LOCALE, 'en-CA', DEFAULT_FRENCH_LOCALE];

module.exports = (locale) => {
	if (LOCALES.includes(locale)) {
		return require(`./${locale}.json`);
	} else if (locale.startsWith('fr-')) {
		return require(`./${DEFAULT_FRENCH_LOCALE}.json`);
	} else {
		return require(`./${DEFAULT_LOCALE}.json`);
	}
};
