import { h, Component } from 'preact';
import { IntlProvider, Text, MarkupText } from 'preact-i18n';

import AutoFiLogo from '~ui/assets/autofi-logo.svg';
import CloseIcon from '~ui/assets/close-icon.svg';
import FormIcons from '~ui/components/form/formIcons';
import GetMonthlyPaymentIcon from '~ui/assets/monthly-payment-icon.svg';
import GetMaxLoanIcon from '~ui/assets/car-loan-icon.svg';
import styles from './sequence.styl';
import withStyles from '~ui/components/withStyles';

const Intro = (props) => {
	const { close, showNextPage, dictionary } = props;

	return (
		<IntlProvider definition={dictionary}>
			<div class="autofi-modal creditAppPage intro">
				<header class="modalHeader">
					<button type="button" class="closeButton" onClick={close}>
						<Text id="close" /> <CloseIcon />
					</button>
					<h2>
						<Text id="find-your-budget" />
					</h2>
					<h3>
						<MarkupText id="lets-get-started-with-your-personalized-estimates" />
					</h3>
				</header>
				<div class="modalContent">
					<form action="">
						<FormIcons
							className="creditAppIntro"
							icons={[
								{
									key: 1,
									Svg: GetMonthlyPaymentIcon,
									label: <Text id="see-monthly-payment" />,
								},
								{
									key: 2,
									Svg: GetMaxLoanIcon,
									label: <Text id="see-loan-amount" />,
								},
							]}
						/>

						<div class="buttonContainer">
							<div class="row">
								<button type="button" onClick={showNextPage}>
									<Text id="lets-get-started" />
								</button>
							</div>
						</div>
						<div class="poweredBy">
							<Text id="powered-by" /> <AutoFiLogo class="autoFiLogo" />
						</div>
					</form>
				</div>
			</div>
		</IntlProvider>
	);
};

export default withStyles(Intro, styles);
