import { h, Component } from 'preact';
import { MarkupText } from 'preact-i18n';

// This is a wrapper for preact-i18n's `MarkupText` component, to be used for text
// that is either static copy defined in our dictionary OR text overriden by dealer configuration
// It takes a `content` prop, which is provided as both the `id` and the children to `MarkupText`
// `MarkupText` will look for a definition in the dictionary provided to its `IntlProvider`
// if no translation is found in the dictionary, it falls back to the child text (i.e. the dealer override)
class OverrideText extends Component {
	// This method prevents text from spontaneously getting selected or
	// unselected when things change, apparently as a result of something
	// strange happening within the preact-i18n library.
	shouldComponentUpdate = (nextProps) => nextProps.content !== this.props.content;

	render = ({ content }) => {
		// in case content is undefined for some reason, just render null
		return content ? <MarkupText id={content}>{content}</MarkupText> : null;
	};
}

export default OverrideText;
