import find from 'lodash/find';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import result from 'lodash/result';

import consumerSession from './consumerSession';
import { applicantHasContactInfoInSession, errorToConsole } from '~ui/utils';

export { trackAmplitudeEvent } from '~ui/utils/amplitude';

/**
 * Gets existing ga tracker matching gaTrackingId
 *
 * @param {string} gaTrackingId
 */
export const getGaTracker = (gaTrackingId = '') => {
	const trackers = result(window.ga || {}, 'getAll', []);
	return find(trackers, (t) => t.get && t.get('trackingId') === gaTrackingId);
};

/**
 * Gets Google Analytics current ClientId
 *
 * @param {string} gaTrackingId
 * @param {Object} sessionData
 * @param {boolean} sessionData.isInStore
 * @return {string=} gaClientId
 */
export const getGaClientId = (gaTrackingId, { isInStore }) => {
	if (!isInStore) {
		const tracker = getGaTracker(gaTrackingId);
		return tracker && tracker.get ? tracker.get('clientId') : undefined;
	}
};

/**
 * Sends a Google Universal Analytics event
 *
 * @param {string} action - string that will appear as the event action in Google Analytics Event reports
 * @param {Object} metadata
 * @param {boolean} metadata.isInStore
 * @param {Object} metadata.dealer - currentDealer
 */
export const trackGoogleUniversalAnalyticsEvent = (action, { dealer, isInStore }) => {
	if (isInStore) {
		return false;
	}

	let measurementId;

	try {
		const googleAnalyticsMeasurementIds = dealer.settings.analytics?.googleAnalyticsMeasurementIds || [];
		measurementId = googleAnalyticsMeasurementIds.find((id) => id.toLowerCase().startsWith('ua'));
	} catch (e) {
		/* do nothing */
	}

	// no id found for universal analytics, move on.
	if (!measurementId) {
		return false;
	}

	if (window.gtag) {
		window.gtag('event', action, {
			event_category: 'AutoFI',
			send_to: measurementId,
		});

		return true;
	}

	if (window.ga) {
		// otherwise we try to use Google Analytics legacy library
		const tracker = getGaTracker(measurementId);
		if (!tracker) {
			// if we can't find a tracker matching our id, we create a new one
			window.ga('create', measurementId, 'auto', { name: 'aftracker' });
			window.ga('aftracker.send', 'event', 'AutoFI', action);
		} else {
			window.ga(`${tracker.get('name')}.send`, 'event', 'AutoFI', action);
		}
		return true;
	}

	return false;
};

export const getShiftDigitalSessionId = () =>
	new Promise((resolve) => {
		if (typeof window.sd === 'function') {
			window.sd('getSessionId', (sessionId) => resolve(sessionId));
		} else {
			resolve(undefined);
		}
	});

export const trackShiftDigitalEvent = (
	eventType,
	vehicle,
	currentDealer,
	sessionData,
	applicant,
	pageType,
	options = {}
) => {
	const { brand, settings, websiteSettings } = currentDealer;
	const { shiftDigital = {} } = settings.analytics || {};
	const { enabled: isShiftMember, trackingEnabled } = shiftDigital;
	const { showPaymentDetailsOnButton } = websiteSettings.ui[pageType] ?? {};
	const lockedPricingEnabled = websiteSettings.ui.features.lockedPricing.enabled;
	const { isInStore } = sessionData;

	const {
		addOnName,
		addOnType,
		dealType: rawDealType,
		depositAmt,
		depositMade,
		isSubaruGTP,
		offerName,
		offerType: rawOfferType,
	} = options;

	if (!(isShiftMember && trackingEnabled)) {
		return;
	}

	if (isInStore && brand !== 'kia') {
		// Track event in-store only for Kia dealers, and out-of-store for all
		// Shift members.
		return;
	}

	if (typeof window.sd !== 'function') {
		// Don't try to track if window.sd object is not defined.
		return;
	}

	const sessionId = consumerSession.getSessionId(sessionData);

	// priceUnlocked will be passed in options in events triggered by deal
	// maker.
	const priceUnlocked =
		options.priceUnlocked ??
		Boolean(
			!showPaymentDetailsOnButton || !lockedPricingEnabled || applicantHasContactInfoInSession(applicant, currentDealer)
		);

	const dealType = { cash: 'Cash', finance: 'Finance', lease: 'Lease' }[rawDealType?.toLowerCase()];
	const offerType = { cash: 'Cash', finance: 'Finance', lease: 'Lease' }[rawOfferType?.toLowerCase()];

	window.sd('dataLayer', {
		digRet: {
			dealId: sessionId,
			priceUnlocked,

			// addOnName and addOnType are used for events for F&I and accessories,
			// sent from deal maker. depositAmt and depositMade are used for the
			// drContractFinish event. offerType is used by the drOfferAdded event.
			// dealType is used by many events.
			...pickBy(
				{ addOnName, addOnType, dealType, depositAmt, depositMade, offerName, offerType },
				(val) => val !== undefined
			),

			provider: 'AutoFi',
			...(vehicle && {
				...(vehicle.year && { vehicleYear: vehicle.year.toString() }),
				...(vehicle.make && { vehicleMake: vehicle.make }),
				...(vehicle.model && { vehicleModel: vehicle.model }),
				...(vehicle.vin && { vehicleVin: vehicle.vin }),
				...(vehicle.age && {
					vehicleStatus: { new: 'New', used: 'Used', cpo: 'CPO' }[vehicle.age],
				}),
			}),
		},
		...(typeof isSubaruGTP === 'boolean' && { isGTP: isSubaruGTP }),
		events: eventType,
	});

	window.sd('send');
};

export const trackAdobePixelEvent = (eventType, type) => {
	const { location, top } = window;
	top.postMessage({ eventType, type }, location.origin);
};

/*
 * Validates if we should inject JDPower scripts into the dealer site
 * @param {Object} dealer - currentDealer
 */
export const shouldAddJDPowerScripts = (dealer) => {
	const { brand, settings } = dealer;

	const isJdPowerMember = get(settings, 'analytics.jdPower.enabled');
	const trackingEnabled = get(settings, 'analytics.jdPower.trackingEnabled');
	const country = get(dealer, 'address.contracts.country');

	return Boolean(['honda', 'acura'].includes(brand) && isJdPowerMember && trackingEnabled && country === 'US');
};

/*
 * Validates if the dealer should track events for JD Power
 * @param {Object} dealer - currentDealer
 */
export const shouldTrackJDPowerEvents = (dealer) => {
	const { settings } = dealer;
	const oemSalesCode = get(settings, 'oem.dealerNumber');

	return Boolean(shouldAddJDPowerScripts(dealer) && oemSalesCode);
};
/**
 * @typedef {"DRS-Start" | "DRS-Credit-Application" | "DRS-Payment-Calculator" | "DRS-Trade-in" | "DRS-Test-Drive" | "DRS-Credit-Application-End" | "DRS-Payment-Calculator-End" | "DRS-Trade-in-End" | "DRS-Test-Drive-End" } DRSEventValueType
 */

/**
 * Sends a Honda Tagging event
 *
 * @param {DRSEventValueType} eventValue - Event Value to be Pushed to the Data Layer
 * @param {Object|null|undefined} [vehicle] - vehicle data
 * @param {Object} dealer - currentDealer
 */
export const trackJdPowerEvent = (eventValue, vehicle, dealer) => {
	/*
	 * @type {('drs'|'mps')}
	 */
	const eventType = eventValue.includes('DRS-') ? 'drs' : 'mps';
	const shouldTrack = shouldTrackJDPowerEvents(dealer);
	const oemSalesCode = get(dealer.settings, 'oem.dealerNumber');

	if (!shouldTrack) {
		// Track event only for honda dealers with OEM Source set and country US
		// JD Power members with tracking enabled
		return;
	}

	if (!Array.isArray(window.dataLayer)) {
		return;
	}

	try {
		window.dataLayer.push({
			event: eventType,
			[eventType === 'drs' ? 'DRSType' : 'MPSType']: eventValue,
			Dealerid: oemSalesCode,
			ProviderID: 'AUT',
			'content-group': 'DRS',
			...(vehicle && {
				...(vehicle.year && { vehicleYear: vehicle.year.toString() }),
				...(vehicle.make && { vehicleMake: vehicle.make }),
				...(vehicle.model && { vehicleModel: vehicle.model }),
				...(vehicle.vin && { vehicleVin: vehicle.vin }),
				...(vehicle.age && { vehicleAge: vehicle.age }),
				...(vehicle.dealerRetailPrice && { vehicleMsrp: vehicle.dealerRetailPrice }),
				...(vehicle.salePrice && { vehicleSellingPrice: vehicle.salePrice }),
			}),
		});
	} catch (err) {
		errorToConsole(err);
	}
};
