import { h, Fragment } from 'preact';
import { createPortal } from 'preact/compat';
import { GTM } from './googleTagScript';

const JDPowerScripts = ({ dealer }) => {
	const { brand, settings } = dealer;
	const isHonda = brand === 'honda';
	const oemSalesCode = settings?.oem?.dealerNumber;

	return (
		oemSalesCode && (
			<Fragment>
				{isHonda && (
					<Fragment>
						{createPortal(<GTM gtmId="GTM-ND7WWDN" shouldAddDataLayerScript shouldAddScript />, document.head)}
						{createPortal(<GTM gtmId="GTM-ND7WWDN" shouldAddNoScript />, document.body)}
					</Fragment>
				)}
			</Fragment>
		)
	);
};

export default JDPowerScripts;
