import compact from 'lodash/compact';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import { h, Component } from 'preact';
import { Text, MarkupText } from 'preact-i18n';
import dateFormat from 'date-fns/format';
import dateParse from 'date-fns/parse';

import { Pathways } from '~lib/enum';
import * as utils from '~ui/utils';
import CheckmarkIcon from '~ui/assets/checkmark-icon.svg';
import CloseIcon from '~ui/assets/close-icon.svg';
import BrandSpecificText from '~ui/components/brandSpecificText';
import NoOfferFound from '~ui/components/privateOffers/noOfferFound';
import Progress from '~ui/components/modalIframeProgress';
import withStyles from '~ui/components/withStyles';
import DiscountTable from './discountTable';

import styles from './index.styl';

class InterstitialPanel extends Component {
	getHeaderContent = (anyPrivateOffers) => {
		const { applicant, discountAndRebatesAmount, pathway, vehicle } = this.props;

		if (pathway === Pathways.RequestMoreInfo) {
			return (
				<div>
					<h2>{<MarkupText id="information-requested" />}</h2>
					<h2>{<Text id="in-the-meantime" />}</h2>
				</div>
			);
		} else if (applicant.appointment.scheduledTime && pathway === Pathways.TestDrive) {
			const scheduledTime = dateParse(applicant.appointment.scheduledTime);
			// TODO: localize date formatting!?
			const date = dateFormat(scheduledTime, 'ddd, MMM D, YYYY [at] h:mm a');
			return (
				<div>
					<h2>{<Text id="we-will-see-you-on" fields={{ date }} />}</h2>
					<h2>{<Text id="in-the-meantime-explore" />}</h2>
				</div>
			);
		} else if (pathway === Pathways.PrivateOffers) {
			const brand = get(vehicle, 'dealer.brand');
			return anyPrivateOffers ? (
				<div>
					<h2 class="privateOfferTitle">
						<BrandSpecificText id="your-private-offer" brand={brand} />
					</h2>
					<h3>{<Text id="weve-found-this-eligible-private-offer" />}</h3>
				</div>
			) : null;
		} else if (this.shouldShowDiscounts()) {
			const amount = utils.formatMoney(discountAndRebatesAmount);
			return <h2>{<Text id="amount-in-discounts-unlocked" fields={{ amount }} />}</h2>;
		} else {
			return <h2>{<Text id="we-are-pulling-in" />}</h2>;
		}
	};

	getProgressText = (progress, firstName, userIsInSession) => {
		if (progress.value < Progress.LOADING_IFRAME_CONTENT.value) {
			if (userIsInSession && firstName) {
				return <Text id="carrying-over-deal-details" fields={{ name: firstName }} />;
			} else {
				return <Text id="calculating-payments" />;
			}
		} else if (progress.value === Progress.LOADING_IFRAME_CONTENT.value) {
			return <Text id="almost-done" />;
		} else {
			return undefined;
		}
	};

	shouldShowCheckmark = () => {
		const { PrivateOffers, RequestMoreInfo, TestDrive } = Pathways;
		const { lockedPricingEnabled, pathway, privateOffers } = this.props;
		const anyPrivateOffers = utils.hasPrivateOffers(privateOffers);

		return (
			[RequestMoreInfo, TestDrive].includes(pathway) ||
			lockedPricingEnabled ||
			(pathway === PrivateOffers && anyPrivateOffers)
		);
	};

	shouldShowDiscounts = () => {
		const { discountAndRebatesAmount, lockedPricingEnabled, pathway } = this.props;
		return lockedPricingEnabled && discountAndRebatesAmount > 0 && pathway === Pathways.UnlockPricing;
	};

	shouldShowIncentivesText = () => {
		const { brand, settings } = this.props.currentDealer;
		return (brand === 'volkswagen' && settings.analytics?.shiftDigital.enabled) || ['acura', 'honda'].includes(brand);
	};

	render = (props) => {
		const {
			applicant,
			discountAndRebatesAmount,
			dealerDiscount,
			loadDealMaker,
			onCloseClick,
			OEMDiscount,
			pathway,
			progress,
			privateOffers,
			userIsInSession,
			vehicle,
		} = props;
		const firstName = get(applicant, 'name.first');
		const progressText = this.getProgressText(progress, firstName, userIsInSession);
		const loadingClass = compact([
			'loadingPlaceholders',
			progress.value === Progress.IFRAME_READY.value ? 'transparent' : null,
		]).join(' ');
		const hasPrivateOffers = utils.hasPrivateOffers(privateOffers);
		const totalOfferAmount = sumBy(privateOffers, 'amount');
		const totalSavingAmount = totalOfferAmount + OEMDiscount + dealerDiscount;
		const content = this.getHeaderContent(hasPrivateOffers);
		const brand = get(vehicle, 'dealer.brand');

		return (
			<div class={`interstitialPanel ${pathway || ''}`}>
				<div class="headerInner">
					<div class="top">
						{progress.value <= Progress.IFRAME_READY.value ? (
							<button type="button" class="closeIcon" onClick={onCloseClick}>
								<CloseIcon />
							</button>
						) : null}
						{pathway === Pathways.PrivateOffers && !hasPrivateOffers && <NoOfferFound applicant={applicant} />}
						{this.shouldShowCheckmark() && <CheckmarkIcon class="checkmark" />}
						{content}
						{pathway === Pathways.PrivateOffers && hasPrivateOffers && (
							<div class="withOffers">
								<div class="offers">
									{privateOffers.map((offer) => (
										<div class="offer" key={offer.programId}>
											<div class="programName">
												<BrandSpecificText id="your-private-cash-offer" brand={brand} />
											</div>
											<div class="amount">{utils.formatMoney(offer.amount)}</div>
										</div>
									))}
									<div class="discount">
										<Text
											class="name"
											id={
												this.shouldShowIncentivesText()
													? 'manufacturer-discounts-and-incentives'
													: 'manufacturer-discounts-and-rebates'
											}
										/>
										<div class="amount">{utils.formatMoney(OEMDiscount)}</div>
									</div>
									<div class="discount">
										<Text class="name" id="dealer-discounts" />
										<div class="amount">{utils.formatMoney(dealerDiscount)}</div>
									</div>
									<div class="totalSaving">
										<Text class="name" id="total-savings" />
										<div class="totalAmount">{utils.formatMoney(totalSavingAmount)}</div>
									</div>
								</div>
								<div class="buttonContainer">
									<button type="button" onClick={loadDealMaker}>
										<Text id="apply-savings-to-vehicle" />
									</button>
								</div>
							</div>
						)}
						{this.shouldShowDiscounts() ? (
							<DiscountTable
								dealerRetailPrice={get(vehicle, 'dealerRetailPrice')}
								discountAndRebatesAmount={discountAndRebatesAmount}
							/>
						) : null}
						{progressText ? <div class="progressText">{progressText}</div> : null}
					</div>
					<div class={`progressBar ${progress.name}`} />
					{progress.value < Progress.IFRAME_READY.value ? (
						<div class={loadingClass} ref={this.loadingRef}>
							<div class="placeholder">
								<div class="cover1_1" />
								<div class="cover1_2" />
								<div class="cover2" />
								<div class="cover3_1" />
								<div class="cover3_2" />
								<div class="cover4" />
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	};
}

export default withStyles(InterstitialPanel, styles);
