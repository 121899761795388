import { h } from 'preact';

import { useEffect, useRef, useState } from 'preact/hooks';
import { loadRemote } from '@module-federation/enhanced/runtime';
import { getCustomStyles } from '~ui/utils';
import consumerSession from '~lib/ui/utils/consumerSession';
import Loading from './loading';
import Result from './result';

const ConsumerMFAClient = (props) => {
	const { close, showPage, applicant, autofiData, performSoftPull } = props;
	const [loading, setLoading] = useState(true);
	const [appInjector, setAppInjector] = useState(null);
	const containerRef = useRef(null);

	useEffect(() => {
		const effect = async () => {
			const appInjector = await loadRemote('mfaApp/appInjector');
			setAppInjector(appInjector);
			setLoading(false);
		};

		effect();
	});

	useEffect(() => {
		if (appInjector) {
			const dealer = autofiData.dealer || {};
			const customCss = dealer.websiteSettings.ui.customCss || '';

			appInjector?.injectApp(containerRef, {
				styles: customCss ? getCustomStyles(customCss) : null,
				onComplete: async () => {
					await performSoftPull();
					showPage(Result);
				},
				onClose: close,
				email: applicant.email,
				phone: applicant.phone,
				name: applicant.name.full,
				authTokenKey: consumerSession.getSessionName(autofiData.sessionData),
				apiProxyUrl: autofiData.apiProxyUrl,
			});
		}

		return () => appInjector?.cleanup(containerRef);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appInjector]);

	return (
		<div class="autofi-modal creditAppPage consumerMFAClient">
			{loading && <Loading />}
			<div ref={containerRef}></div>
		</div>
	);
};

export default ConsumerMFAClient;
