import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import sumBy from 'lodash/sumBy';
import { OfferType } from '~lib/enum';
import * as utils from '~ui/utils';
import HTTPClient from '~ui/utils/HTTPClient';
import { satisfiesConstraints } from '~lib/constraintEngine';

// cache is being exported only for testing. There is no other need for
// code to modify or look at this variable.
export const cache = {};

const NOT_FOUND = 'Estimate Not Found';
let prevApplicant;

const cacheEstimate = (estimatorOptions, estimate) => {
	const cacheKey = getCacheKey(estimatorOptions);
	cache[cacheKey] = estimate;
};

const getCachedEstimate = (estimatorOptions) => {
	const cacheKey = getCacheKey(estimatorOptions);
	return cache[cacheKey];
};

const getCacheKey = (estimatorOptions) => {
	const { payload } = estimatorOptions;
	const { creditBand, incentiveOption, info, offerType } = payload;
	const { offer, vehicle, zip } = info;
	const { dealerRetailPrice, vin } = vehicle;
	const { annualMiles, downPayment, termMonths, totalDiscounts } = offer;
	return [
		vin,
		offerType,
		incentiveOption,
		annualMiles,
		creditBand,
		downPayment,
		termMonths,
		dealerRetailPrice,
		totalDiscounts,
		zip,
	].join(':');
};

/**
 * Get the downPayment based on customer preferences, falling back to dealer defaults
 * @param {number} preferredDownPayment: downPayment from customer preferences
 * @param {object} offerTypeDefaults: the dealer defaults based on offerType
 * @param {number} salePrice: vehicle.dealerRetailPrice - totalDiscounts
 * @return {number} the down payment to be sent to estimator
 */
const getDownPayment = (preferredDownPayment, offerTypeDefaults, salePrice) => {
	const { downPaymentPercentage, downPayment } = offerTypeDefaults || {};

	if (!isNil(preferredDownPayment)) {
		return preferredDownPayment;
	}

	if (!isNil(downPaymentPercentage)) {
		return utils.nearest100(salePrice * (downPaymentPercentage / 100));
	}

	return utils.nearest100(downPayment);
};

/**
 * This code was extracted from loanapp
 */
const discountHasConstraint = (discount) => {
	const { constraints = [{}] } = discount;
	return !isEmpty(constraints[0].constraint);
};

/**
 * This code was extracted from loanapp and modified to take in a vehicle object
 * instead of a deal application to calculate applicable discounts
 * @param {object} vehicleData The vehicle to calculate applicable discounts
 * @param {object} dealer The dealer containing a settings.discounts field
 * @return {Array} Array containing all applicable discounts
 */
const getCustomDiscounts = (vehicleData, dealer) => {
	const dbStoredDiscounts = get(dealer, 'settings.discounts', []);

	const rawCustomDiscounts = dbStoredDiscounts.filter((discount) => {
		const { constraints = [{}], custom, include } = discount;
		const [{ constraint }] = constraints;
		const includeCustomDiscount = custom && include;
		if (discountHasConstraint(discount)) {
			const errors = satisfiesConstraints(vehicleData, constraint, ['requestedOfferType'], false);
			const hasErrors = errors && errors.length;
			return !hasErrors && includeCustomDiscount;
		}

		return includeCustomDiscount;
	});

	const vehiclePrice = get(vehicleData, 'salePrice') || get(vehicleData, 'msrp');

	const customDiscounts = rawCustomDiscounts.map((discount) => {
		if (discountHasConstraint(discount) && discount.discountType === 'PCT') {
			return { ...discount, amount: Math.floor((discount.amount * vehiclePrice) / 100) };
		} else {
			return discount;
		}
	});

	return [...vehicleData.discounts, ...customDiscounts];
};

/**
 * Create the options object to pass to estimator
 * @param {string} offerTypeRaw
 * @param {object} preferences: customer preferences coming from session
 * @param {object} vehicle
 * @param {object} autofiData
 * @return {object} estimator options object
 */
export const getEstimatorOptions = (offerTypeRaw, preferences, vehicle, autofiData, name, address) => {
	const referrer = window.location.href;
	const { backendPath, dealer: currentDealer, estimatorUrl } = autofiData;
	const offerType = offerTypeRaw.toLowerCase();
	const dealer = vehicle.dealer || currentDealer;
	const { incentiveOption, ui } = dealer.websiteSettings;
	const { defaults, features } = ui;
	const isLockedPricing = utils.lockedPricingIsEnabled(currentDealer, vehicle);
	/**
	 * This setting is deprecated, use the new setting in loanapp db.
	 * dealer.settings.inventoryFeed.discountIncludesOemRebates or
	 * dealer.settings.inventoryFeed.discountIncludesDealerCash
	 */
	const splitLumpedDiscounts = false;

	const applicableDiscounts = getCustomDiscounts(vehicle, currentDealer);
	const totalDiscounts = isLockedPricing ? 0 : sumBy(applicableDiscounts, 'amount');

	// TODO: normalize case of creditBand. It looks like "EXCELLENT" in
	// preferences but "Excellent" in dealer settings.
	const creditBand = get(preferences, 'creditBand', defaults[offerType]?.credit);
	const termMonths = get(preferences, [offerType, 'termMonths'], defaults[offerType]?.term);
	const annualMiles = get(preferences, 'lease.annualMileage', defaults.lease.annualMiles);
	const paymentInterval = get(
		preferences,
		'requestedPaymentInterval',
		dealer.settings.consumerFlow.paymentIntervals.defaultInterval
	);
	const downPayment = getDownPayment(
		get(preferences, [offerType, 'downPayment']),
		defaults[offerType],
		vehicle.dealerRetailPrice - totalDiscounts
	);

	let pricePlan;
	let usePricePlanDiscount;

	const { defaultPricePlan, directOffers } = dealer.settings.consumerFlow;
	if (directOffers.pricePlan) {
		pricePlan = get(preferences, 'pricePlan', defaultPricePlan);
		usePricePlanDiscount = Boolean(pricePlan);
	}

	const estimatorOptions = {
		endpoint: { url: estimatorUrl },
		payload: {
			incentiveOption,
			offerType: offerType.toUpperCase(),
			backendPath,
			creditBand,
			info: {
				dealer: dealer._id,
				name: name,
				address: address,

				// Remove ui property from vehicle because it may contain DOM nodes, which
				// JSON.stringify can't handle
				vehicle: omit(vehicle, 'ui'),

				offer: {
					termMonths,
					downPayment,
					annualMiles,
					totalDiscounts,
				},
				getRebates: true,
				splitLumpedDiscounts,
				usePricePlanDiscount,
				paymentInterval,
				pricePlan,
			},
			referrer,
		},
	};

	if (features.useDefaultTaxes && dealer.address.contracts.zip && !isLockedPricing) {
		estimatorOptions.payload.info.address = {
			zip: dealer.address.contracts.zip,
			state: dealer.address.contracts.state,
		};
	}

	return estimatorOptions;
};

/**
 * Parse the raw response from estimator into an estimate object
 * @param {string} rawResponse
 * @return {object} estimate
 */
export const parseEstimate = (rawResponse) => {
	const response = JSON.parse(rawResponse);
	if (!response.data) {
		throw new Error(NOT_FOUND);
	}
	const { constructedLoanApp, data, offer, offerType } = response;
	const { rebatesOffered, vehicle } = constructedLoanApp;
	const privateOffers = rebatesOffered.filter((item) => item.metadata && item.metadata.isPrivateOffer);
	const OEMOffers = rebatesOffered.filter((item) => item.isStackableOffer && !item.isQualifiedOffer);
	const OEMDiscount = sumBy(OEMOffers, 'amount') - sumBy(privateOffers, 'amount');
	const dealerDiscount = get(vehicle, 'rawDiscount', 0);
	const offerDownPayment = offerType === 'FINANCE' ? offer.downPayment : offer.totalCash;
	return {
		annualMiles: offer.annualMiles,
		apr: offer.apr,
		biweeklyPayment: data.biweeklyPayment,
		downPayment: offerDownPayment,
		monthlyPayment: data.monthlyPayment,
		offerType: offerType.toLowerCase(),
		dealerDiscount: dealerDiscount,
		OEMDiscount: OEMDiscount,
		privateOffers: privateOffers,
		term: offer.termMonths,
		totalRebates: offer.rebates.total,
	};
};

// TODO: make this async
/**
 * Get an estimate for the given parameters / options
 * @param {string} offerType
 * @param {object} preferences: the customer preferences from the session
 * @param {object} vehicle
 * @param {object} autofiData
 * @param {object} applicant
 * @param {function} callback standard node callback taking (error, estimate)
 */
// eslint-disable-next-line default-param-last
export const getEstimate = (offerType, preferences, vehicle, autofiData, applicant = {}, callback) => {
	if (offerType === OfferType.LEASING && vehicle.age !== 'new') {
		return callback('lease not available on used vehicles');
	}
	const missingVehicleProperties = [
		...['age', 'make', 'msrp', 'vin', 'year'].filter((prop) => !vehicle[prop]),
		...(vehicle.dealerRetailPrice || vehicle.price ? [] : ['dealerRetailPrice or price']),
	];

	if (missingVehicleProperties.length) {
		return callback(`missing vehicle information: ${missingVehicleProperties.join(', ')}`);
	}

	const { name, address } = applicant;
	const estimatorOptions = getEstimatorOptions(offerType, preferences, vehicle, autofiData, name, address);
	const estimate = getCachedEstimate(estimatorOptions);
	if (estimate && isEqual(prevApplicant, applicant)) {
		if (estimate === NOT_FOUND) {
			return callback(NOT_FOUND);
		} else {
			return callback(null, estimate);
		}
	} else {
		HTTPClient.post(estimatorOptions, (error, response) => {
			prevApplicant = applicant;
			if (error) {
				// TODO: JSON.parse error
				return callback(error);
			}
			try {
				const estimate = parseEstimate(response);
				cacheEstimate(estimatorOptions, estimate);
				callback(null, estimate);
			} catch (e) {
				cacheEstimate(estimatorOptions, NOT_FOUND);
				callback(e.message);
			}
		});
	}
};
