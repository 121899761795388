import compact from 'lodash/compact';
import get from 'lodash/get';
import isElement from 'lodash/isElement';
import { h, Component } from 'preact';
import { IntlProvider } from 'preact-i18n';

import { AmplitudeLabelComponents, Pathways } from '~lib/enum';
import styles from './modalIframe.styl';
import InterstitialPanel from '~lib/ui/components/InterstitialPanel';
import Progress from '~ui/components/modalIframeProgress';
import isolate from '~ui/components/isolate';
import modal from '~ui/components/modal';
import * as utils from '~ui/utils';
import withStyles from '~ui/components/withStyles';
import { trackJdPowerEvent, trackShiftDigitalEvent } from '~ui/utils/analytics';
import { trackGoogleAnalytics4Event } from '~ui/utils/googleAnalytics';

class ModalIframe extends Component {
	state = {
		progress: Progress.START,
		scrolledOffscreen: false,
		scrollStartTime: undefined,
		shouldShowDealMaker: !utils.hasPrivateOffers(this.props.privateOffers),
	};

	componentDidMount = () => {
		window.addEventListener('message', this.handleMessage);
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { $modalContainer, hidden, onModalIframeLoad, privateOffers, src } = this.props;
		const { progress } = this.state;
		const componentBecameVisible = !hidden && prevProps.hidden;
		const srcChanged = src !== prevProps.src;
		const iframeWasReady = prevState.progress.value === Progress.IFRAME_READY.value;
		const iframeIsReady = progress.value === Progress.IFRAME_READY.value;
		const iframeBecameReady = !iframeWasReady && iframeIsReady;

		if ((componentBecameVisible || srcChanged) && !src) {
			this.setState({ progress: Progress.WAITING_FOR_IFRAME_URL });
		} else if (srcChanged && src) {
			this.setState({
				progress: Progress.LOADING_IFRAME,
				scrolledOffscreen: false,
				shouldShowDealMaker: !utils.hasPrivateOffers(privateOffers),
			});
		}

		if (progress !== prevState.progress && progress.value < Progress.IFRAME_READY.value) {
			this.setState({ scrollStartTime: undefined });
		}

		if (iframeBecameReady) {
			if (onModalIframeLoad) {
				onModalIframeLoad();
			}
			if (!utils.hasPrivateOffers(privateOffers)) {
				setTimeout(this.autoScroll, 3000);
			}
		}

		if (privateOffers !== prevProps.privateOffers) {
			this.setState({
				scrolledOffscreen: false,
				shouldShowDealMaker: !utils.hasPrivateOffers(privateOffers),
			});
		}

		if ($modalContainer && $modalContainer !== prevProps.$modalContainer) {
			$modalContainer.addEventListener('scroll', this.handleScroll);
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('message', this.handleMessage);
		this.props.$modalContainer.removeEventListener('scroll', this.handleScroll);
	};

	// smoothly scroll header off screen
	autoScroll = (timestamp) => {
		const { $modalContainer } = this.props;
		if ($modalContainer && !this.state.scrolledOffscreen && this.interstitialPanelExists()) {
			if (this.state.scrollStartTime === undefined) {
				this.setState({ scrollStartTime: timestamp });
			}
			const elapsedMs = timestamp - this.state.scrollStartTime;
			const { height } = this.$interstitialPanel.base.getBoundingClientRect();
			const scrollDuration = 1000;
			// The following expression eases the scrolling to make it smoother, by
			// gradually accelerating, then decelerating, similar to the idea shown at
			// https://easings.net/#easeInOutSine. '+ 1' at end is to make sure header
			// is fully scrolled off screen so scrollDone is called, even if there are
			// rounding errors somewhere.
			const scrollPos = (height / 2) * (1 - Math.cos((Math.PI * elapsedMs) / scrollDuration)) + 1;
			// Math.max avoids autoscrolling up if user scrolled down manually
			$modalContainer.scrollTop = Math.max($modalContainer.scrollTop, scrollPos);
			this.handleScroll();
			window.requestAnimationFrame(this.autoScroll);
		}
	};

	handleMessage = (event) => {
		const { applicant, currentDealer, pageType, scrollToTop, sessionData, vehicle } = this.props;

		// TODO: come up with a more consistent way to represent these events, less
		// confusing than using a string for some and an object for others.
		if (event.data === 'autofi-scroll-to-top') {
			scrollToTop();
		} else if (event.data.type === 'autofi-clip') {
			// workaround for a bug in Chrome preventing text in iframes from being copied
			navigator.clipboard.writeText(event.data.text);
		} else if (event.data.type === 'autofi-shift-digital-event') {
			const { eventType, options } = event.data;
			trackShiftDigitalEvent(eventType, vehicle, currentDealer, sessionData, applicant, pageType, options);
		} else if (event.data.type === 'autofi-jd-power-event') {
			const { eventType } = event.data;
			trackJdPowerEvent(eventType, vehicle, currentDealer);
		} else if (event.data === 'autofi-ready') {
			this.setState({ progress: Progress.IFRAME_READY });
		}
	};

	handleLoad = () => {
		if (this.props.src && this.state.progress.value < Progress.IFRAME_READY.value) {
			// remove the loading content & corresponding iframe margin
			this.setState({ progress: Progress.LOADING_IFRAME_CONTENT });
		}
	};

	// watch for the top of the iframe to go off the top of the screen
	handleScroll = () => {
		const { progress, scrolledOffscreen } = this.state;
		if (!scrolledOffscreen && this.interstitialPanelExists()) {
			const transitionFinished = progress.value === Progress.IFRAME_READY.value;
			const { height } = this.$interstitialPanel.base.getBoundingClientRect();
			const { $modalContainer } = this.props;
			if (transitionFinished && $modalContainer.scrollTop >= height) {
				this.fixIosTransitionScrolling();
				this.setState({ scrolledOffscreen: true });
			}
		}
	};

	interstitialPanelRef = (component) => {
		this.$interstitialPanel = component;
	};

	iframeRef = ($iframe) => {
		this.$iframe = $iframe;
	};

	fixIosTransitionScrolling = () => {
		if (utils.isIos()) {
			const { $modalContainer } = this.props;
			// Setting overflow to 'hidden', then removing 'hidden' setting is a
			// workaround allowing scrollTop to be set in iOS Safari even while the
			// user is scrolling.
			$modalContainer.style.overflow = 'hidden';
			$modalContainer.scrollTop = 0;
			$modalContainer.style.overflow = '';

			// Setting iframe height to 0, then back to '' (making it revert to the
			// style sheet value) fixes iOS bugs that make the iframe scroll back to
			// the top on its own and sometimes prevent the user from scrolling.
			this.$iframe.style.height = 0;
			setTimeout(() => (this.$iframe.style.height = ''), 0);
		}
	};

	interstitialPanelExists = () => {
		return isElement(get(this.$interstitialPanel, 'base'));
	};

	loadDealMaker = (event) => {
		const { applicant, currentVehicle, loanAppId, pathway, trackAmplitudeEvent } = this.props;
		const pathwaysToTrack = [Pathways.PrivateOffers];

		if (pathwaysToTrack.includes(pathway)) {
			trackAmplitudeEvent({
				action: AmplitudeLabelComponents.Action.PrivateOfferApplySavingsClick,
				event,
				opts: { isTestUser: utils.isTestUser(applicant), loanAppId },
				pathway: Pathways.PrivateOffers,
				vehicle: currentVehicle,
			});
		}
		this.setState({ shouldShowDealMaker: true }, () => setTimeout(this.autoScroll, 3000));
	};

	handleClose = () => {
		const { currentDealer, onCloseClick, sessionData, vehicle } = this.props;
		const googleEventMetaData = { dealer: currentDealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'compare', flow_outcome: 'close', vehicle },
			googleEventMetaData
		);

		onCloseClick();
	};

	render = (props, state) => {
		const {
			applicant,
			currentDealer,
			dealerDiscount,
			dictionary,
			discountAndRebatesAmount,
			hidden,
			launchDarklyFeatureFlags,
			lockedPricingEnabled,
			OEMDiscount,
			pathway,
			privateOffers,
			src,
			userIsInSession,
			vehicle,
		} = props;

		const { progress, scrolledOffscreen, shouldShowDealMaker } = state;

		const is_touch_device = 'ontouchstart' in window || navigator.maxTouchPoints;

		const shouldShowInterstitialPanel =
			pathway &&
			![Pathways.StandAloneCreditApp, Pathways.TradeIn, Pathways.DriveTogether].includes(pathway) &&
			!scrolledOffscreen;

		const modalStyles = is_touch_device && !shouldShowInterstitialPanel ? 'overflow: scroll;' : '';

		const modalContainerClasses = compact([
			pathway === Pathways.DriveTogether ? 'driveTogetherModalContainer' : '',
		]).join(' ');

		const iframeLoading =
			progress.value >= Progress.WAITING_FOR_IFRAME_URL.value && progress.value < Progress.IFRAME_READY.value;

		const iframeClasses = compact([
			'modalIframe',
			iframeLoading || (utils.hasPrivateOffers(privateOffers) && !shouldShowDealMaker) ? 'hidden' : null,
			progress.value === Progress.LOADING_IFRAME_CONTENT.value ? 'fading' : null,
			shouldShowInterstitialPanel && !scrolledOffscreen ? 'lockScroll' : null,
			pathway === Pathways.DriveTogether ? 'driveTogetherModal' : '',
			launchDarklyFeatureFlags.appSubmissionImprovements ? 'appSubmissionImprovements' : null,
		]).join(' ');

		const shouldHideIframe = hidden && src?.toString().includes('drive-together');

		return (
			<div class={modalContainerClasses} style={modalStyles}>
				{shouldShowInterstitialPanel ? (
					<IntlProvider definition={dictionary}>
						<InterstitialPanel
							applicant={applicant}
							currentDealer={currentDealer}
							dealerDiscount={dealerDiscount}
							discountAndRebatesAmount={discountAndRebatesAmount}
							loadDealMaker={this.loadDealMaker}
							lockedPricingEnabled={lockedPricingEnabled}
							OEMDiscount={OEMDiscount}
							onCloseClick={this.handleClose}
							pathway={pathway}
							privateOffers={privateOffers}
							progress={progress}
							ref={this.interstitialPanelRef}
							userIsInSession={userIsInSession}
							vehicle={vehicle}
						/>
					</IntlProvider>
				) : null}
				{!shouldHideIframe && (
					<iframe
						className={iframeClasses}
						onError={this.handleLoad}
						onLoad={this.handleLoad}
						ref={this.iframeRef}
						src={src}
					/>
				)}
			</div>
		);
	};
}

export default modal(isolate(withStyles(ModalIframe, styles)), {
	containerClass: utils.isIos() ? '' : 'preventScroll',
});
