const localize = require('./localize');

/**
 * Get the i18n discount title/description for a given dealer name
 *
 * @param {String} dealer name to build discount name for
 * @returns {Object} discount titleI18n/descriptionI18n object
 *    The Object's keys are each locale, and the values are the localized text
 */
module.exports = (dealerName) => ({
	en: localize('dealer-discount', { dealerName }),
	'en-CA': localize('dealer-discount', { dealerName }, 'en-CA'),
	'fr-CA': localize('dealer-discount', { dealerName }, 'fr-CA'),
});
