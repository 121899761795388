import { h, Component } from 'preact';
import { Text } from 'preact-i18n';

import NoOffersIcon from '~ui/assets/no-private-offers.svg';
import styles from './noOfferFound.styl';
import withStyles from '~ui/components/withStyles';
import * as utils from '~ui/utils';

const NoOfferFound = ({ applicant }) => (
	<div class="noOffers">
		<div class="iconContainer">
			<NoOffersIcon class="noOfferIcon" />
		</div>
		<p>
			<Text id="cant-find-any-private-offers" />
		</p>
		<div class="applicant ctaLaunch">
			<div>{applicant.name.full}</div>
			<div>{applicant.address.street}</div>
			<div class="row">
				<div>{utils.formatAddress(applicant.address)}</div>
			</div>
		</div>
		<p>
			<Text id="double-check-your-information-is-correct" />
		</p>
	</div>
);

export default withStyles(NoOfferFound, styles);
