import { h, Component, render } from 'preact';
import * as utils from '~ui/utils';

const isolate = (ComponentToIsolate) => {
	// This higher-order component isolates our CSS from third-party CSS. The
	// isolation does not work on portals passed to this component.

	return class IsolatedComponent extends Component {
		static displayName = `Isolated${utils.getComponentName(ComponentToIsolate)}`;

		containerRef = ($container) => {
			this.$container = $container;
		};

		updateShadowContents = () => {
			const { shadowRoot, dataset } = this.$container;

			render(
				<utils.CustomCssContext.Provider value={dataset.customCss}>
					<ComponentToIsolate {...this.props} />
				</utils.CustomCssContext.Provider>,
				shadowRoot
			);
		};

		componentDidMount = () => {
			if (!this.$container.shadowRoot) {
				this.$container.attachShadow({ mode: 'open' });
			}
			this.updateShadowContents();
		};

		componentDidUpdate = () => {
			this.updateShadowContents();
		};

		render = (props) => (
			<utils.CustomCssContext.Consumer>
				{(customCss) => (
					<div
						class="autofi-css-isolator"
						style={props.style || {}}
						ref={this.containerRef}
						data-custom-css={customCss}
					/>
				)}
			</utils.CustomCssContext.Consumer>
		);
	};
};

export default isolate;
