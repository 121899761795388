import get from 'lodash/get';
import result from 'lodash/result';
import { h, Fragment } from 'preact';
import { createPortal } from 'preact/compat';

import { View } from '~lib/enum';
import CreditApplicationBanner from '~ui/components/creditApp/banner';
import CreditAppModalSequence from '~ui/components/creditApp/sequence';
import DdcLocationCta from '~ui/components/ddcLocationCta';
import DepositModal from '~ui/components/depositModal';
import ExplorePaymentsForm from '~ui/components/explorePaymentsForm';
import FullPageLoadingAnimation from '~ui/components/fullPageLoadingAnimation';
import ModalIframe from '~ui/components/modalIframe';
import PrivateOffersSequence from '~ui/components/privateOffers/sequence';
import RequestMoreInfoForm from '~ui/components/requestMoreInfoForm';
import ScheduleTestDriveForm from '~ui/components/scheduleTestDriveForm';
import StandAloneCreditAppButton from '~ui/components/standAloneCreditAppButton';
import ThankYouModal from '~ui/components/thankYouModal';
import * as utils from '~ui/utils';
import { shouldAddJDPowerScripts } from '~ui/utils/analytics';
import JDPowerScripts from '../components/jdPowerScripts';

const SharedDumbUI = ({
	applicant,
	autofiData,
	autofiVehiclelessCtaMap,
	closeModal,
	currentPathway,
	currentSubTitle,
	currentTitle,
	currentVehicle,
	currentView,
	dealerDiscount,
	dictionary,
	discountAndRebatesAmount,
	handleCreditAppClick,
	handleCreditAppSubmit,
	handleDepositPaymentSubmit,
	handleExplorePaymentsSubmit,
	handlePrivateOffersSubmit,
	handleRequestMoreInfoSubmit,
	handleScheduleTestDriveSubmit,
	handleStandAloneCreditAppClick,
	iframeSrc,
	loanAppId,
	OEMDiscount,
	onModalIframeLoad,
	pageType,
	privateOffers,
	privateOffersStartPageIndex,
	shouldShowLoadingAnimation,
	trackAmplitudeEvent,
	userIsInSession,
}) => {
	const { baseUrl, dealer: currentDealer, launchDarklyFeatureFlags, sessionData } = autofiData;

	// TODO: make render not have side effects
	const $creditAppCtaContainer = result(autofiVehiclelessCtaMap, 'creditApp');
	const $standAloneCreditAppCtaContainer = result(autofiVehiclelessCtaMap, 'standAloneCreditApp');

	// TODO: make components below figure out lockedPricingEnabled on their
	// own, if locked pricing logic is not removed soon. See
	// https://autofi.atlassian.net/browse/DIGRTL-179811813
	const lockedPricingEnabled = utils.lockedPricingIsEnabled(currentDealer, currentVehicle);

	const sacColorsJson = get($standAloneCreditAppCtaContainer, 'dataset.autofiColors');

	const sacCommonProps = {
		borderRadius: get($standAloneCreditAppCtaContainer, 'dataset.autofiBorderRadius'),
		buttonTextOverride: get($standAloneCreditAppCtaContainer, 'dataset.autofiButtonTextOverride'),
		colors: sacColorsJson ? JSON.parse(sacColorsJson) : undefined,
		ctaType: 'standAloneCreditApp',
		dictionary,
		onClick: (clickEvent) => handleStandAloneCreditAppClick(undefined, { clickEvent }),
	};

	return (
		<Fragment>
			<ModalIframe
				applicant={applicant}
				currentDealer={currentDealer}
				dealerDiscount={dealerDiscount}
				dictionary={dictionary}
				discountAndRebatesAmount={discountAndRebatesAmount}
				hidden={![View.LOANAPP, View.PREQUALIFY, View.DRIVE_TOGETHER].includes(currentView)}
				launchDarklyFeatureFlags={launchDarklyFeatureFlags}
				loanAppId={loanAppId}
				lockedPricingEnabled={lockedPricingEnabled}
				OEMDiscount={OEMDiscount}
				onCloseClick={closeModal}
				onModalIframeLoad={onModalIframeLoad}
				pageType={pageType}
				pathway={currentPathway}
				privateOffers={privateOffers}
				sessionData={sessionData}
				src={iframeSrc}
				trackAmplitudeEvent={trackAmplitudeEvent}
				transparent={!!shouldShowLoadingAnimation}
				userIsInSession={userIsInSession}
				vehicle={currentVehicle}
			/>
			<ExplorePaymentsForm
				applicant={applicant}
				autofiData={autofiData}
				close={closeModal}
				dictionary={dictionary}
				hidden={currentView !== View.EXPLORE_PAYMENTS}
				lockedPricingEnabled={lockedPricingEnabled}
				pageType={pageType}
				submit={handleExplorePaymentsSubmit}
				subTitle={currentSubTitle}
				title={currentTitle}
				trackAmplitudeEvent={trackAmplitudeEvent}
				vehicle={currentVehicle}
			/>
			<RequestMoreInfoForm
				applicant={applicant}
				autofiData={autofiData}
				close={closeModal}
				dictionary={dictionary}
				hidden={currentView !== View.REQUEST_MORE_INFO}
				pageType={pageType}
				submit={handleRequestMoreInfoSubmit}
				subTitle={currentSubTitle}
				title={currentTitle}
				trackAmplitudeEvent={trackAmplitudeEvent}
				vehicle={currentVehicle}
			/>
			<ScheduleTestDriveForm
				applicant={applicant}
				autofiData={autofiData}
				close={closeModal}
				dictionary={dictionary}
				hidden={currentView !== View.TEST_DRIVE}
				pageType={pageType}
				submit={handleScheduleTestDriveSubmit}
				subTitle={currentSubTitle}
				title={currentTitle}
				trackAmplitudeEvent={trackAmplitudeEvent}
				vehicle={currentVehicle}
			/>

			{currentVehicle && (
				<ThankYouModal
					close={closeModal}
					dictionary={dictionary}
					hidden={currentView !== View.THANK_YOU}
					pathway={currentPathway}
					trackAmplitudeEvent={trackAmplitudeEvent}
					vehicle={currentVehicle}
				/>
			)}
			{currentVehicle && (
				<DepositModal
					applicant={applicant}
					autofiData={autofiData}
					close={closeModal}
					dictionary={dictionary}
					hidden={currentView !== View.DEPOSIT}
					pageType={pageType}
					pathway={currentPathway}
					submit={handleDepositPaymentSubmit}
					title={currentTitle}
					trackAmplitudeEvent={trackAmplitudeEvent}
					vehicle={currentVehicle}
				/>
			)}
			<CreditAppModalSequence
				applicant={applicant}
				autofiData={autofiData}
				close={closeModal}
				dictionary={dictionary}
				hidden={currentView !== View.CREDIT_APP}
				loanAppId={loanAppId}
				pageType={pageType}
				propagateApplicant={handleCreditAppSubmit}
				subTitle={currentSubTitle}
				title={currentTitle}
				trackAmplitudeEvent={trackAmplitudeEvent}
			/>
			<PrivateOffersSequence
				applicant={applicant}
				autofiData={autofiData}
				close={closeModal}
				dictionary={dictionary}
				hidden={currentView !== View.PRIVATE_OFFERS}
				loanAppId={loanAppId}
				pageType={pageType}
				propagateApplicant={handlePrivateOffersSubmit}
				startPageIndex={privateOffersStartPageIndex}
				subTitle={currentSubTitle}
				title={currentTitle}
				trackAmplitudeEvent={trackAmplitudeEvent}
				vehicle={currentVehicle}
			/>
			{$creditAppCtaContainer &&
				createPortal(
					<CreditApplicationBanner
						baseUrl={baseUrl}
						dealer={currentDealer}
						dictionary={dictionary}
						launchDarklyFeatureFlags={launchDarklyFeatureFlags}
						onClick={(clickEvent) => handleCreditAppClick(undefined, { clickEvent })}
					/>,
					$creditAppCtaContainer
				)}
			{$standAloneCreditAppCtaContainer &&
				createPortal(
					$standAloneCreditAppCtaContainer.classList.contains('ddc-style') ? (
						<DdcLocationCta {...sacCommonProps} ctaType={'standAloneCreditApp'} />
					) : (
						<StandAloneCreditAppButton {...sacCommonProps} compact={pageType === 'listings'} />
					),
					$standAloneCreditAppCtaContainer
				)}
			{shouldShowLoadingAnimation && <FullPageLoadingAnimation />}
			{shouldAddJDPowerScripts(currentDealer) && <JDPowerScripts dealer={currentDealer} />}
		</Fragment>
	);
};

export default SharedDumbUI;
