import { h, Fragment } from 'preact';

export const GoogleTagDataLayerScript = ({ dataLayerName = 'dataLayer' }) => (
	<script className="autofi-data-layer-script">{`window.${dataLayerName} = window.${dataLayerName} || [];`}</script>
);

export const GoogleTagScript = ({ id, dataLayerName = 'dataLayer' }) => (
	<script className="autofi-google-tag-script">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','${dataLayerName}','${id}')`}</script>
);

export const GoogleTagNoScript = ({ id }) => (
	<noscript className="autofi-google-tag-no-script">
		<iframe
			src={`https://www.googletagmanager.com/ns.html?id=${id}`}
			height="0"
			width="0"
			style={{ display: 'none', visibility: 'hidden' }}
		></iframe>
	</noscript>
);

export const GTM = ({
	gtmId,
	shouldAddDataLayerScript = false,
	shouldAddNoScript = false,
	shouldAddScript = false,
}) => (
	<Fragment>
		{shouldAddDataLayerScript && <GoogleTagDataLayerScript />}
		{shouldAddNoScript && gtmId && <GoogleTagNoScript id={gtmId} />}
		{shouldAddScript && gtmId && <GoogleTagScript id={gtmId} />}
	</Fragment>
);
