/**
 * This is a simplified version of the constraint engine extracted from the @autofidev/utils library
 * The full library includes several server-side dependencies, rendering its use not viable in panda client
 * It is currently used to calculate applicable discounts per vehicle
 */

/**
 * @typedef {object} CVObject
 * @property {string} name
 * @property {Constraints} constraints
 * @property {*} value
 */

/**
 * @typedef {object} Constraints
 * @property {Conditions} * arbitrary paths to match against conditions
 * @property {number|string|object} * arbitrary paths that are not Conditions objects are equivalent
 * to `{ %eq: (number|string|object) }`
 */

/**
 * @typedef {object} Conditions
 * @property {number|string|object} %eq
 * @property {number|string|object} %ne
 * @property {number} %gt
 * @property {number} %lt
 * @property {number} %gte
 * @property {number} %lte
 * @property {string} %regex
 * @property {Conditions[]} %every
 * @property {Conditions[]} %some
 * @property {Conditions[]} %elemMatch
 * @property {(string|number|object)[]} %in
 * @property {(string|number|object)[]} %nin
 *
 * @property {number} min
 * @property {number} max
 * @property {number|string|object} eq
 * @property {string} regex
 */

const get = require('lodash/get');
const isEqual = require('lodash/isEqual');

const LOGICAL_CONDITIONS = {
	'%and': and,
	'%or': or,
};

const OPERATORS = {
	// legacy operators
	min: (value, min, path) => gte(value, min, path, { errMsg: `${path}: ${value} is less than min: ${min}` }),
	max: (value, max, path) => lte(value, max, path, { errMsg: `${path}: ${value} is greater than max: ${max}` }),
	eq: (value, cond, path) => eq(value, cond, path, { errMsg: `${path}: ${value} does not equal ${cond}` }),
	regex: regex,

	// comparison operators
	'%gte': gte,
	'%min': (value, min, path) => gte(value, min, path, { errMsg: `${path}: ${value} is less than %min: ${min}` }),
	'%lte': lte,
	'%max': (value, min, path) => lte(value, min, path, { errMsg: `${path}: ${value} is greater than %max: ${min}` }),
	'%gt': gt,
	'%lt': lt,
	'%eq': eq,
	'%ne': ne,
	'%regex': regex,
	'%in': _in,
	'%nin': nin,

	// array ops
	'%every': every,
	'%some': some,
	'%elemMatch': (values, cond, path, opts) => some(values, cond, path, { op: '%elemMatch', ...opts }),
	'%exists': (value, cond, path) => exists(value, cond, { errMsg: `${path}: ${cond ? 'does not exist' : 'exists'}` }),
};

/**
 * Checks if a piece of data satisfies the passed in constraints
 *
 * @param {object} dataObj
 * @param {Constraints} constraints
 * @param {string[]} [skipConstraints=[]] array of constraint paths to ignore.
 * @param {boolean} [allowUndefined=false] if true values that are undefined will evaluate as
 * satisfying all constraints.
 * @param {string[]|boolean} [allowUndefinedConstraints=[]] if true or valid paths are passed,
 * bypass the constraint check for all constraints or the specified paths
 * @returns {string[]} array of error messages for constraints that did not match
 */
function satisfiesConstraints(
	dataObj,
	constraints,
	skipConstraints = [],
	allowUndefined = false,
	allowUndefinedConstraints = []
) {
	const errMsgs = [];

	Object.keys(constraints)
		// filter out constraints that should be skipped
		.filter((path) => !skipConstraints.includes(path))
		.forEach((path) => {
			const constraint = constraints[path];
			const validPath = path.replace(/:/gi, '.');

			if (LOGICAL_CONDITIONS[validPath]) {
				const complexErrors = LOGICAL_CONDITIONS[validPath](dataObj, constraint, skipConstraints, allowUndefined);

				if (complexErrors.length) {
					errMsgs.push(...complexErrors);
				}

				return;
			}

			const allow = allowUndefinedConstraints === true || allowUndefinedConstraints.includes(path);
			const value = get(dataObj, validPath);
			if (!allow && (value === undefined || value === null)) {
				if (allowUndefined && value === undefined) {
					return;
				}
				errMsgs.push(`${validPath}: is ${value} and must have a value`);
				return;
			}

			errMsgs.push(...testCondition(value, constraint, validPath, skipConstraints, allowUndefined));
		});

	return errMsgs.filter(Boolean);
}

/**
 * Test all operations in constraint object
 * @param {*} value value of the path
 * @param {Conditions} conditions set of conditions to match against the path
 * @param {string} path
 * @param {*} skipConstraints
 * @param {*} allowUndefined
 */
function testCondition(value, conditions, path, skipConstraints, allowUndefined) {
	let opFound = false;
	let errors = [];
	if (typeof conditions === 'object') {
		Object.keys(conditions).forEach((op) => {
			if (OPERATORS[op]) {
				opFound = true;
				errors = errors.concat(OPERATORS[op](value, get(conditions, op), path, { skipConstraints, allowUndefined }));
			}
		});
	}

	// If no operators found then assume we should equal
	if (!opFound) {
		errors.push(eq(value, conditions, path));
	}
	return errors.filter(Boolean);
}

/**
 * And handles logical "and" condition of multiple conditions.  All conditions must evaluate
 * to true.
 *
 * The `%and` performs a logical AND operation on an array of one or more expressions
 * (i.e. <expression1>, <expression2>, etc.) and selects data that satisfy all the
 * expressions in the array. The %and operator uses short-circuit evaluation.
 *
 * The %and operator has the following syntax:
 * ```
 * { %and: [ { <expression1> }, { <expression2> } , ... , { <expressionN> } ] }
 * ```
 *
 * @param {object} dataObj - Any object that needs to have its structured constrained
 * @param {Constraints[]} constraints
 * @param {string[]} skipRequirements
 * @param {boolean} allowUndefined
 * @returns {string[]}
 */
function and(dataObj, constraint, skipRequirements, allowUndefined) {
	const errors = [];

	for (const subConstraints of constraint) {
		const subErrors = satisfiesConstraints(dataObj, subConstraints, skipRequirements, allowUndefined);

		if (subErrors.length) {
			errors.push(`${subErrors.join('; AND ')}; in ${JSON.stringify(subConstraints)}`);
		}
	}

	return errors;
}

/**
 * Or handles logical or condition of multiple conditions.  At least one of the conditions must
 * evaluate to true.
 *
 * %or operator performs a logical OR operation on an array of two or more <expressions> and
 * selects data that satisfy at least one of the <expressions>.
 *
 * The %or operator has the following syntax:
 * ```
 * { %or: [ { <expression1> }, { <expression2> }, ... , { <expressionN> } ] }
 * ```
 *
 * @param {object} dataObj - Any object that needs to have its structured constrained
 * @param {Constraints[]} constraints
 * @param {string[]} skipRequirements
 * @param {boolean} allowUndefined
 * @returns {string[]}
 */
function or(dataObj, constraints, skipRequirements, allowUndefined) {
	const errors = [];

	for (const subConstraints of constraints) {
		const subErrors = satisfiesConstraints(dataObj, subConstraints, skipRequirements, allowUndefined);

		if (subErrors.length === 0) {
			// We don't have any error for this element so %or is a success
			// (no errors reported)
			return [];
		}

		errors.push(`${subErrors.join('; AND ')}; in ${JSON.stringify(subConstraints)}`);
	}
	return errors;
}

/**
 * Regex handles regular expression matching
 * The %regex operator provides regular expression capabilities for pattern matching strings in
 * queries.
 *
 * The %regex operator has the following syntax:
 * ```
 * { <path>: { %regex: <string> } }
 * ```
 * Currently the regex operator is limited to matching purely case insensitive values matches.
 * i.e. {%regex: "abc"} is equivalent to "/abc/i"
 *
 * In the future we may want to be able to pass in a full regex. (i.e. `{ "%regex": "/abc/i" }` )
 * This may help:
 * https://stackoverflow.com/questions/874709/converting-user-input-string-to-regular-expression
 *
 * @param {number} value value that should satisfy condition
 * @param {string} cond regular expression to compare against value
 * @param {object} opts optional error message
 * @param {string} opts.errMsg optional error message
 * @returns {string} error message if condition fails
 */
function regex(value, regex, path, { errMsg }) {
	if (regex === undefined || value.match(new RegExp(regex, 'i'))) {
		return undefined;
	}
	return errMsg || `${path}: ${value} does not match regex ${regex}`;
}

/**
 * Gte handles greater than or equal condition.
 * The %gte operator matches data where the value of the path is greater than or equal to (i.e. >=)
 * a specified condition.
 *
 * The %gte operator has the following syntax:
 * ```
 * { <path>: { %gte: <cond> } }
 * ```
 * @param {number} value value that should satisfy condition
 * @param {number} cond condition to compare against value
 * @param {object} opts optional error message
 * @param {string} opts.errMsg optional error message
 * @returns string error message if condition fails
 */
function gte(value, cond, path, { errMsg }) {
	if (cond === undefined || value >= cond) {
		return undefined;
	}
	return errMsg || `${path}: ${value} should be %gte: ${cond}`;
}

/**
 * Lte handles less than or equal condition.
 * The %lte operator matches data where the value of the path is less than or equal to (i.e. <=)
 * a specified condition.
 *
 * The %lte operator has the following syntax:
 * ```
 * { <path>: { %lte: <cond> } }
 * ```
 * @param {number} value value that should satisfy condition
 * @param {number} cond condition to compare against value
 * @param {string} errMsg optional error message
 * @returns string error message if condition fails
 */
function lte(value, cond, path, { errMsg }) {
	if (cond === undefined || value <= cond) {
		return undefined;
	}
	return errMsg || `${path}: ${value} should be %lte: ${cond}`;
}

/**
 * Gt handles greater than condition.
 * The %gt operator matches data where the value of the path is greater than (i.e. >) a
 * specified condition.
 *
 * The %gt operator has the following syntax:
 * ```
 * { <path>: { %gt: <cond> } }
 * ```
 * @param {number} value value that should satisfy condition
 * @param {number} cond condition to compare against value
 * @param {object} opts optional error message
 * @param {string} opts.errMsg optional error message
 * @returns string error message if condition fails
 */
function gt(value, cond, path, { errMsg }) {
	if (cond === undefined || value > cond) {
		return undefined;
	}
	return errMsg || `${path}: ${value} should be %gt: ${cond}`;
}

/**
 * Lt handles less than condition.
 * The %lt operator matches data where the value of the path is less than (i.e. <) a
 * specified condition.
 *
 * The %lt operator has the following syntax:
 * ```
 * { <path>: { %lt: <cond> } }
 * ```
 * @param {number} value value that should satisfy condition
 * @param {number} cond condition to compare against value
 * @param {object} opts optional error message
 * @param {string} opts.errMsg optional error message
 * @returns string error message if condition fails
 */
function lt(value, cond, path, { errMsg }) {
	if (cond === undefined || value < cond) {
		return undefined;
	}
	return errMsg || `${path}: ${value} should be %lt: ${cond}`;
}

/**
 * Eq handles equality condition.
 * The %eq operator matches data data where the value of a path equals the specified value.
 *
 * The %eq operator has the following syntax:
 * ```
 * { <path>: { %eq: <cond> } }
 * ```
 * @param {number|string|object} value value that should satisfy condition
 * @param {number|string|object} cond condition to compare against value
 * @param {string} errMsg optional error message
 * @returns string error message if condition fails
 */
function eq(value, cond, path, { errMsg } = {}) {
	if (cond === undefined || isEqual(value, cond)) {
		return undefined;
	}
	return errMsg || `${path}: ${value} should be %eq to: ${JSON.stringify(cond)}`;
}

/**
 * Ne handles negation equality condition.
 * The %ne operator matches data where the value of the path is not equal to the specified
 * value.
 *
 * The %ne operator has the following syntax:
 * ```
 * { <path>: { %ne: <cond> } }
 * ```
 * @param {number|string|object} value value that should satisfy condition
 * @param {number|string|object} cond condition to compare against value
 * @param {string} errMsg optional error message
 * @returns string error message if condition fails
 */
function ne(value, cond, path, { errMsg }) {
	if (cond === undefined || !isEqual(value, cond)) {
		return undefined;
	}
	return errMsg || `${path}: ${value} should be %ne to: ${JSON.stringify(cond)}`;
}

/**
 * In handles in condition, testing if a value is 'in an array'
 * The %in operator selects data where the value of a path equals any value in the
 * specified array.
 *
 * The %in operator has the following syntax:
 * ```
 * { path: { %in: [<value1>, <value2>, ... <valueN> ] } }
 * ```
 * @param {any} value value that should satisfy condition
 * @param {array} cond condition to compare against value
 * @param {string} errMsg optional error message
 * @returns string error message if condition fails
 */
function _in(value, cond, path, { errMsg }) {
	if (!Array.isArray(cond)) {
		return `%in condition ${JSON.stringify(cond)} for ${path} is not an array`;
	}
	if (cond === undefined || cond.find((condItem) => isEqual(value, condItem))) {
		return undefined;
	}
	return errMsg || `${path}: ${value} should be %in: ${JSON.stringify(cond)}`;
}

/**
 * Nin handles "not in" condition, testing if a value is 'not in an array'
 * The %nin operator selects data where the value of a path does not equal any value in the
 * specified array.
 *
 * The %nin operator has the following syntax:
 * ```
 * { path: { %nin: [<value1>, <value2>, ... <valueN> ] } }
 * ```
 *
 * https://docs.mongodb.com/manual/reference/operator/query/nin/
 *
 * @param {any} value value that should satisfy condition
 * @param {array} cond condition to compare against value
 * @param {string} errMsg optional error message
 * @returns string error message if condition fails
 */
function nin(value, cond, path, { errMsg }) {
	if (!Array.isArray(cond)) {
		return `%nin condition ${JSON.stringify(cond)} for ${path} is not an array`;
	}
	if (cond === undefined || !cond.find((condItem) => isEqual(value, condItem))) {
		return undefined;
	}
	return errMsg || `${path}: ${JSON.stringify(value)} should be %nin: ${JSON.stringify(cond)}`;
}

/**
 * Some handles some/any condition, testing if a value matches at least one of a set of conditions
 * The %some operator selects data where the value of a path matches any constraints or conditions
 * in a specified array
 *
 * The %some operator has the following syntax:
 * ```
 * { path: { %some: [<(cond/constraint)>, <(cond/constraint)2>, ... <(cond/constraint)N> ] } }
 * ```
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
 *
 * @param {array} value value that should satisfy condition
 * @param {number|string|object} cond condition to compare against value
 * @param {string} opts.errMsg optional error message
 * @param {string} opts.op optional alias for the operation
 * @param {string[]} opts.skipConstraints
 * @param {boolean} opts.allowUndefined
 * @returns string error message if condition fails
 */
function some(values, cond, path, { op = '%some', errMsg, skipConstraints, allowUndefined }) {
	if (!Array.isArray(values)) {
		return `${path}: value with ${op} constraint should be an array`;
	}

	let foundMatch = values.reduce((foundMatch, value) => {
		if (foundMatch) {
			return true;
		}

		// check if the values are equal
		let isEqual = !eq(value, cond, path); // <- eq returns arry of errs
		if (isEqual) {
			return true;
		}

		// check constraint objects
		if (typeof cond === 'object') {
			let constraintErrs;
			if (typeof value === 'object') {
				// check for full constraint matches [{b: 1}, {b: 2}] => {b: {%lt: 3}}
				constraintErrs = satisfiesConstraints(value, cond, skipConstraints, allowUndefined);
			} else {
				// check for simple condition [1, 2] => {%lt: 3}}
				constraintErrs = testCondition(value, cond, skipConstraints, allowUndefined);
			}

			if (!constraintErrs.length) {
				return true; // no errors (found match)
			}
		}

		return false;
	}, false);

	if (foundMatch) {
		return undefined;
	}

	return errMsg || `${path}: does not have a match for ${op}: ${JSON.stringify(cond)}`;
}
/**
 * Exists checks for existance (or lack thereof) of a value. **Must be used with
 * getMatchingConstraintValues(_, _, _, _, _, allowUndefinedConstraints) where
 * allowUndefinedConstraints is either true or an array of paths**
 * @param {undefined|*} values value that should satisfy the condition
 * @param {boolean} cond condition to compare against value
 * @param {string} opts.errMsg optional error message
 * @returns
 */
function exists(values, cond, { errMsg }) {
	// "%exists": true
	if (values !== undefined && cond) {
		return undefined;
	}
	// "%exists": false
	if (values === undefined && !cond) {
		return undefined;
	}
	return errMsg;
}

/**
 * Every handles every/all condition, testing if a value matches all of a set of conditions
 *
 * The %every operator selects data where the value of a path matches all of the
 * constraints or conditions in a specified array
 *
 * The %every operator has the following syntax:
 * ```
 * { path: { %every: [<(cond/constraint)>, <(cond/constraint)2>, ... <(cond/constraint)N> ] } }
 *
 * Caution: Calling this method on an empty array will return true for any condition!
 *
 * Similar to: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every
 *
 * @param {array} value value that should satisfy condition
 * @param {number|string|object|Conditions} cond condition to compare against value
 * @param {string} opts.errMsg optional error message
 * @param {string[]} opts.skipConstraints
 * @param {boolean} opts.allowUndefined
 * @returns string error message if condition fails
 */
function every(values, cond, path, { errMsg, skipConstraints, allowUndefined }) {
	if (!Array.isArray(values)) {
		return `${path}: value with %every constraint should be an array`;
	}

	let mismatchIndices = values.reduce((mismatches, value, index) => {
		// check if the values are equal
		const isEqual = !eq(value, cond, path); // <- no equal error
		let constraintErrs = ['does not satisfy constraints'];

		if (typeof cond === 'object') {
			if (typeof value === 'object') {
				// check for full constraint matches [{b: 1}, {b: 2}] => {b: {%lt: 3}}
				constraintErrs = satisfiesConstraints(value, cond, skipConstraints, allowUndefined);
			} else {
				// check for simple condition [1, 2] => {%lt: 3}}
				constraintErrs = testCondition(value, cond, path, skipConstraints, allowUndefined);
			}
		}

		if (isEqual || constraintErrs.length === 0) {
			return mismatches;
		}

		return mismatches.concat(index);
	}, []);

	if (mismatchIndices.length === 0) {
		return undefined;
	}

	return (
		errMsg || `${path}: has elements [${mismatchIndices.join(',')}] which do not match %every: ${JSON.stringify(cond)}`
	);
}

module.exports = {
	satisfiesConstraints,
};
