import { h, Component } from 'preact';
import get from 'lodash/get';
import { IntlProvider, Text, MarkupText, Localizer } from 'preact-i18n';

import AutoFiLogo from '~ui/assets/autofi-logo.svg';
import FormHeader from '~ui/components/formHeader';
import isolate from '~ui/components/isolate';
import makePhoneNumberPlaceholder from '~ui/components/makePhoneNumberPlaceholder';
import modal from '~ui/components/modal';
import PrettyInput from '~ui/components/prettyInput';
import PrettyTextArea from '~ui/components/prettyTextArea';
import SubmitButton from '~ui/components/submitButton';
import styles from './form/form.styl';
import { AmplitudeLabelComponents, Pathways, VehicleStatus } from '~lib/enum';
import * as utils from '~ui/utils';
import { trackGoogleUniversalAnalyticsEvent, trackShiftDigitalEvent } from '~ui/utils/analytics';
import { trackGoogleAnalytics4Event } from '~ui/utils/googleAnalytics';
import withStyles from '~ui/components/withStyles';

class RequestMoreInfoForm extends Component {
	state = {
		formData: { rememberMe: true },
		inputValidity: {},
		submitEvent: null,
		touched: false,
	};

	inputIsValid = (inputName) => {
		// If valid property is undefined, input hasn't been touched, so don't
		// consider it invalid.
		return Boolean(get(this.state.inputValidity, [inputName, 'valid'], true));
	};

	formIsValid = () => {
		const { email, phone } = this.state.formData;
		return (
			this.requiredFieldsAreFilled() &&
			this.inputIsValid('name') &&
			(!phone || this.inputIsValid('phone')) &&
			(!email || this.inputIsValid('email'))
		);
	};

	requiredFieldsAreFilled = () => {
		const { email, name, phone } = this.state.formData;
		const { requirePhone } = this.props.autofiData.dealer.websiteSettings.ui.features.getYourPrice;
		return Boolean(name && email && (requirePhone ? phone : true));
	};

	handleInputChange = (event) => {
		const { target } = event;
		this.setState({ touched: true });
		this.updateInputState(target);
	};

	updateInputState = ($input) => {
		const value = $input.type === 'checkbox' ? $input.checked : $input.value;
		const { name, validity } = $input;
		this.setState((prevState) => ({
			formData: { ...prevState.formData, [name]: value },
			inputValidity: { ...prevState.inputValidity, [name]: validity },
		}));
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ submitEvent: event });
	};

	submit = () => {
		const { autofiData, pageType, submit, trackAmplitudeEvent, vehicle } = this.props;
		const { dealer: currentDealer, sessionData } = autofiData;
		const { formData, submitEvent } = this.state;
		const { comments, email, name, phone, rememberMe } = formData;
		const contactData = { email, name, phone, rememberMe };
		const dealer = vehicle.dealer || currentDealer;
		const applicant = utils.makeApplicant({ comments, contactData, dealer });
		const googleEventMetaData = { dealer: currentDealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_submission',
			{ form_name: 'request_info', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_submission_sales',
			{ form_name: 'request_info', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'request_info', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);

		trackGoogleUniversalAnalyticsEvent('Requested Information Form Submitted', googleEventMetaData);

		trackShiftDigitalEvent('drLeadFormFinish', vehicle, currentDealer, sessionData, applicant, pageType);

		const { PathwaySubmit } = AmplitudeLabelComponents.Label;
		const pathway = Pathways.RequestMoreInfo;
		trackAmplitudeEvent({ event: submitEvent, vehicle, label: PathwaySubmit, pathway });

		submit(applicant, () => this.setState({ submitEvent: null }));
	};

	initializeInputState = () => {
		[...this.$form.querySelectorAll('input, textarea, select')].forEach(this.updateInputState);
	};

	trackGa4LoadEvents = () => {
		const { vehicle, autofiData } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);

		trackGoogleAnalytics4Event('asc_page_view', { page_type: 'sales', vehicle }, googleEventMetaData);
	};

	componentDidMount = () => {
		this.initializeInputState();

		if (!this.props.hidden) {
			this.trackGa4LoadEvents();
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { applicant, autofiData, hidden, pageType, vehicle } = this.props;
		const { dealer: currentDealer, sessionData } = autofiData;

		if (!hidden) {
			const formBecameVisible = prevProps.hidden;
			const applicantChanged = applicant !== prevProps.applicant;

			if (formBecameVisible) {
				this.trackGa4LoadEvents();
			}

			if (formBecameVisible || applicantChanged) {
				this.initializeInputState();
			}
		}

		const { submitEvent, touched } = this.state;
		if (!prevState.touched && touched) {
			trackShiftDigitalEvent('drLeadFormStart', vehicle, currentDealer, sessionData, applicant, pageType);
		}

		const { Pending } = VehicleStatus;
		const prevVehicleStatus = get(prevProps.vehicle, 'status', Pending);
		const vehicleStatus = get(vehicle, 'status', Pending);
		const formWasReadyToSubmit = Boolean(prevVehicleStatus !== Pending && prevState.submitEvent);
		const formIsReadyToSubmit = Boolean(vehicleStatus !== Pending && submitEvent);
		if (!formWasReadyToSubmit && formIsReadyToSubmit) {
			this.submit();
		}
	};

	handleClose = () => {
		const { autofiData, close, vehicle } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'request_info', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);

		close();
	};

	render = (props, state) => {
		const { applicant, autofiData, dictionary, subTitle, title } = props;
		const { formData, submitEvent } = state;
		const { requirePhone } = autofiData.dealer.websiteSettings.ui.features.getYourPrice;
		const { launchDarklyFeatureFlags } = autofiData;
		const showTCPADisclosure = launchDarklyFeatureFlags.enableTCPAConsent;

		return (
			<IntlProvider definition={dictionary}>
				<div class="autofi-modal requestMoreInfoForm">
					<FormHeader close={this.handleClose} subTitle={subTitle} title={title} />
					<div class="modalContent">
						<form class="autofi-form" ref={($el) => (this.$form = $el)}>
							<div class="row">
								<Localizer>
									<PrettyInput
										name="name"
										defaultValue={applicant.name.full}
										placeholder={<Text id="full-name">Full Name</Text>}
										required
										pattern={utils.validationPatterns.fullname}
										hint={utils.hasNoNumbers(formData.name) ? '' : <Text id="invalid-name-numbers-are-not-allowed" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
							</div>
							<div class="row">
								<Localizer>
									<PrettyInput
										name="email"
										defaultValue={applicant.email}
										type="email"
										placeholder={<Text id="email">Email</Text>}
										hint={this.inputIsValid('email') ? '' : <Text id="invalid-email-address" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
								<Localizer>
									<PrettyInput
										name="phone"
										defaultValue={applicant.phone}
										type="tel"
										placeholder={makePhoneNumberPlaceholder({ requirePhone })}
										hint={this.inputIsValid('phone') || !formData.phone ? '' : <Text id="invalid-phone-number" />}
										onInput={this.handleInputChange}
									/>
								</Localizer>
							</div>
							<div class="row">
								<Localizer>
									<PrettyTextArea
										name="comments"
										placeholder={<Text id="any-questions-or-comments">Any questions or comments?</Text>}
										onInput={this.handleInputChange}
									/>
								</Localizer>
							</div>
							<div class="row">
								<SubmitButton
									onClick={this.handleSubmit}
									disabled={!this.formIsValid()}
									submitting={Boolean(submitEvent)}
								>
									<Text id="submit" />
								</SubmitButton>
							</div>
							<div>
								<label class="checkboxContainer">
									<input
										type="checkbox"
										name="rememberMe"
										checked={formData.rememberMe}
										onChange={this.handleInputChange}
									/>
									<div>
										<Text id="remember-me" />
										<div class="explanation">
											<MarkupText id="you-wont-have-to-fill-out" />
										</div>
									</div>
								</label>
							</div>
							{showTCPADisclosure && (
								<div class="row">
									<div className="consent">
										<MarkupText id="tcpa-consent" />
									</div>
								</div>
							)}
							<div class="poweredBy">
								<Text id="powered-by" /> <AutoFiLogo class="autoFiLogo" />
							</div>
						</form>
					</div>
				</div>
			</IntlProvider>
		);
	};
}

const WrappedRequestMoreInfoForm = modal(isolate(withStyles(RequestMoreInfoForm, styles)));

WrappedRequestMoreInfoForm.allRequiredFieldsFilled = () => {
	// Users may write different comments when clicking on RequestMoreInfo button,
	// so request more info will always be shown on every vehicle.
	return false;
};

export default WrappedRequestMoreInfoForm;
