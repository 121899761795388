import { errorToConsole } from '~ui/utils';

// Common sourceData event payload
const formSubmissionSourceData = (payload) => ({
	form_name: payload.form_name,
	form_type: payload.form_type,
	department: payload.department,
	comm_type: 'form',
});

// Event payload data builders (use the above standard for identical events such as form submissions)
const sourceDataBuilders = {
	asc_form_submission: formSubmissionSourceData,
	asc_form_submission_sales_appt: formSubmissionSourceData,
	asc_form_submission_sales: formSubmissionSourceData,
	asc_form_engagement: formSubmissionSourceData,
	asc_retail_process: (payload) => ({
		page_type: payload.page_type,
		flow_name: payload.flow_name,
		flow_outcome: payload.flow_outcome,
	}),
	asc_cta_interaction: (payload) => ({
		element_type: payload.element_type,
		event_action: payload.event_action,
		page_type: payload.page_type,
	}),
	asc_page_view: (payload) => ({
		page_type: payload.page_type,
	}),
};

// Only send existing vehicle data
export const buildVehiclePayload = (vehicle) => {
	const vehiclePayload = {};
	if (vehicle) {
		if (vehicle.vin) {
			vehiclePayload.item_id = vehicle.vin;
		}
		if (vehicle.stockNumber) {
			vehiclePayload.item_number = vehicle.stockNumber;
		}
		if (vehicle.age) {
			vehiclePayload.item_condition = vehicle.age;
		}
		if (vehicle.year) {
			vehiclePayload.item_year = vehicle.year;
		}
		if (vehicle.make) {
			vehiclePayload.item_make = vehicle.make;
		}
		if (vehicle.model) {
			vehiclePayload.item_model = vehicle.model;
		}
	}
	return vehiclePayload;
};

const buildEvent = (eventName, payload) => {
	const sourceDataBuilder = sourceDataBuilders[eventName];
	if (!sourceDataBuilder) {
		errorToConsole(`Unsupported event name: ${eventName}`);
		return null;
	}

	return {
		...sourceDataBuilder(payload),
		...buildVehiclePayload(payload.vehicle),
		...payload.customParameters,
	};
};

/**
 * Gets measurementIds Array for GA4
 * @param {Object} metadata
 * @param {Object} metadata.dealer - currentDealer
 */
const getMeasurementIds = ({ dealer }) => {
	let measurementIds = [];

	const googleAnalyticsMeasurementIds = dealer.settings?.analytics?.googleAnalyticsMeasurementIds || [];

	try {
		measurementIds = googleAnalyticsMeasurementIds.filter((id) => !id.toLowerCase().startsWith('ua'));
	} catch (e) {
		/* do nothing */
	}

	return measurementIds;
};

/**
 * Embeds and initializes gtag script
 * @param {Object} dealer - currentDealer
 */
export const embedGoogleAnalytics4 = (dealer) => {
	if (!dealer.isLive) {
		return;
	}

	const measurementIds = getMeasurementIds({ dealer });

	if (!measurementIds.length) {
		return;
	}

	// if we already embedded the script do not do it twice
	if (document.querySelector('#autofi_gtm_script')) {
		return;
	}

	const gtmScript = document.createElement('script');
	gtmScript.id = 'autofi_gtm_script';
	// only the first ID should be pushed to the GTM script
	// the rest, if there are more than 1 are handled in the dataLayer script below
	gtmScript.src = `https://www.googletagmanager.com/gtag/js?${new URLSearchParams({ id: measurementIds[0] })}`;

	const dataLayerScript = document.createElement('script');

	dataLayerScript.innerHTML = `
		window.dataLayer = window.dataLayer || [];
		window.gtag = window.gtag || function(){dataLayer.push(arguments);}
		gtag('js', new Date());
		${measurementIds.map((id) => `gtag('config', '${id}');`).join('\n\t\t\t')}
	`;

	document.head.prepend(gtmScript, dataLayerScript);
};

/**
 * Sends a Google Analytics 4 event
 *
 * @param {string} eventName - string that will appear as the event action in Google Analytics Event reports
 * @param {Object} payload - additional parameters to send with the event
 * @param {Object} metadata
 * @param {boolean} metadata.isInStore
 * @param {Object} metadata.dealer - currentDealer
 */
export const trackGoogleAnalytics4Event = (eventName, payload, { dealer, isInStore }) => {
	const measurementIds = getMeasurementIds({ dealer });

	// no ids found for GA4 events, we are in Store, or gtag has not been set up, move on
	if (!measurementIds.length || isInStore || !window.gtag) {
		return;
	}

	const eventParams = buildEvent(eventName, payload);

	if (!eventParams) {
		return;
	}

	measurementIds.forEach((measurementId) => {
		window.gtag('event', eventName, {
			event_owner: 'AutoFi',
			send_to: measurementId,
			...eventParams,
		});
	});
};
