import { h, Component } from 'preact';

import ModalSequence from '~ui/components/modalSequence';
import Form from './form';
import Loading from './loading';

class PrivateOffersSequence extends Component {
	render = (props) => {
		return <ModalSequence {...props} pages={[Form, Loading]} />;
	};
}

export default PrivateOffersSequence;
