import get from 'lodash/get';
import { h, Component } from 'preact';
import { IntlProvider } from 'preact-i18n';

import ExplorePaymentsButtonText from '~ui/components/explorePaymentsButtonText';
import isolate from '~ui/components/isolate';
import withStyles from '~ui/components/withStyles';

import styles from './mainButton.styl';

class ExplorePaymentsButton extends Component {
	render = (props) => {
		const {
			borderRadius,
			buttonTextOverride,
			colors,
			compact,
			dictionary,
			estimate,
			isLockedPricing,
			onClick,
			paymentInterval,
			showPaymentDetailsOnButton,
			waitingForVehicleData,
		} = props;
		const buttonClasses = ['autofi-cta', 'autofi-main-button'];
		if (compact) {
			buttonClasses.push('compact');
		}

		return (
			<IntlProvider definition={dictionary}>
				<button
					class={buttonClasses.join(' ')}
					data-autofi-cta-type="startApplication"
					onClick={onClick}
					type="button"
					style={{
						backgroundColor: get(colors, 'background'),
						borderRadius,
						color: get(colors, 'text'),
					}}
				>
					<ExplorePaymentsButtonText
						buttonTextOverride={buttonTextOverride}
						estimate={estimate}
						isLockedPricing={isLockedPricing}
						paymentInterval={paymentInterval}
						showPaymentDetailsOnButton={showPaymentDetailsOnButton}
						waitingForVehicleData={waitingForVehicleData}
					/>
				</button>
			</IntlProvider>
		);
	};
}

export default isolate(withStyles(ExplorePaymentsButton, styles));
