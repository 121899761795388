import { h } from 'preact';
import { IntlProvider, Text } from 'preact-i18n';

import isolate from '~ui/components/isolate';
import withStyles from '~ui/components/withStyles';

import styles from './banner.styl';

import background from './background.png';

const CreditApplicationBanner = ({ baseUrl, dealer, dictionary, launchDarklyFeatureFlags, onClick }) => {
	const { usePandaCreditAppBannerImageFromCdn } = launchDarklyFeatureFlags;
	const backgroundUrl = usePandaCreditAppBannerImageFromCdn
		? 'https://cdn.autofi.com/images/background.png'
		: `${baseUrl}/${background}`;

	return (
		<IntlProvider definition={dictionary}>
			<div className="autofi-cta creditApplicationBanner" data-autofi-cta-type="creditApp" onClick={onClick}>
				<div className="backgroundImageContainer" style={{ backgroundImage: `url("${backgroundUrl}")` }}>
					<div className="text">
						<Text id="instant-prequal-no-ssn" />
					</div>
					<button className="button" type="button">
						<Text id="start-today" />
					</button>
				</div>
			</div>
		</IntlProvider>
	);
};

export default isolate(withStyles(CreditApplicationBanner, styles));
