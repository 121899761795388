import get from 'lodash/get';
import { h, Component } from 'preact';
import { IntlProvider } from 'preact-i18n';

import isolate from '~ui/components/isolate';
import OverrideText from '~ui/components/overrideText';
import withStyles from '~ui/components/withStyles';

import styles from './mainButton.styl';

const StandAloneCreditAppButton = (props) => {
	const { colors, buttonTextOverride, compact, dictionary, onClick } = props;
	const buttonClasses = ['autofi-cta', 'autofi-main-button'];
	if (compact) {
		buttonClasses.push('compact');
	}

	const buttonText = buttonTextOverride || 'start-your-credit-application';

	return (
		<IntlProvider definition={dictionary}>
			<button
				class={buttonClasses.join(' ')}
				data-autofi-cta-type="standAloneCreditApp"
				onClick={onClick}
				type="button"
				style={{ backgroundColor: get(colors, 'background'), color: get(colors, 'text') }}
			>
				<OverrideText content={buttonText} />
			</button>
		</IntlProvider>
	);
};

export default isolate(withStyles(StandAloneCreditAppButton, styles));
