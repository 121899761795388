import get from 'lodash/get';
import pick from 'lodash/pick';
import { h, Component } from 'preact';
import { IntlProvider, Localizer, Text, MarkupText } from 'preact-i18n';

import OverrideText from '~ui/components/overrideText';
import { AmplitudeLabelComponents, Pathways } from '~lib/enum';
import AutoFiLogo from '~ui/assets/autofi-logo.svg';
import CloseIcon from '~ui/assets/close-icon.svg';
import LightBulbIcon from '~ui/assets/light-bulb.svg';
import styles from './sequence.styl';
import withStyles from '~ui/components/withStyles';
import PrettyAddressInput from '~ui/components/prettyAddressInput';
import PrettyInput from '~ui/components/prettyInput';
import SubmitButton from '~ui/components/submitButton';
import * as utils from '~ui/utils';
import { trackGoogleUniversalAnalyticsEvent, trackShiftDigitalEvent } from '~ui/utils/analytics';
import { trackGoogleAnalytics4Event } from '~ui/utils/googleAnalytics';
import consumerSession from '~ui/utils/consumerSession';
import ConsumerMFAClient from './ConsumerMFAClient';

class Form extends Component {
	state = {
		formData: { consent: false, rememberMe: true },
		inputValidity: {},
		isSubmitting: false,
		touched: false,
	};

	inputIsValid = (inputName) => {
		// If valid property is undefined, input hasn't been touched, so don't
		// consider it invalid.
		return Boolean(get(this.state.inputValidity, [inputName, 'valid'], true));
	};

	formIsValid = () => {
		// Usually, this.base refers to the form. But when the component is
		// mounting, this.base is undefined, so in that case, avoid calling
		// checkValidity.
		return this.base && this.base.checkValidity() && this.phoneAndEmailAreValid();
	};

	phoneAndEmailAreValid = () => {
		const { requirePhone } = this.props.autofiData.dealer.websiteSettings.ui.features.getYourPrice;
		const { consent, email, phone } = this.state.formData;
		return Boolean(consent && email && this.inputIsValid('email') && (requirePhone ? phone : true));
	};

	handleInputChange = (event) => {
		const { target } = event;
		this.setState({ touched: true });
		this.updateInputState(target);
	};

	getInputState = ($input) => {
		const value = $input.type === 'checkbox' ? $input.checked : $input.value;
		const { name, validity } = $input;

		return { name, validity, value };
	};

	updateInputState = ($input) => {
		const { name, validity, value } = this.getInputState($input);
		const { formData, inputValidity } = this.state;

		if (formData[name] === value && inputValidity[name] === validity) {
			// input has not changed
			return;
		}

		this.setState({
			formData: { ...formData, [name]: value },
			inputValidity: { ...inputValidity, [name]: validity },
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({ isSubmitting: true });

		const { autofiData, pageType, performSoftPull, propagateApplicant, showNextPage, showPage, trackAmplitudeEvent } =
			this.props;

		showNextPage();

		const { formData } = this.state;

		const { apiProxyUrl, dealer, sessionData, launchDarklyFeatureFlags } = autofiData;

		const contactData = pick(formData, [
			'city',
			'email',
			'name',
			'phone',
			'rememberMe',
			'state',
			'street',
			'street2',
			'zip',
		]);
		let { income } = formData;
		income = utils.parseMoneyString(income);
		const applicant = utils.makeApplicant({ contactData, dealer, income });
		applicant.isTestUser = utils.isTestUser(applicant);
		const vehicle = undefined;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_submission',
			{ form_name: 'credit_estimator', form_type: 'consumer_contact', department: 'sales' },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_submission_sales',
			{ form_name: 'credit_estimator', form_type: 'consumer_contact', department: 'sales' },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'credit_estimator', form_type: 'consumer_contact', department: 'sales' },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start' },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction' },
			googleEventMetaData
		);

		trackGoogleUniversalAnalyticsEvent('Credit App Lead', googleEventMetaData);

		trackShiftDigitalEvent('drLeadFormFinish', vehicle, dealer, sessionData, applicant, pageType);
		trackShiftDigitalEvent('drPreQFinish', vehicle, dealer, sessionData, applicant, pageType);

		const { PathwaySubmit } = AmplitudeLabelComponents.Label;
		const pathway = Pathways.CreditApp;
		trackAmplitudeEvent({ event, label: PathwaySubmit, opts: { isTestUser: applicant.isTestUser }, pathway });

		let isAuthorized = true;

		if (launchDarklyFeatureFlags?.enableMfaAuthCheck) {
			({ isAuthorized } = await consumerSession.checkAuthStatus({
				apiProxyUrl,
				launchDarklyFeatureFlags,
				sessionData,
			}));
		}

		propagateApplicant(applicant, () => {
			if (isAuthorized) {
				performSoftPull();
				showNextPage();
				return;
			}

			showPage(ConsumerMFAClient);
		});
	};

	initializeInputState = () => {
		const { formData, inputValidity } = this.state;
		const $inputs = [...this.base.querySelectorAll('input, textarea, select')];
		const inputState = $inputs.map(this.getInputState);

		inputState.forEach(({ name, value, validity }) => {
			formData[name] = value;
			inputValidity[name] = validity;
		});

		this.setState({ formData, inputValidity });
	};

	handlePlaceChange = (addressDetails) => {
		const { formData } = this.state;
		const address = {
			street: get(addressDetails, 'street_number', ''),
			city: get(addressDetails, 'locality', ''),
			state: get(addressDetails, 'administrative_area_level_1', ''),
			zip: get(addressDetails, 'postal_code', ''),
		};

		[...this.base.querySelectorAll('input')]
			.filter(($input) => $input.name in address)
			.forEach(($input) => {
				$input.value = address[$input.name];
				this.updateInputState($input);
			});

		// Update value in state of inputs to properly set 'empty' class
		[...this.base.querySelectorAll(':not(.prettyAddressInput) > input')].forEach(($input) =>
			$input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
		);

		this.base.querySelector('[name=street2]').focus();
		this.setState({
			formData: {
				...formData,
				...address,
			},
		});
	};

	componentDidMount = () => {
		const { applicant, autofiData, pageType, trackAmplitudeEvent } = this.props;
		const { dealer: currentDealer, sessionData } = autofiData;
		const vehicle = undefined;
		const googleEventMetaData = { dealer: currentDealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start' },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction' },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event('asc_page_view', { page_type: 'sales', vehicle }, googleEventMetaData);

		const { Load } = AmplitudeLabelComponents.Action;
		const { PathwayCreditEstimator } = AmplitudeLabelComponents.Label;
		const { LeadForm } = AmplitudeLabelComponents.Steps;
		const pathway = Pathways.CreditApp;
		trackAmplitudeEvent({ action: Load, label: PathwayCreditEstimator, opts: { step: LeadForm }, pathway });

		trackShiftDigitalEvent('drPreQShown', vehicle, currentDealer, sessionData, applicant, pageType);

		this.initializeInputState();
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { applicant, autofiData, pageType, vehicle } = this.props;
		const { dealer: currentDealer, sessionData } = autofiData;
		const { touched } = this.state;

		if (!prevState.touched && touched) {
			trackShiftDigitalEvent('drLeadFormStart', vehicle, currentDealer, sessionData, applicant, pageType);
			trackShiftDigitalEvent('drPreQStart', vehicle, currentDealer, sessionData, applicant, pageType);
		}
	};

	handleClose = () => {
		const { autofiData, close } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'credit_estimator', form_type: 'consumer_contact', department: 'sales' },
			googleEventMetaData
		);

		close();
	};

	render = (props, state) => {
		const { applicant, autofiData, subTitle, title, dictionary } = props;
		const { dealer, googleApiKey, launchDarklyFeatureFlags } = autofiData;
		const { formData, isSubmitting } = state;
		const street = formData.street === '' ? applicant.address.street : formData.street;
		const hint = utils.makeAddressHintMessage(street);
		const showTCPADisclosure = launchDarklyFeatureFlags?.enableTCPAConsent;

		return (
			<IntlProvider definition={dictionary}>
				<form class="autofi-modal creditAppPage creditAppForm autofi-form">
					<header class="modalHeader">
						<button type="button" class="closeButton" onClick={this.handleClose}>
							<Text id="close" /> <CloseIcon />
						</button>
						<h2>
							<OverrideText content={title} />
						</h2>
						{subTitle && (
							<h3>
								<OverrideText content={subTitle} />
							</h3>
						)}
					</header>
					<div class="modalContent">
						<div class="row">
							<Localizer>
								<PrettyInput
									name="name"
									defaultValue={applicant.name.full}
									placeholder="Full Name"
									required
									pattern={utils.validationPatterns.fullname}
									hint={utils.hasNoNumbers(formData.name) ? '' : <Text id="invalid-name-numbers-are-not-allowed" />}
									onInput={this.handleInputChange}
								/>
							</Localizer>
						</div>
						<div class="row">
							<Localizer>
								<PrettyInput
									name="email"
									defaultValue={applicant.email}
									type="email"
									placeholder="Email"
									required
									hint={!formData.email || this.inputIsValid('email') ? '' : <Text id="invalid-email-address" />}
									onInput={this.handleInputChange}
								/>
							</Localizer>
							<Localizer>
								<PrettyInput
									name="phone"
									defaultValue={applicant.phone}
									type="tel"
									placeholder="Phone Number"
									hint={this.inputIsValid('phone') || !formData.phone ? '' : <Text id="invalid-phone-number" />}
									onInput={this.handleInputChange}
								/>
							</Localizer>
						</div>
						<div class="row">
							<Localizer>
								<PrettyAddressInput
									name="street"
									containerClass="streetContainer"
									defaultValue={applicant.address.street}
									placeholder={<Text id="address">Address</Text>}
									required
									onInput={this.handleInputChange}
									googleApiKey={googleApiKey}
									onPlaceChange={this.handlePlaceChange}
									hint={<Text id={hint} />}
								/>
							</Localizer>
							<Localizer>
								<PrettyInput
									name="street2"
									containerClass="street2Container"
									defaultValue={applicant.address.street2}
									placeholder={<Text id="apt">Apt</Text>}
									onInput={this.handleInputChange}
								/>
							</Localizer>
						</div>
						<div class="row">
							<Localizer>
								<PrettyInput
									name="city"
									containerClass="cityContainer"
									defaultValue={applicant.address.city}
									placeholder={<Text id="city">City</Text>}
									required
									onInput={this.handleInputChange}
								/>
							</Localizer>
							<Localizer>
								<PrettyInput
									name="state"
									containerClass="stateContainer"
									defaultValue={applicant.address.state}
									maxlength={2}
									pattern="[a-zA-Z]{2}"
									placeholder={<Text id="state">State</Text>}
									required
									hint={this.inputIsValid('state') || !formData.state ? '' : <Text id="invalid-state" />}
									onInput={this.handleInputChange}
								/>
							</Localizer>
							<Localizer>
								<PrettyInput
									name="zip"
									containerClass="zipContainer"
									defaultValue={applicant.address.zip}
									pattern="\d{5}|[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d"
									placeholder={<Text id="zip-code">Zip code</Text>}
									required
									hint={this.inputIsValid('zip') || !formData.zip ? '' : 0}
									onInput={this.handleInputChange}
								/>
							</Localizer>
						</div>
						<div class="row">
							<Localizer>
								<PrettyInput
									name="income"
									containerClass="preTaxIncomeContainer"
									defaultValue={applicant.income}
									maskType="money"
									placeholder={<Text id="pre-tax-monthly-income">Pre Tax Monthly Income</Text>}
									required
									type="text"
									hint={this.inputIsValid('income') || !formData.income ? '' : <Text id="invalid-monthly-income" />}
									onInput={this.handleInputChange}
								/>
							</Localizer>
						</div>
						<div class="note">
							<LightBulbIcon class="icon" />
							<span>
								<MarkupText id="this-will-not-affect-your-credit-score" />
							</span>
						</div>
						<div>
							<label class="checkboxContainer">
								<input
									type="checkbox"
									name="consent"
									checked={formData.consent}
									onChange={this.handleInputChange}
									required
								/>
								<div class="small">
									<MarkupText
										id={showTCPADisclosure ? 'by-clicking-see-my-estimates-tcpa' : 'by-clicking-see-my-estimates'}
										fields={{ dealer: dealer.name }}
									/>
								</div>
							</label>
						</div>
						<div>
							<label class="checkboxContainer">
								<input
									type="checkbox"
									name="rememberMe"
									checked={formData.rememberMe}
									onChange={this.handleInputChange}
								/>
								<div>
									<Text id="remember-me" />
									<div class="explanation">
										<MarkupText id="you-wont-have-to-fill-out" />
									</div>
								</div>
							</label>
						</div>
						<div class="buttonContainer">
							<SubmitButton onClick={this.handleSubmit} disabled={!this.formIsValid()} submitting={isSubmitting}>
								<Text id="see-my-estimates" />
							</SubmitButton>
						</div>
						<div class="poweredBy">
							<Text id="powered-by" /> <AutoFiLogo class="autoFiLogo" />
						</div>
					</div>
				</form>
			</IntlProvider>
		);
	};
}

export default withStyles(Form, styles);
