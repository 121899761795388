import isNil from 'lodash/isNil';
import { h, Component } from 'preact';
import { Text } from 'preact-i18n';
import { Donut } from 'gaugeJS';

class CreditScoreGauge extends Component {
	creditRangeIsKnown = () => {
		const { bottom, top } = this.props.ficoRange || {};
		return !(isNil(bottom) || isNil(top));
	};

	gaugeRef = ($gaugeCanvas) => {
		this.$gaugeCanvas = $gaugeCanvas;
	};

	componentDidMount = () => {
		this.initCreditGauge();
		window.addEventListener('resize', this.fixCreditGaugeDimensions);
	};

	componentDidUpdate = () => {
		this.updateCreditGauge();
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.fixCreditGaugeDimensions);
	}

	initCreditGauge = () => {
		this.$gaugeCanvas.width = this.$gaugeCanvas.offsetWidth;
		// Set height equal to offsetWidth so gauge has an aspect ratio of 1. This
		// prevents the gauge from looking funny and cut off on small screens.
		this.$gaugeCanvas.height = this.$gaugeCanvas.offsetWidth;
		this.gauge = new Donut(this.$gaugeCanvas);
		this.gauge.setOptions({
			angle: 0.15,
			colorStart: '#4593e2',
			colorStop: '#4593e2',
			lineWidth: 0.07,
			strokeColor: '#e6e6e6',
		});
		this.gauge.animationSpeed = 7;
		// minValue must be set using setMinValue and maxValue must be set
		// directly, else animation will look funny
		this.gauge.setMinValue(300);
		this.gauge.maxValue = 850;
		this.updateCreditGauge();
	};

	updateCreditGauge = () => {
		if (this.creditRangeIsKnown()) {
			const { bottom, top } = this.props.ficoRange;
			this.gauge.set((bottom + top) / 2);
		} else {
			this.gauge.set(0);
		}
	};

	fixCreditGaugeDimensions = () => {
		this.$gaugeCanvas.style.width = '';
		this.$gaugeCanvas.style.height = '';
	};

	render = (props) => {
		const { dictionary } = props;
		const { bottom, top } = props.ficoRange || {};

		return (
			<div>
				<div class="gaugeContainer">
					<canvas class="gauge" ref={this.gaugeRef} />
					<div class="range">{this.creditRangeIsKnown() ? `${bottom}-${top}` : '-'}</div>
				</div>
				{this.creditRangeIsKnown() ? null : (
					<p>
						<Text id="unfortunately-we-dont-have-enough-info" />
					</p>
				)}
			</div>
		);
	};
}

export default CreditScoreGauge;
