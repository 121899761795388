import { h, Component } from 'preact';
import { IntlProvider, MarkupText, Text } from 'preact-i18n';
import clamp from 'lodash/clamp';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { VehicleStatus } from '~lib/enum';
import FormHeader from '~ui/components/formHeader';
import DisclosurePopup from '~ui/components/disclosurePopup';
import InfoIcon from '~ui/components/infoIcon';
import isolate from '~ui/components/isolate';
import modal from '~ui/components/modal';
import SubmitButton from '~ui/components/submitButton';
import withStyles from '~ui/components/withStyles';
import DollarIcon from '~ui/assets/dollarsign.svg';
import ScheduleIcon from '~ui/assets/schedule.svg';
import DepositIcon from '~ui/assets/deposit.svg';
import * as utils from '~ui/utils';
import styles from './depositModal.styl';

import { trackGoogleAnalytics4Event } from '~ui/utils/googleAnalytics';

class DepositModal extends Component {
	state = {
		disclosureIsOpen: false,
		submitEvent: null,
	};

	handleBodyClick = () => {
		this.setState({ disclosureIsOpen: false });
	};

	handleDisclosureClick = (event) => {
		event.stopPropagation();
		this.setState((state) => ({ disclosureIsOpen: !state.disclosureIsOpen }));
		this.updatePopupPositionStyles();
	};

	updatePopupPositionStyles = () => {
		const bodyRect = document.body.getBoundingClientRect();
		const disclaimerRect = this.base.getBoundingClientRect();

		const popupPositionStyles = {
			backgroundColor: '#fff',
			top: disclaimerRect.bottom - bodyRect.top - disclaimerRect.height / 3,
			right: 1.05 * bodyRect.width - disclaimerRect.right + bodyRect.left,
			width: clamp(window.innerWidth, 150, Math.min(bodyRect.right, 350)),
		};

		this.setState({ popupPositionStyles });
	};

	trackLoad = () => {
		const { autofiData, vehicle } = this.props;
		const { dealer, sessionData } = autofiData;
		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event('asc_page_view', { page_type: 'sales', vehicle }, googleEventMetaData);
	};

	componentDidMount = () => {
		const { hidden } = this.props;

		document.addEventListener('click', this.handleBodyClick);
		window.addEventListener('resize', this.updatePopupPositionStyles);

		if (!hidden) {
			this.trackLoad();
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { hidden, vehicle } = this.props;
		const { submitEvent } = this.state;

		if (!hidden && prevProps.hidden) {
			this.trackLoad();
		}

		const { Pending } = VehicleStatus;
		const prevVehicleStatus = get(prevProps.vehicle, 'status', Pending);
		const vehicleStatus = get(vehicle, 'status', Pending);
		const formWasReadyToSubmit = Boolean(prevVehicleStatus !== Pending && prevState.submitEvent);
		const formIsReadyToSubmit = Boolean(vehicleStatus !== Pending && submitEvent);
		if (!formWasReadyToSubmit && formIsReadyToSubmit) {
			this.submit();
		}
	};

	componentWillUnmount = () => {
		document.addEventListener('click', this.handleBodyClick);
		window.addEventListener('resize', this.updatePopupPositionStyles);
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ submitEvent: event });
	};

	submit = () => {
		const { applicant, autofiData, submit, trackAmplitudeEvent, vehicle } = this.props;
		const { submitEvent } = this.state;
		const { dealer, loanAppBaseUrl, sessionData } = autofiData;
		const dealerId = dealer._id;
		const googleEventMetaData = {
			dealer,
			isInStore: sessionData.isInStore,
		};

		trackGoogleAnalytics4Event(
			'asc_form_submission',
			{ form_name: 'deposits', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_submission_sales',
			{ form_name: 'deposits', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'deposits', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_retail_process',
			{ page_type: 'sales', flow_name: 'contact_info', flow_outcome: 'start', vehicle },
			googleEventMetaData
		);
		trackGoogleAnalytics4Event(
			'asc_cta_interaction',
			{ page_type: 'sales', element_type: 'digital_retailing_tool', event_action: 'pathway_interaction', vehicle },
			googleEventMetaData
		);

		// TODO: pass pathway of Pathways.Deposit here to make this consistent with other
		// pathways. See https://autofi.atlassian.net/browse/CS-1629
		trackAmplitudeEvent({ event: submitEvent, vehicle, label: 'Pathway: deposit: click: continue' });

		submit(applicant, (err, response) => {
			if (err) {
				this.setState({ submitEvent: null });
				alert(err);
				return;
			}

			if (response) {
				// response may be undefined for invalid vehicle leads
				const { id: loanAppId, url } = response;
				// eslint-disable-next-line no-useless-escape
				const token = new URL(url).pathname.match(/[^\/]*$/)[0];
				const vehicleData = pick(vehicle, ['make', 'model', 'photoUrl', 'year']);

				utils.loadStripeCheckoutForm({ dealerId, loanAppBaseUrl, loanAppId, token, vehicleData }, () =>
					this.setState({ submitEvent: null })
				);
			}
		});
	};

	handleClose = (event) => {
		const { autofiData, close, trackAmplitudeEvent, vehicle } = this.props;
		const { dealer, sessionData } = autofiData;

		// TODO: pass pathway of Pathways.Deposit here to make this consistent with other
		// pathways. See https://autofi.atlassian.net/browse/CS-1629
		trackAmplitudeEvent({ event, vehicle, label: 'Pathway: deposit: click: close' });

		const googleEventMetaData = { dealer, isInStore: sessionData.isInStore };

		trackGoogleAnalytics4Event(
			'asc_form_engagement',
			{ form_name: 'deposits', form_type: 'consumer_contact', department: 'sales', vehicle },
			googleEventMetaData
		);

		close();
	};

	render = (props, state) => {
		const { autofiData, dictionary, title } = props;
		const { dealer } = autofiData;
		const dealerName = dealer.name;
		const depositAmount = get(dealer, 'settings.deposit.amount');
		const depositDisclosure = get(dealer, 'settings.deposit.disclosure');
		const depositPhone = get(
			dealer.phones.find((p) => p.type === 'GENERAL'),
			'number'
		);
		const { disclosureIsOpen, popupPositionStyles, submitEvent } = state;

		return (
			<IntlProvider definition={dictionary}>
				<div class="autofi-modal depositModal">
					<FormHeader close={this.handleClose} title={title} />
					<div class="modalContent">
						<div class="introduction amountIntroduction">
							<DollarIcon width="46px" height="46px" class="icon--dollar" />
							<p>
								<MarkupText id="deposit-to-hold-the-vehicle" fields={{ depositAmount }} />
							</p>
						</div>
						<div class="introduction appointmentIntroduction">
							<ScheduleIcon width="46px" height="46px" class="icon--appointment" />
							<p>
								<Text id="schedule-an-appointment" />
							</p>
						</div>
						<div class="introduction refundIntroduction">
							<DepositIcon width="50px" height="50px" class="icon--refund" />
							<p>
								<MarkupText id="refund-or-question" fields={{ dealerName, depositPhone }} />
							</p>
						</div>
						{depositDisclosure && (
							<div class="disclosureContainer">
								<span>
									<Text id="disclosure" />
									<InfoIcon active={disclosureIsOpen} onClick={this.handleDisclosureClick} />
								</span>
								<DisclosurePopup
									class="disclosure depositDisclosure"
									hidden={!disclosureIsOpen}
									style={popupPositionStyles}
									innerPopUpStyle={{
										marginTop: '-15px',
										padding: '0 15px',
										wordBreak: 'break-word',
									}}
								>
									{depositDisclosure}
								</DisclosurePopup>
							</div>
						)}
						<SubmitButton onClick={this.handleSubmit} submitting={Boolean(submitEvent)}>
							<Text id="continue" />
						</SubmitButton>
					</div>
				</div>
			</IntlProvider>
		);
	};
}

const WrappedDepositModal = modal(isolate(withStyles(DepositModal, styles)));

export default WrappedDepositModal;
