import clamp from 'lodash/clamp';
import { h, Component } from 'preact';
import { IntlProvider, Text, MarkupText } from 'preact-i18n';

import OverrideText from '~ui/components/overrideText';
import DisclosurePopup from '~ui/components/disclosurePopup';
import InfoIcon from '~ui/components/infoIcon';
import isolate from '~ui/components/isolate';
import * as utils from '~ui/utils';
import { trackAmplitudeEvent } from '~ui/utils/analytics';
import withStyles from '~ui/components/withStyles';

import styles from './paymentDisclaimer.styl';

class PaymentDisclaimer extends Component {
	state = {
		disclosurePopupOpen: false,
		popupPositionStyles: undefined,
	};

	componentDidMount() {
		document.addEventListener('click', this.handleBodyClick);
		window.addEventListener('resize', this.updatePopupPositionStyles);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleBodyClick);
		window.removeEventListener('resize', this.updatePopupPositionStyles);
	}

	handleBodyClick = (e) => {
		if (this.state.disclosurePopupOpen) {
			this.handleDisclosureClick(e);
		}
	};

	handleDisclosureClick = (event) => {
		const { dealer, estimate, isInStore, pageType, trackId, vehicle } = this.props;
		if (!this.state.disclosurePopupOpen) {
			const action = 'click: Payment Disclaimer';
			const ctaText = event?.currentTarget?.innerText;
			trackAmplitudeEvent({ action, ctaText, dealer, estimate, isInStore, pageType, trackId, vehicle });
		}
		event.stopPropagation(); // don't bubble up to handleBodyClick
		this.setState((state) => ({ disclosurePopupOpen: !state.disclosurePopupOpen }));
		this.updatePopupPositionStyles();
	};

	getDiscounts = () => {
		const { estimate, vehicle } = this.props;
		const discount = utils.getDiscountsAndRebatesAmount(vehicle, estimate);
		return <Text id="additional-discount" fields={{ discount }} />;
	};

	disclaimerIntro = () => {
		return this.props.lockedPricingEnabled ? (
			<span>
				<MarkupText id="discounts-included-in-sample-payment" fields={{ discounts: this.getDiscounts() }} />
			</span>
		) : (
			<div>
				<MarkupText id="sample-payment-assumes" />
			</div>
		);
	};

	disclaimer = () => {
		return this.props.estimate.offerType === 'finance' ? this.financeDisclaimer() : this.leaseDisclaimer();
	};

	financeDisclaimer = () => (
		<Text
			id="finance-disclaimer"
			fields={{
				term: this.props.estimate.term,
				downPayment: this.formattedDownPayment(),
				apr: this.formattedApr(),
			}}
		/>
	);

	leaseDisclaimer = () => (
		<Text
			id="lease-disclaimer"
			fields={{
				term: this.props.estimate.term,
				downPayment: this.formattedDownPayment(),
				annualDistance: this.formattedAnnualDistance(),
			}}
		/>
	);

	additionalDisclosures = () => {
		return this.props.estimate.offerType === 'finance'
			? this.additionalFinanceDisclosures()
			: this.additionalLeaseDisclosures();
	};

	additionalFinanceDisclosures = () => {
		const { estimate, financeDisclosureCopy } = this.props;
		const finePrint = this.replacePlaceHolders(financeDisclosureCopy) || 'finance-disclosures-fine-print';
		const mainDisclosure = (
			<MarkupText
				id="finance-disclosures"
				fields={{
					apr: this.formattedApr(),
					downPayment: this.formattedDownPayment(),
					term: estimate.term,
				}}
			/>
		);

		return (
			<div>
				<p>{mainDisclosure}</p>
				<p>
					<OverrideText content={finePrint} />
				</p>
			</div>
		);
	};

	replacePlaceHolders = (disclosureText) => {
		if (!disclosureText) {
			return '';
		}
		const { monthlyPayment, term, downPayment, totalRebates, totalDiscounts } = this.props.estimate;
		const placeholders = {
			AF_Term: term,
			AF_DownPayment: this.formattedDownPayment(),

			// TODO: fix this.
			AF_AnnualDistanceLabel: 'miles',

			AF_FormattedAnnualDistance: this.formattedAnnualDistance(),
			AF_MonthlyPayment: utils.formatMoney(monthlyPayment),
			AF_TotalPayment: utils.formatMoney(downPayment + monthlyPayment * term + totalRebates + totalDiscounts),
		};

		const arrayOfWords = disclosureText.split(' ');
		const replacedText = arrayOfWords.map((aWord) => {
			// eslint-disable-next-line no-prototype-builtins
			if (placeholders.hasOwnProperty(aWord)) {
				return placeholders[aWord];
			}
			return aWord;
		});
		return replacedText.join(' ');
	};

	additionalLeaseDisclosures = () => {
		const { estimate, leaseDisclosureCopy } = this.props;
		const finePrint = this.replacePlaceHolders(leaseDisclosureCopy) || 'lease-disclosures-fine-print';
		const mainDisclosure = (
			<MarkupText
				id="lease-disclosures"
				fields={{
					annualDistance: this.formattedAnnualDistance(),
					downPayment: this.formattedDownPayment(),
					term: estimate.term,
				}}
			/>
		);
		return (
			<div>
				<p>{mainDisclosure}</p>
				<p>
					<OverrideText content={finePrint} />
				</p>
			</div>
		);
	};

	formattedApr = () => {
		const { apr = 0 } = this.props.estimate;
		return (100 * apr).toFixed(2).replace(/\.?0+$/, '');
	};

	formattedMoney = (money) => {
		return parseFloat(money).toFixed(2);
	};

	formattedDownPayment = () => {
		return utils.formatMoney(utils.nearest100(this.props.estimate.downPayment));
	};

	formattedAnnualDistance = () => {
		return utils.formatNumber(this.props.estimate.annualMiles);
	};

	updatePopupPositionStyles = () => {
		const bodyRect = document.body.getBoundingClientRect();
		// TODO: use this.getDOMNode() instead of this.base if it becomes supported
		const disclaimerRect = this.base.getBoundingClientRect();

		const popupPositionStyles = {
			top: disclaimerRect.bottom - bodyRect.top,
			right: bodyRect.width - disclaimerRect.right + bodyRect.left,
			width: clamp(window.innerWidth, 150, Math.min(bodyRect.right, 440)),
		};

		this.setState({ popupPositionStyles });
	};

	render() {
		const { dictionary, estimate } = this.props;
		const { disclosurePopupOpen, popupPositionStyles } = this.state;

		if (estimate.offerType === 'cash') {
			return null;
		}

		return (
			<IntlProvider definition={dictionary}>
				<div class="paymentDisclaimer">
					{this.disclaimerIntro()}
					{this.disclaimer()}
					<button class="infoIconButton" type="button" onClick={this.handleDisclosureClick}>
						<InfoIcon active={disclosurePopupOpen} />
					</button>
					<DisclosurePopup
						class="disclosure paymentDisclosure"
						hidden={!disclosurePopupOpen}
						style={popupPositionStyles}
					>
						<IntlProvider definition={dictionary}>{this.additionalDisclosures()}</IntlProvider>
					</DisclosurePopup>
				</div>
			</IntlProvider>
		);
	}
}

export default isolate(withStyles(PaymentDisclaimer, styles));
