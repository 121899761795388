import { AmplitudeLabelComponents, Pathways } from '~lib/enum';
import { shouldLog } from '~ui/utils';

// TODO: consider the following ideas:
// * Export trackId instead of putting it in window.autofi.
// * Actually put all Amplitude code in this file instead of injecting a.js.
//   Maybe make the js/plugins/a/a.js script a module that can be imported
//   instead of creating a new script element and making another request.

let trackerConfigs;
let trackId;
window.autofi = window.autofi || {};

const shouldLogEvents = () => {
	return Boolean(window.autofi.debugTracking || shouldLog() || trackerConfigs.afNoTracking);
};

const initInstance = () => {
	const amplitudeInstance = window.amplitude.getInstance();
	amplitudeInstance.init(trackerConfigs.amplitudeId, trackId, {
		apiEndpoint: trackerConfigs.endpointUrl + '/amptrack',
		forceHttps: trackerConfigs.loanAppBaseUrl.indexOf('https') === 0 && !window.XDomainRequest,
	});
	return amplitudeInstance;
};

const makeDefaultLabel = ({ action, ctaText, pathway }) => {
	const prefix =
		{
			[Pathways.Deposit]: 'Pathway: deposit',
			[Pathways.PrivateOffers]: 'Pathway',
			[Pathways.StandAloneCreditApp]: 'Pathway: standAloneCreditApp',
			[Pathways.TradeIn]: 'Pathway: tradeIn',
		}[pathway] || 'Express Checkout';

	const suffix = pathway === 'tradein' && ctaText ? 'cta' : null;

	return [prefix, action, suffix].filter(Boolean).join(': ');
};

const getExpressCheckoutTrackingData = ({ dealer, isInStore, vehicle }) => {
	return {
		dealerCode: dealer.code,
		dealer: dealer.name,
		domain: window.location.host,
		inStore: isInStore,
		...(vehicle && {
			make: vehicle.make,
			model: vehicle.model,
			vehicleAge: vehicle.age,
			vehicleMsrp: vehicle.dealerRetailPrice,
			vehicleSellingPrice: vehicle.salePrice,
			vin: vehicle.vin,
			year: vehicle.year,
		}),
	};
};

const getTrackingProps = (estimate, paymentInterval, ctaText) => {
	const { annualMiles, apr, biweeklyPayment, downPayment, monthlyPayment, offerType, term, totalRebates } =
		estimate || {};

	return {
		buttonText: ctaText,
		estimatedAPR: apr,
		estimatedDown: downPayment,
		estimatedMiles: annualMiles,
		estimatedRebate: totalRebates,
		estimatedTerm: term,
		paymentInterval,
		saleType: offerType,
		...(paymentInterval === 'BIWEEKLY' ? { estimatedBiweekly: biweeklyPayment } : { estimatedMonthly: monthlyPayment }),
	};
};

const getTrackingData = ({
	action,
	ctaText,
	dealer,
	estimate,
	isInStore,
	opts,
	pageType,
	pathway,
	trackId: trackIdOverride,
	vehicle,
}) => {
	const paymentInterval = dealer.settings.consumerFlow.paymentIntervals.defaultInterval;
	const { Load } = AmplitudeLabelComponents.Action;
	const source = document.location.search.match(/utm_source=(\w+)/i);
	const expressCheckoutTrackingData = getExpressCheckoutTrackingData({ dealer, isInStore, vehicle });
	const trackingProps = getTrackingProps(estimate, paymentInterval, ctaText);

	const trackingData = {
		...expressCheckoutTrackingData,
		...(action === Load && { action: 'load' }),
		...trackingProps,
		...opts,
		pageType,
		pathway,

		// TODO: fix isTestUser of false not being sent.
		// See https://autofi.atlassian.net/browse/CS-1675
		...(action !== Load && { isTestUser: opts?.isTestUser || undefined }),

		trackId: trackIdOverride || trackId,
		...(source && source[1] && { source: source[1] === 'link' ? 'copied link' : source[1] }),
	};

	return Object.fromEntries(Object.entries(trackingData).filter(([_key, value]) => value !== undefined));
};

export const init = (trackerConfigsParam) => {
	trackerConfigs = trackerConfigsParam;
	trackId = new Date() * 1 + '';
	window.autofi.trackId = trackId;

	(function (e, t) {
		var n = e.amplitude || { _q: [], _iq: {} };
		var r = t.createElement('script');
		r.type = 'text/javascript';
		r.async = true;
		r.src = trackerConfigs.loanAppBaseUrl + '/js/plugins/a/a.js';
		r.onload = function () {
			window.amplitude.runQueuedFunctions();
		};
		var i = t.getElementsByTagName('script')[0];
		i.parentNode.insertBefore(r, i);

		function s(e, t) {
			e.prototype[t] = function () {
				this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
				return this;
			};
		}
		var o = function () {
			this._q = [];
			return this;
		};
		var a = ['add', 'append', 'clearAll', 'prepend', 'set', 'setOnce', 'unset'];
		for (var u = 0; u < a.length; u++) {
			s(o, a[u]);
		}
		n.Identify = o;
		var c = function () {
			this._q = [];
			return this;
		};
		var p = ['setProductId', 'setQuantity', 'setPrice', 'setRevenueType', 'setEventProperties'];
		for (var l = 0; l < p.length; l++) {
			s(c, p[l]);
		}
		n.Revenue = c;
		var d = [
			'init',
			'logEvent',
			'logRevenue',
			'setUserId',
			'setUserProperties',
			'setOptOut',
			'setVersionName',
			'setDomain',
			'setDeviceId',
			'setGlobalUserProperties',
			'identify',
			'clearUserProperties',
			'setGroup',
			'logRevenueV2',
			'regenerateDeviceId',
			'logEventWithTimestamp',
			'logEventWithGroups',
		];

		function v(e) {
			function t(t) {
				e[t] = function () {
					e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
				};
			}
			for (var n = 0; n < d.length; n++) {
				t(d[n]);
			}
		}
		v(n);
		n.getInstance = function (e) {
			e = (!e || e.length === 0 ? '$default_instance' : e).toLowerCase();
			n._iq = n._iq || {};
			// eslint-disable-next-line no-prototype-builtins
			if (!n._iq.hasOwnProperty(e)) {
				n._iq[e] = { _q: [] };
				v(n._iq[e]);
			}
			return n._iq[e];
		};
		e.amplitude = n;
	})(window, document);
};

/**
 * Sends a tracking event to Amplitude for a page load
 *
 * @param {Object} params
 * @param {string} [params.action]
 * @param {string} [params.ctaText]
 * @param {Object} params.dealer
 * @param {Object} [params.estimate]
 * @param {boolean} params.isInStore
 * @param {string} [params.label]
 * @param {Object} [params.opts]
 * @param {string} params.pageType
 * @param {string} [params.pathway]
 * @param {any} params.trackId
 * @param {Object} [params.vehicle]
 */
export const trackAmplitudeEvent = (params) => {
	const { action, ctaText, label: labelOverride, pathway } = params;
	const label = labelOverride || makeDefaultLabel({ action, ctaText, pathway });
	const trackingData = getTrackingData(params);

	if (shouldLogEvents()) {
		/* eslint-disable-next-line */
		console.log('AutoFi Amplitude event', label, trackingData);
	}

	if (!trackerConfigs.afNoTracking) {
		const amplitudeInstance = initInstance();
		amplitudeInstance.logEvent(label, trackingData);
	}
};
