/** @jsx h */
import { h, Component } from 'preact';

import { DealMakerPanel, VehicleStatus, CallbackState } from '~lib/enum';
import { fetchVehicles } from '~lib/scraper/vehicleService';
import * as utils from '~ui/utils';

export class PathwaysApiUi extends Component {
	/**
	 * Adds the eventListener for postMessage api from DM iframe. By passing a callback,
	 * the client of the panda SDK can receive callbacks for events triggered from DM.
	 * @param {*} event
	 */
	handleCallbackMessage = (event) => {
		const { autofiData, callback } = this.props;
		const { loanAppBaseUrl } = autofiData;
		if (event.origin === loanAppBaseUrl) {
			const { data } = event;

			if (callback && data && String(data.type).includes('autofi-')) {
				callback(data);
			}
		}
	};

	mountPathwaysApi = () => {
		const { autofiData, ctaTypeClickHandlerMap, setApplicant, setCallback, setCurrentVehicle } = this.props;

		window.addEventListener('message', this.handleCallbackMessage);

		/**
		 * @todo Extract api object to it's own file and initializer.
		 */
		window.autofi.api = window.autofi.api || {
			/*
			 * @param {object} opts
			 * @param {[object]} opts.address customer's address
			 * @param {[string]} opts.address.street customer's street address
			 * @param {[string]} opts.address.street2 customer's street address, line 2
			 * @param {[string]} opts.address.city customer's city
			 * @param {[string]} opts.address.state customer's state or province, 2-letter abbreviation
			 * @param {[string]} opts.address.zip customer's ZIP code or postal code
			 * @param {[string]} opts.apiKey account API key
			 * @param {[string]} opts.ctaText text of button that was clicked
			 * @param {[string]} opts.customerReferenceId avoid creating new customers
			 * @param {[string]} opts.dealerCode override used by pathways API
			 * @param {DealMakerPanel} opts.dealMakerPanel Used by aggregators to jump to loan application step
			 * @param {[string]} opts.email customer's email address
			 * @param {[string]} opts.firstName customer's first name
			 * @param {[string]} opts.formHeader override used by pathways API
			 * @param {[string]} opts.formSubheader override used by pathways API
			 * @param {[string]} opts.heroImage URL of vehicle photo
			 * @param {[string]} opts.loanApplicationId loan app id to load said loan app in DM
			 * @param {[string]} opts.lastName customer's last name
			 * @param {[string]} opts.oemLeadSource provider name for the OEM lead
			 *     source, used for aggregators
			 * @param {string} opts.pathway
			 * @param {[string]} opts.phone customer's phone number
			 * @param {[string]} opts.vdpUrl
			 * @param {[string]} opts.vin
			 * @param {[function]} opts.callback We'll call this function if present when there are
			 * changes such as during launch, when finishes loading, etc.
			 */
			render: (opts) => {
				utils.logToConsole('AutoFi Debugger:\n' + JSON.stringify(opts, null, '\t'));

				const {
					/**
					 * @todo any pathways added should be documented on the pathways API doc.
					 * https://docs.google.com/document/d/1PtZklusrms_xmUOMttSyNzJZZi1_kAKiO_pmlXc6L54
					 */
					apiKey,
					callback,
					ctaText,
					customerReferenceId,
					dealerCode,
					/* For aggregator use. Look at the `DealMakerPanel` enum for possible options */
					dealMakerPanel = DealMakerPanel.ExplorePayments,
					formHeader,
					formSubheader,
					heroImage,
					/* allows us to open an existing application given the ID */
					loanApplicationId,
					oemLeadSource,
					pathway,
					vdpUrl,
					vin,
				} = opts;

				/**
				 * The callback is a function passed as part of the pathways that can be used
				 * to listen to postMessage events without having to initialize a listener,
				 * since panda will do it for you.
				 */
				setCallback(callback);

				callback?.({ type: CallbackState.Loading });

				const applicant = this.makeApplicant(opts);

				/**
				 * If the loanApplicationId pathway is present, we just need to use the iframe
				 * to load an existing loan application - no vehicle loading is needed.
				 */
				if (loanApplicationId) {
					this.invokeExistingLoanApp(callback, dealMakerPanel, loanApplicationId);
					return;
				}

				setApplicant(applicant, () => {
					// vehicle data is fetched below but the UI expects a pending state
					setCurrentVehicle({ vin, status: VehicleStatus.Pending }, async () => {
						const ctaType = utils.ctaTypeFromPathwayName(pathway);
						const handleClick = ctaTypeClickHandlerMap[ctaType];

						if (handleClick) {
							handleClick(vin, {
								apiKey,
								ctaText,
								customerReferenceId,
								dealerCode,
								formHeader,
								formSubheader,
								oemLeadSource,
							});
							callback?.({ type: CallbackState.Finished });
						} else {
							callback?.({ type: CallbackState.Error });
							/**
							 * @todo
							 * Direct API consumers to supported pathways documentation
							 */
							// eslint-disable-next-line no-console
							console.warn(
								'AutoFi Warning: ' +
									`${pathway} is not a supported pathway. ` +
									'See API documentation for supported pathways.'
							);
						}

						// We only support one vehicle at a time when using the API
						const vehicles = await fetchVehicles([{ vin }], autofiData, dealerCode);
						// If the vehicle is not found, the results will be an array with an object
						// that contains the vin and a status of 'NotFound'.
						if (callback && vehicles[0].status === VehicleStatus.NoData) {
							callback({
								type: CallbackState.Error,
								message: `The vehicle with vin ${vin} was not found for dealer with code ${dealerCode}`,
							});
						}
						const photoUrl = heroImage || vehicles[0].photoUrl;
						const vehicle = { ...vehicles[0], photoUrl, vdpUrl };
						utils.logToConsole(`AutoFi Debugger:\nSetting ${vin} as current vehicle`);
						setCurrentVehicle(vehicle);
					});
				});
			},
		};
	};

	invokeExistingLoanApp = (cb, dealMakerPanel, loanApplicationId) => {
		if (!utils.isValidPanelOption(dealMakerPanel)) {
			cb?.({ type: CallbackState.Error });
			throw new Error(
				`An invalid dealMakerPanel variable was used. Valid options are ${Object.values(DealMakerPanel).join(', ')}`
			);
		}
		this.props.handleLoadLoanApplication(loanApplicationId, dealMakerPanel);
	};

	makeApplicant = (opts) => {
		const { dealer } = this.props.autofiData;
		const { address, email, firstName, lastName, phone } = opts;

		if (address || email || firstName || lastName || phone) {
			const contactData = {
				...address,
				email,
				phone,
				...(firstName && lastName && { name: `${firstName} ${lastName}` }),
			};
			return utils.makeApplicant({ contactData, dealer });
		} else {
			return null;
		}
	};

	componentDidMount = () => {
		this.mountPathwaysApi();
	};

	componentDidUpdate = () => {
		this.mountPathwaysApi();
	};

	componentWillUnmount = () => {
		window.removeEventListener('message', this.handleCallbackMessage);
	};

	render = () => null;
}
