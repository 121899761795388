import { h, Component } from 'preact';
import uniqueId from 'lodash/uniqueId';
import { createPortal } from 'preact/compat';

import * as utils from '~ui/utils';
import withStyles from '~ui/components/withStyles';

import styles from '../common.styl';

const modal = (ComponentToMakeModal, { containerClass } = {}) => {
	const componentName = utils.getComponentName(ComponentToMakeModal);

	class ModalComponent extends Component {
		static displayName = `Modal${componentName}`;

		preventScrollClassName = uniqueId(`autofi-prevent-scroll-${componentName}-`);

		updateAttributes = ($container) => {
			if (this.props.hidden) {
				document.documentElement.classList.remove(this.preventScrollClassName);
			} else {
				document.documentElement.classList.add(this.preventScrollClassName);
			}

			$container.classList.toggle('hidden', Boolean(this.props.hidden));
			$container.classList.toggle('transparent', Boolean(this.props.transparent));
		};

		scrollToTop = () => {
			this.state.$container.scrollTop = 0;
		};

		// this method is to prevent accessiBe plug-in from being enabled when the tab key is pressed
		handleKeyDown = (e) => {
			if (window.acsb) {
				e.stopPropagation();
			}
		};

		componentDidMount = () => {
			const $container = document.createElement('div');
			$container.classList.add('autofi-modal-container');
			if (containerClass) {
				$container.classList.add(containerClass);
			}
			$container.addEventListener('keydown', this.handleKeyDown);
			document.body.appendChild($container);

			// Call this.updateAttributes immediately, instead of doing it in the
			// callback to this.setState, so the page doesn't flicker on sites that
			// have a badly-implemented Promise polyfill, which causes
			// this.updateAttributes to run after a delay.
			this.updateAttributes($container);

			this.setState({ $container });
		};

		componentDidUpdate = () => {
			this.updateAttributes(this.state.$container);
		};

		componentWillUnmount = () => {
			document.documentElement.classList.remove(this.preventScrollClassName);
			this.state.$container.remove();
		};

		render = (props) => {
			const { $container } = this.state;

			return (
				$container &&
				createPortal(
					<ComponentToMakeModal $modalContainer={$container} scrollToTop={this.scrollToTop} {...props} />,
					$container
				)
			);
		};
	}

	return withStyles(ModalComponent, styles);
};

export default modal;
