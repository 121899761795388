import { h, Component } from 'preact';
import { IntlProvider, MarkupText, Text } from 'preact-i18n';

import AutoFiLogo from '~ui/assets/autofi-logo.svg';
import CarFrontIcon from '~ui/assets/car-front.svg';
import CloseIcon from '~ui/assets/close-icon.svg';
import CreditFormIcon from '~ui/assets/credit-form.svg';
import SignsIcon from '~ui/assets/signs-icon.svg';
import withStyles from '~ui/components/withStyles';
import CreditScoreGauge from './creditScoreGauge';
import Estimate from './estimate';
import styles from './sequence.styl';

class Result extends Component {
	render = (props) => {
		const { applicant, close, softPullResponse, dictionary } = props;
		const { ficoRange } = softPullResponse || {};

		const maxMonthlyPayment = applicant.income * 0.18;
		const maxLoanAmount = applicant.income * 12 * 0.9;

		return (
			<IntlProvider definition={dictionary}>
				<form class="autofi-modal creditAppPage result">
					<header class="modalHeader">
						<button type="button" class="closeButton" onClick={close}>
							<Text id="close" /> <CloseIcon />
						</button>
						<h2>
							<Text id="your-personal-estimates" />
						</h2>
					</header>
					<div class="modalContent">
						<div class="creditStuff">
							<div class="columns">
								<div class="creditScoreContainer">
									<h3>
										<Text id="credit-range" />
									</h3>
									<CreditScoreGauge ficoRange={ficoRange} />
								</div>
								<div class="detailsContainer">
									<Estimate
										dictionary={dictionary}
										text={<Text id="est-max-monthly-payment" />}
										amount={maxMonthlyPayment}
										disclosure={
											<div>
												<h3>
													<Text id="estimated-max-monthly-payment" />
												</h3>
												<p>
													<MarkupText id="max-monthly-payment-approved-by-lenders" />
												</p>
											</div>
										}
									/>
									<Estimate
										dictionary={dictionary}
										text={<Text id="est-max-loan-amount" />}
										amount={maxLoanAmount}
										disclosure={
											<div>
												<h3>
													<Text id="estimated-max-loan-amount" />
												</h3>
												<p>
													<MarkupText id="max-loan-amount-typically-averages" />
												</p>
											</div>
										}
									/>
								</div>
							</div>
						</div>
						<div class="iconContainer">
							<h3>
								<Text id="explore-your-options" />
							</h3>
							<div class="columns">
								<div class="column">
									<CarFrontIcon />
									<div class="text">
										<MarkupText id="choose-a-vehicle" />
									</div>
								</div>
								<div class="column">
									<SignsIcon />
									<div class="text">
										<MarkupText id="customize-your-payments" />
									</div>
								</div>
								<div class="column">
									<CreditFormIcon />
									<div class="text">
										<Text id="apply-for-financing" />
									</div>
								</div>
							</div>
						</div>
						<div class="buttonContainer" onClick={close}>
							<button type="button">
								<Text id="back-to-browsing" />
							</button>
							{applicant.rememberMe && (
								<p>
									<Text id="your-information-will-be-carried-over" />
								</p>
							)}
						</div>
						<div class="poweredBy">
							<Text id="powered-by" /> <AutoFiLogo class="autoFiLogo" />
						</div>
					</div>
				</form>
			</IntlProvider>
		);
	};
}

export default withStyles(Result, styles);
