import { h, Component } from 'preact';
import { forwardRef } from 'preact/compat';

import * as utils from '~ui/utils';

const withStyles = (ComponentToStyle, defaultStyles) => {
	class InnerComponentWithStyles extends Component {
		updateStyles = (styles) => {
			if (styles) {
				// TODO: find a less hacky way to get the DOM node for this component,
				// maybe by using this.getDOMNode if we start using React.
				const $root = (this.base || this.__P).getRootNode();
				const filename = styles[0][0];
				const $allStyleTags = [...$root.querySelectorAll('style')];
				let $style = $allStyleTags.find(($el) => $el.dataset.autofiSrc === filename.toString());
				if (!$style) {
					$style = document.createElement('style');
					$style.dataset.autofiSrc = filename;
					const $target = $root === document ? $root.head : $root;
					$target.appendChild($style);
				}
				const css = styles.map((x) => x[1]).join('\n');
				$style.innerHTML = css;
			}
		};

		componentDidMount = () => {
			this.updateStyles(defaultStyles);
			this.updateStyles(utils.getCustomStyles(this.props.customCss));
		};

		componentDidUpdate = (prevProps) => {
			if (this.props.customCss !== prevProps.customCss) {
				this.updateStyles(utils.getCustomStyles(this.props.customCss));
			}
		};

		render = (props) => <ComponentToStyle ref={props.forwardedRef} {...props} />;
	}

	const InnerComponent = (props, ref) => (
		<utils.CustomCssContext.Consumer>
			{(customCss) => <InnerComponentWithStyles {...props} forwardedRef={ref} customCss={customCss} />}
		</utils.CustomCssContext.Consumer>
	);

	InnerComponent.displayName = `${utils.getComponentName(ComponentToStyle)}WithStyles`;

	return forwardRef(InnerComponent);
};

export default withStyles;
